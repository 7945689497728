import { createContext, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import {
  ApiObjectVariant,
  createApiObjectDataFromPlainObject,
  deriveApiObjectVariantId,
  IApiListType,
  IApiObjectVariantKey,
  IListMutateFn,
  IProject,
  ISearchTasksApiRequest,
  ISmallTask,
  KEY,
  makeApiObjectVariantKey,
  mutate,
  PROJECT,
  PROJECTS_SPRINTS,
  TASK,
  TaskSearchType,
  TaskSortType,
  useApiObject
} from '../../hooks/api2';
import fetchRequest from '../../services/api';
import { DateType, ISprintProps, IUserProps, ProjectType, TaskStatus, TaskType } from '../../types';
import { UUID_1, UUID_F } from '../../utils/helpers';
import { fetchJson } from '../../services/api2';
import { ApiDataCache } from '../../hooks/api2/cache';
import { CUSTOM_COLUMN_GROUP, ICustomColumnGroup } from '../../hooks/api2/types/customColumns';

//#region View Parameters

export enum ProjectFilterType {
  My = 'my',
  Involved = 'involved',
  All = 'all',
  Completed = 'completed',
  Templates = 'templates'
}

export const PROJECT_FILTER_TYPE_VALUES = Object.values(ProjectFilterType);

/** Non-configurable parameters for the tasks page. */
export interface ITasksPageParams {
  /** Opaque hash string for change detection */
  hash: string;
  teamId: null | string;
  projectId: null | string;
  sprintId: null | string;
  filterType: null | TaskSearchType;
  projectFilterType: null | ProjectFilterType;
  viewType: null | TasksViewType;
}

export enum TaskGroupingType {
  Statuses,
  AssignedToIds,
  SprintId,
  DateType,
  Priorities
}

export const ZTaskGroupingCustom = z.object({
  type: z.literal('custom'),
  groupId: z.string().uuid()
});

export type ITaskGroupingCustom = z.infer<typeof ZTaskGroupingCustom>;

export type TaskGrouping =
  | (typeof TaskGroupingType)[keyof typeof TaskGroupingType]
  | ITaskGroupingCustom;

export const ZTaskGrouping = z.nativeEnum(TaskGroupingType).or(ZTaskGroupingCustom);

export const TaskGroupingContext = createContext<TaskGrouping | null>(null);

export enum TasksViewType {
  Kanban = 'kanban',
  List = 'task-list',
  Gantt = 'gantt',
  Grid = 'grid',
  Dashboard = 'dashboard'
}

export const TASKS_VIEW_TYPE_VALUES = Object.values(TasksViewType);

const ZTaskSortType = z.nativeEnum(TaskSortType);
export const ZTaskSorting = z
  .object({
    type: ZTaskSortType,
    ascending: z.boolean()
  })
  .or(z.null());

export type TaskSorting = z.infer<typeof ZTaskSorting>;

/** User-configurable view parameters on the tasks page. */
export interface ITasksView {
  type: TasksViewType;
  grouping: TaskGrouping;
  sorting: TaskSorting;
  filters: ITaskFilters;
  showMsTeamsTasks: boolean;
  currentViewId: string | null;
}

const ZDateType = z.nativeEnum(DateType);
const ZTaskType = z.nativeEnum(TaskType);
const ZTaskStatus = z.nativeEnum(TaskStatus);

export const TaskFilters = z.object({
  usePlanner: z.boolean(),
  dateType: ZDateType.array(),
  types: ZTaskType.array(),
  statuses: ZTaskStatus.array(),
  assignedToIds: z.string().uuid().array(),
  priorities: z.number().int().gte(1).lte(9).array(),
  creatorIds: z.string().uuid().array(),
  assignedToTeamIds: z.string().uuid().array(),
  definitionIds: z.string().uuid().array(),
  projects: z.string().uuid().array(),
  tags: z.object({ id: z.string().uuid() }).array()
});

/** Parameters for filtering tasks. */
export type ITaskFilters = z.infer<typeof TaskFilters>;

export const DEFAULT_FILTER: ITaskFilters = {
  usePlanner: false,
  dateType: [],
  types: [],
  statuses: [],
  assignedToIds: [],
  priorities: [],
  creatorIds: [],
  assignedToTeamIds: [],
  definitionIds: [],
  projects: [],
  tags: []
};

export function hasTaskFilters(filters: ITaskFilters): boolean {
  for (const value of Object.values(filters)) {
    if (Array.isArray(value) && value.length) return true;
    if (typeof value === 'boolean' && value) return true;
  }
  return false;
}

//#endregion

//#region Task Groups

export interface ITasksViewGroupProps {
  /** Some kind of ID that uniquely identifies this group. */
  id: string;
  /** The group's name. */
  name: string;
  /** If passed, this group's name is a customizable string. */
  onRename?: (newName: string) => Promise<void>;
  /** If passed, this group can be removed. */
  onRemove?: () => Promise<void>;
  allowRemoveNonEmpty?: boolean;

  /** If passed, this column can be moved left. */
  onMoveLeft?: () => Promise<void>;
  /** If passed, this column can be moved right. */
  onMoveRight?: () => Promise<void>;

  /** API endpoint to fetch contents from. */
  endpoint: IApiListType<ISearchTasksApiRequest, typeof TASK>;
  /** API parameters that fetch this group's contents. */
  params: ISearchTasksApiRequest;
}

export interface ITasksGroupData {
  items: ISmallTask[];
  mutate: IListMutateFn<string>;
}

export interface ITaskGroupsContext {
  /**
   * Currently loaded group data. Used for mutations like drag & drop.
   * Should only be mutated by group components, i.e. those that provide GroupDataRefs.
   */
  groupData: Map<string, ITasksGroupData>;

  /**
   * Returns true if the task can probably be moved to a different group and should have drag & drop enabled.
   * If destGroupId passed, returns true only if the task can also be moved to that group.
   */
  canMoveTask: (task: ISmallTask, srcGroupId: string, destGroupId?: string) => boolean;

  /**
   * Attempts to move a task between groups and returns success.
   * If `destIndex` is given, then the task will be inserted close to that index in the destination group.
   */
  moveTask: (
    taskId: string,
    srcGroupId: string,
    destGroupId: string,
    destIndex?: number
  ) => boolean;

  /**
   * Mutates a task by ID.
   *
   * If a task by the given ID exists, the partial mutated data will be merged,
   * and apply will be called with the mutated data to upload to the backend.
   * Its return value will be used as the final value.
   */
  mutateTask: (
    taskId: string,
    mutated: Partial<ISmallTask>,
    apply: (task: ISmallTask) => Promise<ISmallTask>
  ) => void;
}

const TASK_GROUPS_CONTEXT_WITHOUT_PROVIDER = () => {
  throw new Error('no Provider');
};
export const TaskGroupsContext = createContext<ITaskGroupsContext>({
  groupData: new Map(),
  canMoveTask: TASK_GROUPS_CONTEXT_WITHOUT_PROVIDER,
  moveTask: TASK_GROUPS_CONTEXT_WITHOUT_PROVIDER,
  mutateTask: TASK_GROUPS_CONTEXT_WITHOUT_PROVIDER
});

/**
 * Mutates a task's data. May cause it to move between groups.
 * Also see mutateTask in ITaskGroupsContext.
 *
 * Additional parameters:
 * - `groupForTask`: returns the group that a task should belong to, as that may have changed.
 *   if null, the task will be removed. if undefined, the task will keep its current group.
 */
export function mutateTaskInGroupData(
  groupData: Map<string, ITasksGroupData>,
  task: string,
  mutatedPartial: Partial<ISmallTask>,
  apply: (task: ISmallTask) => Promise<ISmallTask>,
  groupForTask: (task: ISmallTask) => string | null | undefined,
  destinationIndexIfMoved?: number
): Promise<void> {
  for (const [groupId, group] of groupData) {
    const item = group.items.find((item) => item.id === task);
    if (item) {
      const mutated = { ...item, ...mutatedPartial };
      const application = apply(mutated);

      mutate(
        mutated,
        application.then((task) => {
          // we want this mutation to apply to small too
          // eslint-disable-next-line no-param-reassign
          delete task[KEY];
          return task;
        }),
        mutatedPartial
      );

      let newGroupId = groupForTask(mutated);
      if (newGroupId === undefined) newGroupId = groupId;
      const willMoveToDifferentGroup = newGroupId !== groupId;

      const itemKey: IApiObjectVariantKey<string> = {
        ...(mutated[KEY] as IApiObjectVariantKey<string>),
        variant: ApiObjectVariant.Small
      };
      const itemId = deriveApiObjectVariantId(itemKey);

      group.mutate(
        application.then(() => undefined),
        (items) => {
          if (willMoveToDifferentGroup) {
            return items.filter((item) => item.id !== itemId);
          }
          return items;
        }
      );

      const newGroup = groupData.get(newGroupId);
      if (willMoveToDifferentGroup && newGroup) {
        newGroup.mutate(
          application.then(() => undefined),
          (items) => {
            const newItems = [...items];

            if (newItems.some((item) => item.id === itemId)) {
              // mutation is redundant. skip
              return newItems;
            }

            newItems.splice(destinationIndexIfMoved || 0, 0, {
              key: itemKey,
              id: itemId
            });
            return newItems;
          }
        );
      }

      return application.then(() => undefined);
    }
  }

  return Promise.reject(new Error('could not find item'));
}

//#endregion

export const TASK_MUTATIONS = {
  setStatus(taskId: string, statusId: TaskStatus) {
    return fetchRequest({
      method: 'PUT',
      url: 'Task/TaskStatus',
      body: JSON.stringify({ id: taskId, statusId })
    });
  },
  setAssignedTo(taskId: string, userId: string) {
    return fetchRequest({
      url: `Task/AssignedTo`,
      method: 'PUT',
      body: JSON.stringify({
        id: taskId,
        assignedTo: { userId }
      })
    });
  },
  setProjectSprint(taskId: string, projectId: string, sprintId: string) {
    return fetchRequest({
      url: `Task/TaskSprint`,
      method: 'PUT',
      body: JSON.stringify({
        id: taskId,
        sprintId,
        projectId
      })
    });
  },
  setPriority(taskId: string, priority: number) {
    return fetchRequest({
      url: `Task/TaskPriority`,
      method: 'PUT',
      body: JSON.stringify({ id: taskId, priority })
    });
  },
  setCustomColumn(taskId: string, groupId: string, columnId: string | null) {
    const params = new URLSearchParams({
      taskId,
      columnGroupId: groupId
    });
    if (columnId) params.set('columnId', columnId);

    return fetchRequest({
      url: `Task/TaskColumn?${params}`,
      method: 'PUT'
    });
  }
};

function addAdditionalGroupsToSprints(
  projectId: string,
  sprints: ISprintProps[],
  project: IProject,
  t: (id: string) => string
) {
  const newSprints = [...sprints];

  const sortSprintsByName = (columnA: ISprintProps, columnB: ISprintProps) => {
    const propA = columnA.name;
    const propB = columnB.name;
    return propA > propB ? 1 : -1;
  };

  if (project.type === ProjectType.Agile) {
    newSprints.unshift({
      id: UUID_1,
      name: t('subheader.projects.commandbar.productBacklog'),
      projectId
    });

    newSprints.push({
      id: UUID_F,
      name: t('subheader.projects.commandbar.removed'),
      projectId
    });
  } else if (project.type === ProjectType.Classic) {
    newSprints.sort(sortSprintsByName);

    newSprints.unshift({
      id: UUID_1,
      name: t('subheader.projects.commandbar.noColumn'),
      projectId
    });
  }

  return newSprints;
}

/** Loads project sprints with additional 'sprints' that are not part of the API response (backlog, removed). */
export function useProjectSprintsWithAdditionalGroups(projectId?: string) {
  const { t } = useTranslation();
  const {
    data: project,
    isLoading: isLoadingProject,
    forceLoad: forceLoadProject,
    error: projectError
  } = useApiObject(PROJECT, projectId);
  const {
    data: originalSprintsWrapped,
    isLoading: isLoadingSprints,
    error: sprintsError,
    forceLoad: forceLoadSprints,
    mutate
  } = useApiObject(PROJECTS_SPRINTS, projectId);

  const originalSprints = originalSprintsWrapped?.items;

  const data = useMemo(() => {
    if (project && originalSprints) {
      return addAdditionalGroupsToSprints(projectId, originalSprints, project, t);
    }
    return null;
  }, [projectId, project, originalSprints, t]);

  const forceLoad = useCallback(() => {
    forceLoadProject();
    forceLoadSprints();
  }, [forceLoadProject, forceLoadSprints]);

  return {
    data,
    mutate,
    isLoading: isLoadingProject || isLoadingSprints,
    error: projectError || sprintsError,
    forceLoad
  };
}

function shouldShowPlannerTasks(params: ITasksPageParams, view: ITasksView): boolean {
  return (
    view.filters.usePlanner &&
    params.filterType === TaskSearchType.MyOpenTasks &&
    view.grouping === TaskGroupingType.DateType
  );
}

function getFilterFetchProps({ params, view }: { params: ITasksPageParams; view: ITasksView }) {
  const body: ISearchTasksApiRequest = {};

  const {
    dateType,
    types,
    statuses,
    assignedToIds,
    creatorIds,
    priorities,
    assignedToTeamIds,
    definitionIds,
    projects,
    tags
  } = view.filters;

  if (dateType.length && dateType.length === 1) {
    const [firstDateType] = dateType;
    body.dateType = firstDateType;
  }
  if (types.length) {
    body.types = types;
  }
  if (statuses.length && view.grouping !== TaskGroupingType.Statuses) {
    body.statuses = statuses;
  }
  if (
    assignedToIds.length &&
    (view.type === TasksViewType.Dashboard || view.grouping !== TaskGroupingType.AssignedToIds)
  ) {
    body.assignedToIds = assignedToIds;
  }
  if (creatorIds.length) {
    body.creatorIds = creatorIds;
  }
  if (assignedToTeamIds.length) {
    body.assignedToTeamIds = assignedToTeamIds;
  }
  if (priorities.length) {
    body.priorities = priorities;
  }
  if (definitionIds.length) {
    body.definitionIds = definitionIds;
  }
  if (projects.length) {
    body.projectIds = projects;
  }

  if (tags.length) {
    body.tagIds = tags.map(({ id }) => id);
  }

  // add planner tasks
  if (shouldShowPlannerTasks(params, view)) {
    body.usePlanner = true;
  }

  return body;
}

export function getTasksFetchProps({
  params,
  view,
  currentUser,
  project,
  searchTerm,
  msTeamId
}: {
  params: ITasksPageParams;
  view: ITasksView;
  currentUser: IUserProps;
  project?: IProject;
  searchTerm: string;
  msTeamId?: string;
}): ISearchTasksApiRequest {
  const body: ISearchTasksApiRequest = {};

  if (params.filterType === TaskSearchType.OpenTasksByMe) {
    body.type = TaskSearchType.OpenTasksByMe;
    body.creatorIds = [currentUser.userId];
  } else if (params.filterType === TaskSearchType.OpenMentions) {
    body.type = TaskSearchType.OpenMentions;
    body.mentionedId = currentUser.userId;
  } else if (params.filterType === TaskSearchType.CompletedTasks) {
    body.type = TaskSearchType.CompletedTasks;
    body.statuses = [5];
  } else if (params.filterType === TaskSearchType.AllOpenTasks || params.teamId) {
    body.type = TaskSearchType.AllOpenTasks;
  } else if (params.filterType === TaskSearchType.MyOpenTasks) {
    body.type = TaskSearchType.MyOpenTasks;
    body.assignedToIds = [currentUser.userId];
  }

  // add selectedFilters
  Object.assign(body, getFilterFetchProps({ params, view }));

  // add search term
  if (searchTerm) {
    body.searchTerms = searchTerm;
  }

  // if tasks is running on Teams environment and msTeamId is configured add the id to url
  if (msTeamId && view.showMsTeamsTasks) {
    body.assignedToTeamIds = [msTeamId];
  }

  if (params.teamId) {
    body.assignedToTeamIds = [params.teamId];
  }

  // fetch all project tasks if project id is given
  if (params.projectId) {
    if (!view.filters?.projects?.length) {
      body.projectIds = [params.projectId];
    }

    if (
      params.sprintId !== UUID_F &&
      view.grouping === TaskGroupingType.SprintId &&
      !view.filters?.statuses?.length
    ) {
      body.statuses = [
        TaskStatus.NotStarted,
        TaskStatus.Deferred,
        TaskStatus.InProgress,
        TaskStatus.Waiting,
        TaskStatus.Completed,
        TaskStatus.Aborted
      ];
    }

    if (view.grouping === TaskGroupingType.SprintId && params.sprintId) {
      body.showAssociatedBacklogs = true;
    }

    if (project?.type === ProjectType.Classic) {
      body.sortType = TaskSortType.DueDate;
    }
  } else if (view.sorting) {
    body.sortType = view.sorting.type;
    body.sortAscending = view.sorting.ascending;
  }

  // fetch all project tasks if sprint id is given
  if (view.grouping !== TaskGroupingType.SprintId && params.projectId && params.sprintId) {
    body.sprintId = params.sprintId;
  }

  return body;
}

export function createColumnGroup(cache: ApiDataCache, { teamId }) {
  return fetchJson({
    url: `CustomColumns/Group`,
    method: 'POST',
    body: { teamId }
  }).then((result) => {
    const object = createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(CUSTOM_COLUMN_GROUP, result.id),
      result
    ) as ICustomColumnGroup;
    cache.insertObjectData(object);
    return object;
  });
}

export function getInitialGroupingType(projectType?: ProjectType, sprintId?: string) {
  if (projectType) {
    if (projectType === ProjectType.Classic) {
      return TaskGroupingType.SprintId;
    }

    if (projectType === ProjectType.Agile) {
      if (sprintId === UUID_1 || sprintId === UUID_F) {
        return TaskGroupingType.AssignedToIds;
      }
      if (!sprintId) {
        return TaskGroupingType.SprintId;
      }
    }

    return TaskGroupingType.Statuses;
  }
  return TaskGroupingType.DateType;
}
