import { DateTime, IApiObjectArray, Uuid, VoidParams } from './base-types';
import { IChatFile } from './chats';
import { IAgentWebsite } from './agents';
import {
  ApiObjectVariant,
  createSmallApiObjectDataFromPlainObject,
  IApiObjectData,
  IApiObjectType,
  makeApiObjectVariantKey
} from '../object';
import { fetchAIJson } from '../../../services/api2';
import { createFieldRefs } from './utils';

export const KB_FILE_TYPES = [
  // images are currently broken
  // '.jpg',
  // '.jpeg',
  // '.png',
  // '.gif',
  // '.bmp',
  '.pdf',
  '.ppt',
  '.pptx',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx'
];
export const KB_FILE_MAX_SIZE = 5 * 1024 * 1024;

export interface ITeamPublisher {
  $type: 'team';
  teamId: Uuid;
}

export interface IUserPublisher {
  $type: 'user';
  userId: Uuid;
}

export type IPublisher = ITeamPublisher | IUserPublisher;

export interface IKnowledgeBase extends IApiObjectData {
  id: Uuid;
  createdAt: DateTime;
  updatedAt: DateTime;
  name: string;
  description: string | null;
  files: IChatFile[] | null;
  websites: IAgentWebsite[] | null;
  publishedForTenant: boolean;
  userPublisherId: Uuid | null;
  teamPublisherId: Uuid | null;
  authorizedTeams: Uuid[] | null;
  isEnabled: boolean;
  publisher: IPublisher | null;
  permissions: {
    canUse: boolean;
    canEdit: boolean;
  } | null;
}

export enum KnowledgeBaseIncludes {
  Files = 'files',
  Websites = 'websites',
  AuthorizedTeams = 'authorizedTeams',
  Publisher = 'publisher',
  Permissions = 'permissions'
}

function compileKnowledgeBaseIncludes(includes: KnowledgeBaseIncludes[]) {
  const params = new URLSearchParams();

  for (const include of includes) params.append('includes', include);

  return params.size ? `?${params}` : '';
}

export const KNOWLEDGE_BASE: IApiObjectType<Uuid, IKnowledgeBase> = {
  id: 'knowledge-base',
  createRefs() {
    // nothing
  },
  async load(cache, params, abort, { includes }) {
    const result = await fetchAIJson(
      {
        url: `knowledge-bases/${params}${compileKnowledgeBaseIncludes(
          includes as KnowledgeBaseIncludes[]
        )}`,
        abort
      },
      { addUserId: true }
    );

    return createSmallApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(KNOWLEDGE_BASE, params, ApiObjectVariant.Small, includes),
      result
    ) as IKnowledgeBase;
  }
};

export const KNOWLEDGE_BASES: IApiObjectType<VoidParams, IApiObjectArray<IKnowledgeBase>> = {
  id: 'knowledge-bases',
  createRefs(cache, data, key) {
    createFieldRefs(
      cache,
      data,
      data.items.map((item, index) => ({
        path: `items/${index}`,
        type: KNOWLEDGE_BASE,
        params: () => item.id,
        includes: key.includes
      })),
      false
    );
  },
  async load(cache, params, abort, { includes }): Promise<IApiObjectArray<IKnowledgeBase>> {
    const result = await fetchAIJson(
      {
        url: `knowledge-bases${compileKnowledgeBaseIncludes(includes as KnowledgeBaseIncludes[])}`,
        abort
      },
      { addUserId: true }
    );

    return createSmallApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(KNOWLEDGE_BASES, params, ApiObjectVariant.Small, includes),
      { items: result }
    ) as IApiObjectArray<IKnowledgeBase>;
  }
};
