import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';

const FolderStyled = styled.div`
  display: block;
  margin: 0 1rem 3rem 1rem;
  text-align: center;
  position: relative;

  .icon {
    position: relative;
    width: 113px;
    margin: 0 auto;
  }

  .name {
    width: 113px;
    max-height: 55px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .number-instances {
    position: absolute;
    bottom: 6px;
    right: 6px;
    color: rgb(${({ theme }) => theme.folderView.folderIconForeground});
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${breakpoints.extraSmallMax}px) {
    margin: 0 1rem 2rem 1.5rem;
  }
`;

export default FolderStyled;
