import {
  ApiObjectVariant,
  createApiObjectDataFromPlainObject,
  IApiObjectCache,
  IApiObjectData,
  IApiObjectType,
  makeApiObjectVariantKey
} from '../object';
import { ITeam, IUser, TEAM, USER } from './users';
import {
  DateTime,
  DEFAULT_ITEMS_PER_PAGE,
  IApiObjectArray,
  ITextItem,
  Ref,
  Uuid
} from './base-types';
import { ProjectStatus, ProjectType } from '../../../types';
import { createFieldRefs, simpleIdObject } from './utils';
import { IApiListFetchResult, IApiListPage, IApiListType } from '../list';
import { ITagItem, TAG } from './tags';
import { fetchJson } from '../../../services/api2';
import { ISmallTask, TASK } from './tasks';

export interface ISprintItem {
  id: Uuid;
  projectId: Uuid;
  name: string | null;
  allNames: ITextItem[] | null;
  startDate: DateTime;
  endDate: DateTime;
  predecessor: unknown;
  successor: unknown;
}

export interface IProjectProcessAssociation {
  definitionId: Uuid;
  definitionName: string | null;
}

export interface IProjectPermissions {
  update: boolean;
  delete: boolean;
  following: boolean;
}

export interface IProject extends IApiObjectData {
  id: Uuid;
  intId: number;
  creationDate: DateTime;
  creator: Ref<IUser>;
  name: string | null;
  allNames: ITextItem[] | null;
  description: string | null;
  allDescriptions: ITextItem[] | null;
  type: ProjectType;
  team: Ref<ITeam>;
  status: ProjectStatus;
  startDate: DateTime | null;
  plannedEndDate: DateTime | null;
  numberOfSprints: number;
  sprintDuration: number;
  currentSprint: ISprintItem;
  percentComplete: number;
  confidential: boolean;
  url: string | null;
  assignedTeams: Ref<ITeam>[] | null;
  assignedUsers: Ref<IUser>[] | null;
  processAssociations: IProjectProcessAssociation[] | null;
  includedPages: Uuid[] | null;
  permissions: IProjectPermissions;
  tags: Ref<ITagItem>[] | null;
}

export const PROJECT = simpleIdObject<IProject>({
  id: 'Project',
  url: (id) => `Project/${id}`,
  fields: [
    { path: 'creator', type: USER, params: (data) => data.creator?.userId },
    { path: 'team', type: TEAM, variant: ApiObjectVariant.Small, params: (data) => data.team?.id }
  ],
  dynFields: (project) => [
    ...(project.assignedTeams?.map((team, index) => ({
      path: `assignedTeams/${index}`,
      type: TEAM,
      variant: ApiObjectVariant.Small,
      params: () => team.id
    })) ?? []),
    ...(project.assignedUsers?.map((user, index) => ({
      path: `assignedUsers/${index}`,
      type: USER,
      params: () => user.userId
    })) ?? []),
    ...(project.tags?.map((tag, index) => ({
      path: `tags/${index}`,
      type: TAG,
      params: () => tag.id
    })) ?? [])
  ]
});

export const PROJECTS_SPRINTS = simpleIdObject<IApiObjectArray<ISprintItem>>({
  id: 'Projects/Sprints',
  url: (id) => `Projects/Sprints?projectId=${encodeURIComponent(id)}`,
  wrapInArray: true
});

export enum ProjectSearchType {
  MyOpenProjects = 0,
  OpenProjectsByMe = 1,
  AllOpenProjects = 2,
  AllCompletedProjects = 3,
  AllProjects = 4
}

export interface ISearchProjectsApiRequest {
  type?: ProjectSearchType;
  /** A string to search projects' name and description */
  searchTerms?: string;
  /** If specified only projects tagged with this will be returned */
  tagId?: string;
}

export const PROJECTS: IApiListType<ISearchProjectsApiRequest, typeof PROJECT> = {
  id: 'Projects',
  async fetchPage(
    cache: IApiObjectCache,
    params: ISearchProjectsApiRequest,
    pageIndex: number,
    _: IApiListPage<typeof PROJECT>,
    abort?: AbortSignal
  ): Promise<IApiListFetchResult<typeof PROJECT>> {
    const urlBase = 'Projects';
    const urlParams = new URLSearchParams();
    urlParams.append('pageIndex', pageIndex.toString());

    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        urlParams.append(key, value);
      }
    });

    const url = `${urlBase}?${urlParams}`;

    const { items, searchProps } = await fetchJson({ url, abort });
    return {
      items: items.map((item: object) => {
        const id = makeApiObjectVariantKey(PROJECT, (item as IProject).id);
        cache.insertObjectData(createApiObjectDataFromPlainObject(cache, id, item));
        return id;
      }),
      searchProps
    };
  }
};

export enum ProjectFilterPropsSearchStatus {
  New = 1,
  InProgress = 2,
  OnHold = 3,
  Completed = 10,
  Deleted = 99
}

export interface IProjectFilterPropsSearchApiRequest {
  searchTerm?: string;
  tenantId: string;
  statuses?: ProjectFilterPropsSearchStatus[];
}

export const TENANT_PROJECTS: IApiObjectType<
  IProjectFilterPropsSearchApiRequest,
  IApiObjectArray<IProject>
> = {
  id: 'tenants/{tenantId}/projects',
  createRefs(cache: IApiObjectCache, data: IApiObjectArray<IProject>) {
    createFieldRefs(
      cache,
      data,
      data.items.map((project, index) => ({
        path: `items/${index}`,
        type: PROJECT,
        params: () => project.id
      }))
    );
  },
  async load(
    cache: IApiObjectCache,
    params: IProjectFilterPropsSearchApiRequest,
    abort: AbortSignal
  ): Promise<IApiObjectArray<IProject>> {
    const urlBase = `tenants/${params.tenantId}/projects`;
    const urlParams = new URLSearchParams();

    urlParams.append('searchTerm', params.searchTerm);

    if (params.statuses) {
      params.statuses.forEach((type) => {
        urlParams.append('includedProjectStatuses', type.toString());
      });
    }

    const url = `${urlBase}?${urlParams}`;

    const result = await fetchJson({ url, abort });
    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(TENANT_PROJECTS, params),
      { items: result }
    ) as IApiObjectArray<IProject>;
  }
};

export interface IProjectTemplateTasksParams {
  templateId: Uuid;
  version?: string | null;
  parentTaskId?: Uuid;
  onlyTopLevel?: boolean;
  itemsPerPage?: number;
}
export const PROJECT_TEMPLATE_TASKS: IApiListType<
  IProjectTemplateTasksParams,
  typeof TASK,
  void,
  ApiObjectVariant.Small
> = {
  id: 'Project/Template/Tasks',
  async fetchPage(
    cache: IApiObjectCache,
    params: IProjectTemplateTasksParams,
    pageIndex: number,
    _: unknown,
    abort: AbortSignal
  ) {
    const { items, searchProps } = await fetchJson({
      method: 'POST',
      url: 'Project/Template/Tasks',
      body: {
        pageIndex,
        itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
        ...params,
        // this parameter cannot be null, for some reason
        parentTaskId: params.parentTaskId ?? undefined
      },
      abort
    });

    return {
      items: items.map((item: object) => {
        const itemAsTask = item as ISmallTask;
        const id = makeApiObjectVariantKey(TASK, itemAsTask.id, ApiObjectVariant.Small);
        cache.insertObjectData(createApiObjectDataFromPlainObject(cache, id, item));
        return id;
      }),
      searchProps
    };
  }
};

export interface IProjectTemplateTaskPredecessorsParams {
  taskIds?: Uuid[];
  version?: string;
}

export interface IProjectTemplateTaskPredecessor {
  taskid: Uuid;
  id: Uuid;
  title: string | null;
  startDate: DateTime | null;
  dueDate: DateTime | null;
  sortOrder: number;
}

export const PROJECT_TEMPLATE_TASK_PREDECESSORS: IApiObjectType<
  IProjectTemplateTaskPredecessorsParams,
  IApiObjectArray<IProjectTemplateTaskPredecessor>
> = {
  id: 'Project/Template/TaskPredecessors',
  createRefs() {
    // nothing to do
  },
  async load(
    cache: IApiObjectCache,
    params: IProjectTemplateTaskPredecessorsParams,
    abort: AbortSignal
  ): Promise<IApiObjectArray<IProjectTemplateTaskPredecessor>> {
    const result = await fetchJson({
      url: `Project/Template/TaskPredecessors`,
      method: 'POST',
      body: params,
      abort
    });
    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(PROJECT_TEMPLATE_TASK_PREDECESSORS, params),
      { items: result }
    ) as IApiObjectArray<IProjectTemplateTaskPredecessor>;
  }
};
