import { useCallback, useMemo, useRef } from 'react';
import { z } from 'zod';
import {
  ITaskFilters,
  TaskFilters,
  TaskGrouping,
  TaskGroupingType,
  TasksViewType,
  ZTaskGrouping,
  ZTaskSorting
} from 'features/Tasks/model';
import { t } from 'i18next';
import { TaskSearchType, useApiDataCache, useApiObject } from './api2';
import { IClientStoreItem, MEMBER_CLIENT_STORE, TEAM_CLIENT_STORE } from './api2/types/clientStore';
import { fetchVoid } from '../services/api2';
import { useNotificationContext } from '../features/App';

export interface ITasksFilterViewV1 {
  filters: ITaskFilters;
  id: string;
  name: string;
  projectId?: string;
  scopeFilter?: string;
  teamId?: string;
  depiction: 'list' | 'grid' | 'gantt' | 'dashboard';
  groupBy?: 'dateType' | 'statuses' | 'assignedToIds' | 'sprintId' | 'columnId' | 'priorities';
  version?: number;
}

export interface ITasksFilterViewV2 {
  filters: ITaskFilters;
  id: string;
  name: string;
  projectId?: string;
  scopeFilter?: string;
  teamId?: string;
  depiction: TasksViewType;
  groupBy?: TaskGrouping;
  version: 2;
}

const TasksFilterViewV3 = z.object({
  filters: TaskFilters,
  id: z.string().uuid(),
  name: z.string(),
  projectId: z.string().uuid().optional(),
  scopeFilter: z.nativeEnum(TaskSearchType).optional(),
  teamId: z.string().uuid().optional(),
  depiction: z.nativeEnum(TasksViewType),
  groupBy: ZTaskGrouping
});

const TasksFilterViewV4 = TasksFilterViewV3.extend({
  sorting: ZTaskSorting
});

export const TasksFilterView = TasksFilterViewV4;

type ITasksFilterViewV3 = z.infer<typeof TasksFilterViewV3>;
type ITasksFilterViewV4 = z.infer<typeof TasksFilterViewV4>;

export type ITasksFilterView = ITasksFilterViewV4;

const MemberDataV2 = z.object({
  version: z.literal(2),
  views: TasksFilterViewV3.array(),
  scopeDefaults: z.record(z.string().regex(/^\d+$/), z.string()).optional(),
  teamDefaults: z.record(z.string().uuid(), z.string())
});

const MemberDataV3 = z.object({
  version: z.literal(3),
  views: TasksFilterViewV4.array(),
  scopeDefaults: z.record(z.string().regex(/^\d+$/), z.string()).optional(),
  teamDefaults: z.record(z.string().uuid(), z.string())
});

type IMemberDataV2 = z.infer<typeof MemberDataV2>;
type IMemberDataV3 = z.infer<typeof MemberDataV3>;
type IMemberData = IMemberDataV3;

const TeamDataV2 = z.object({
  version: z.literal(2),
  views: TasksFilterViewV3.array()
});

const TeamDataV3 = z.object({
  version: z.literal(3),
  views: TasksFilterViewV4.array()
});

type ITeamDataV2 = z.infer<typeof TeamDataV2>;
type ITeamDataV3 = z.infer<typeof TeamDataV3>;
type ITeamData = ITeamDataV3;

function groupingV1ToV2(value: string) {
  switch (value) {
    case 'statuses':
      return TaskGroupingType.Statuses;
    case 'assignedToIds':
      return TaskGroupingType.AssignedToIds;
    case 'sprintId':
      return TaskGroupingType.SprintId;
    case 'priorities':
      return TaskGroupingType.Priorities;
    default:
      return TaskGroupingType.DateType;
  }
}

function viewTypeV1ToV2(value: string) {
  switch (value) {
    case 'dashboard':
      return TasksViewType.Dashboard;
    case 'gantt':
      return TasksViewType.Gantt;
    case 'grid':
      return TasksViewType.Grid;
    case 'list':
      return TasksViewType.List;
    default:
      return TasksViewType.Kanban;
  }
}

function filterViewV1ToV2(view: ITasksFilterViewV1 | ITasksFilterViewV2): ITasksFilterViewV2 {
  if (!('version' in view)) {
    const viewV1 = view as ITasksFilterViewV1;

    return {
      ...viewV1,
      depiction: viewTypeV1ToV2(viewV1.depiction),
      groupBy: groupingV1ToV2(viewV1.groupBy),
      version: 2
    };
  }

  return view as ITasksFilterViewV2;
}

function scopeFilterToSearchType(value: string): TaskSearchType | undefined {
  switch (value) {
    case 'my-open':
      return TaskSearchType.MyOpenTasks;
    case 'all':
      return TaskSearchType.AllOpenTasks;
    case 'allocated':
      return TaskSearchType.OpenTasksByMe;
    case 'involved':
      return TaskSearchType.OpenMentions;
    case 'completed':
      return TaskSearchType.CompletedTasks;
    default:
      return undefined;
  }
}

type OnError = (error: string) => void;

function filterViewV2ToV3(view: ITasksFilterViewV2, onError: OnError): ITasksFilterViewV3 | null {
  const probably: ITasksFilterViewV3 = {
    filters: view.filters,
    id: view.id,
    name: view.name,
    projectId: view.projectId || undefined,
    scopeFilter: scopeFilterToSearchType(view.scopeFilter),
    teamId: view.teamId || undefined,
    depiction: view.depiction,
    groupBy: view.groupBy
  };
  const result = TasksFilterViewV3.safeParse(probably);
  if (!result.success) {
    // eslint-disable-next-line no-console
    console.error(`error migrating filter view V2 data: ${result.error}`, view);
    onError(`a saved view “${view?.name}” could not be loaded because of invalid data`);
    return null;
  }
  return result.data;
}

function filterViewV3ToV4(view: ITasksFilterViewV3): ITasksFilterViewV4 {
  return TasksFilterViewV4.parse({ ...view, sorting: null });
}

function memberDataV1ToV2(
  value: (ITasksFilterViewV1 | ITasksFilterViewV2)[],
  onError: OnError
): IMemberDataV2 {
  return {
    version: 2,
    views: value
      .map(filterViewV1ToV2)
      .map((view) => filterViewV2ToV3(view, onError))
      .filter((x) => x),
    scopeDefaults: {},
    teamDefaults: {}
  };
}

function memberDataV2ToV3(value: IMemberDataV2): IMemberDataV3 {
  return {
    version: 3,
    views: value.views.map(filterViewV3ToV4),
    scopeDefaults: value.scopeDefaults,
    teamDefaults: value.teamDefaults
  };
}

function teamDataV1ToV2(
  value: (ITasksFilterViewV1 | ITasksFilterViewV2)[],
  onError: OnError
): ITeamDataV2 {
  return {
    version: 2,
    views: value
      .map(filterViewV1ToV2)
      .map((view) => filterViewV2ToV3(view, onError))
      .filter((x) => x)
  };
}

function teamDataV2ToV3(value: ITeamDataV2): ITeamDataV3 {
  return {
    version: 3,
    views: value.views.map(filterViewV3ToV4)
  };
}

function migrateMemberData(
  value: unknown,
  onError: OnError,
  setIsFutureSchema: () => void
): IMemberData {
  if (value && typeof value === 'object' && 'version' in value) {
    const { version } = value as { version: number };
    if (version > 3) {
      setIsFutureSchema();
      return EMPTY_MEMBER_DATA;
    }

    const migrations = [memberDataV2ToV3];
    let newValue = value;
    for (const migration of migrations.slice(version - 2)) {
      newValue = migration(newValue);
      if (!newValue) return EMPTY_MEMBER_DATA;
    }

    const result = MemberDataV3.safeParse(newValue);
    if (!result.success) {
      // eslint-disable-next-line no-console
      console.error(`error reading member views: ${result.error}`, newValue);
      onError(`error reading saved member views because of invalid data`);
      return EMPTY_MEMBER_DATA;
    }
    return result.data;
  }
  return migrateMemberData(
    memberDataV1ToV2(value as (ITasksFilterViewV1 | ITasksFilterViewV2)[], onError),
    onError,
    setIsFutureSchema
  );
}

function migrateTeamData(
  value: unknown,
  onError: OnError,
  setIsFutureSchema: () => void
): ITeamData {
  if (value && typeof value === 'object' && 'version' in value) {
    const { version } = value as { version: number };
    if (version > 3) {
      setIsFutureSchema();
      return EMPTY_TEAM_DATA;
    }

    const migrations = [teamDataV2ToV3];
    let newValue = value;
    for (const migration of migrations.slice(version - 2)) {
      newValue = migration(newValue);
      if (!newValue) return EMPTY_TEAM_DATA;
    }

    const result = TeamDataV3.safeParse(newValue);
    if (!result.success) {
      // eslint-disable-next-line no-console
      console.error(`error reading member views: ${result.error}`, newValue);
      onError(`error reading saved member views because of invalid data`);
      return EMPTY_MEMBER_DATA;
    }
    return result.data;
  }
  return migrateTeamData(
    teamDataV1ToV2(value as (ITasksFilterViewV1 | ITasksFilterViewV2)[], onError),
    onError,
    setIsFutureSchema
  );
}

const EMPTY_MEMBER_DATA: IMemberDataV3 = {
  version: 3,
  views: [],
  scopeDefaults: {},
  teamDefaults: {}
};

const EMPTY_TEAM_DATA: ITeamDataV3 = {
  version: 3,
  views: []
};

type Defaults = Pick<IMemberDataV3, 'scopeDefaults' | 'teamDefaults'>;

export interface UseTasksFilterView {
  isLoading: boolean;
  error?: Error;
  views: ITasksFilterView[];
  mutateViews: (changes: (views: ITasksFilterView[]) => ITasksFilterView[]) => Promise<void>;
  defaults: Defaults;
  mutateDefaults: (changes: (defaults: Defaults) => Defaults) => Promise<void>;
}

let cachedFutureError: Error | null = null;
function getFutureError() {
  if (!cachedFutureError) {
    cachedFutureError = new Error(t('subheader.tasks.commandbar.views.futureError'));
    cachedFutureError.toString = () => cachedFutureError.message;
  }
  return cachedFutureError;
}

export default function useTasksFilterView({
  teamId
}: {
  teamId: string | null | undefined;
}): UseTasksFilterView {
  const {
    isLoading: isLoadingMember,
    error: errorMember,
    data: memberDataValue,
    mutate: mutateMember
  } = useApiObject(MEMBER_CLIENT_STORE, 'views');
  const {
    isLoading: isLoadingTeam,
    error: errorTeam,
    data: teamDataValue,
    mutate: mutateTeam
  } = useApiObject(TEAM_CLIENT_STORE, teamId ? { teamId, store: 'views' } : null);

  const { showError: showErrorInner } = useNotificationContext();
  const showError = useCallback(
    (error: string) => {
      // setTimeout to avoid recursive react update
      setTimeout(() => showErrorInner(error), 10);
    },
    [showErrorInner]
  );

  const isLoading = isLoadingMember || isLoadingTeam;
  const error = errorMember || errorTeam;

  const [memberData, memberDataIsFutureSchema] = useMemo(() => {
    if (memberDataValue?.value) {
      let isFuture = false;
      const data = migrateMemberData(memberDataValue.value, showError, () => {
        isFuture = true;
      });

      return [data, isFuture];
    }
    return [EMPTY_MEMBER_DATA, false];
  }, [memberDataValue, showError]);

  const [teamData, teamDataIsFutureSchema] = useMemo(() => {
    if (teamDataValue?.value) {
      let isFuture = false;
      const data = migrateTeamData(teamDataValue.value, showError, () => {
        isFuture = true;
      });
      return [data, isFuture];
    }
    return [EMPTY_TEAM_DATA, false];
  }, [teamDataValue, showError]);

  const views = teamId ? teamData.views : memberData.views;

  const currentData = useRef({ memberData, teamData });
  currentData.current = { memberData, teamData };

  const cache = useApiDataCache();
  const mutateMemberData = useCallback(
    (changes: (data: IMemberData) => IMemberData) => {
      if (memberDataIsFutureSchema) return Promise.reject(getFutureError());

      const promise = fetchVoid({
        url: `tenants/${cache.tenantId}/members/${cache.memberId}/client-store/views`,
        method: 'PUT',
        body: JSON.stringify(changes(currentData.current.memberData))
      });
      const overlay = (data: IClientStoreItem) => ({
        value: changes(migrateMemberData(data.value ?? EMPTY_MEMBER_DATA, showError, () => null))
      });
      mutateMember(
        promise.then(() => overlay),
        overlay
      );
      return promise;
    },
    [cache, mutateMember, showError, memberDataIsFutureSchema]
  );

  const mutateTeamData = useCallback(
    (changes: (data: ITeamData) => ITeamData) => {
      if (teamDataIsFutureSchema) return Promise.reject(getFutureError());

      const promise = fetchVoid({
        url: `tenants/${cache.tenantId}/teams/${teamId}/client-store/views`,
        method: 'PUT',
        body: JSON.stringify(changes(currentData.current.teamData))
      });
      const overlay = (data: IClientStoreItem) => ({
        value: changes(migrateTeamData(data.value ?? EMPTY_TEAM_DATA, showError, () => null))
      });
      mutateTeam(
        promise.then(() => overlay),
        overlay
      );
      return promise;
    },
    [cache, teamId, mutateTeam, showError, teamDataIsFutureSchema]
  );

  const mutateViews = useCallback(
    (changes: (views: ITasksFilterView[]) => ITasksFilterView[]) => {
      if (teamId) {
        return mutateTeamData((data) => ({ ...data, views: changes(data.views) }));
      }

      return mutateMemberData((data) => ({ ...data, views: changes(data.views) }));
    },
    [teamId, mutateTeamData, mutateMemberData]
  );

  const mutateDefaults = useCallback(
    (changes: (defaults: Defaults) => Defaults) =>
      mutateMemberData((data) => {
        const currentDefaults: Defaults = {
          scopeDefaults: data.scopeDefaults,
          teamDefaults: data.teamDefaults
        };

        return {
          ...data,
          ...changes(currentDefaults)
        };
      }),
    [mutateMemberData]
  );

  return {
    isLoading,
    error: memberDataIsFutureSchema || teamDataIsFutureSchema ? getFutureError() : error,
    views,
    mutateViews,
    defaults: memberData,
    mutateDefaults
  };
}
