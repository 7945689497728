import { useBubblesStore } from 'features/App/context/BubblesStore';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { matchPath, useLocation } from 'react-router-dom';
import fetchRequest from 'services/api';
import styled from 'styled-components';
import { ViewStatus } from 'types';
import { TeachingBubble, getTheme } from '@fluentui/react';
import useElementObserver from './useElementObserver';

const Container = styled.div`
  .top-left,
  .bottom-left,
  .top-right,
  .bottom-right,
  .center {
    position: absolute;
  }

  .center {
    top: calc(50% - 76px);
    left: calc(50% - 182px);
  }

  .top-left {
    top: 55px;
    left: 10px;
  }

  .bottom-left {
    bottom: 10px;
    left: 10px;
  }

  .top-right {
    top: 55px;
    right: 10px;
  }

  .bottom-right {
    bottom: 10px;
    right: 10px;
  }
`;

const { crossApiOrigin = 'https://befa945a.azurewebsites.net' } = window.config;

export default function useOnBoarding(currentUser) {
  const activeBubbleGroups = useBubblesStore((state) => state.activeBubbleGroups);
  const setBubbleStatus = useBubblesStore((state) => state.setBubbleStatus);
  const [bubblesToRender, setBubblesToRender] = useState([]);
  const trainers = useBubblesStore((state) => state.trainers);
  const [stepIndex, setStepIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const [urlChanged, setUrlChanged] = useState(true);

  const [target, setTarget] = useState(null);
  const [targetElement, removeElement] = useElementObserver(target);

  const { pathname } = useLocation();

  useEffect(() => {
    const logEvent = (evt) => {
      if (targetElement && evt.type === 'focus') {
        evt.preventDefault();
        evt.stopPropagation();
        evt.stopImmediatePropagation();
      }
    };

    ['focus'].forEach((evtType) => {
      document.addEventListener(evtType, logEvent, true);
    });

    return () => {
      ['focus'].forEach((evtType) => {
        document.removeEventListener(evtType, logEvent, true);
      });
    };
  }, [target, targetElement]);

  function checkIfParamsMatchCurrentPath(params, matchedPath) {
    const { path } = matchedPath.pattern;
    const paramsToCheck = { ...params };

    // first filter all params that are not in the path
    Object.keys(params).forEach((param) => {
      if (!path.includes(`:${param}`)) {
        delete paramsToCheck[param];
      }
    });

    if (!Object.keys(paramsToCheck)?.length) return true;

    // check if params match current path
    return Object.keys(paramsToCheck).every((param) => {
      const paramValues = paramsToCheck[param];

      if (!paramValues?.length) return true;

      const paramValue = matchedPath.params[param];

      return paramValues.includes(paramValue);
    });
  }

  useEffect(() => {
    const itemsToRender = [];
    setTarget(null);

    activeBubbleGroups.forEach(({ bubbles }) => {
      if (!bubbles?.length) return;

      if (bubbles.some(({ viewStatus }) => viewStatus === ViewStatus.deferred) && !urlChanged) {
        return;
      }

      bubbles.find((bubble) => {
        const { viewStatus } = bubble;

        if (
          viewStatus === ViewStatus.new ||
          viewStatus === ViewStatus.running ||
          (viewStatus === ViewStatus.deferred && urlChanged)
        ) {
          itemsToRender.push(bubble);

          return true;
        }

        return false;
      });
    });

    setBubblesToRender(itemsToRender);
  }, [activeBubbleGroups, pathname, urlChanged]);

  useEffect(() => {
    // useEffect to set url changed to true when the url changes
    // this is used to show the bubbles that are deferred
    setUrlChanged(true);
  }, [pathname]);

  function getBubbleTarget(bubble) {
    const { target } = bubble;

    if (target === 'TopLeft') {
      return '#help-bubble-top-left';
    }

    if (target === 'TopRight') {
      return '#help-bubble-top-right';
    }

    if (target === 'BottomLeft') {
      return '#help-bubble-bottom-left';
    }

    if (target === 'BottomRight') {
      return '#help-bubble-bottom-right';
    }

    if (target === 'center') {
      return '#help-bubble-center';
    }

    return `#${target}`;
  }

  function fetchBubbleStatus({ bubbleId, status }) {
    const trainer = trainers.find((trainer) =>
      trainer.trainings.some((t) => t.bubbles.some((b) => b.id === bubbleId))
    );
    const training = trainer.trainings.find((t) => t.bubbles.some((b) => b.id === bubbleId));

    return fetchRequest({
      method: 'POST',
      ignoreAlert: true,
      body: JSON.stringify({
        userId: currentUser?.userId,
        trainerId: trainer.id,
        trainingId: training.id,
        trainingBubbleId: bubbleId,
        viewStatus: status
      }),
      url: 'help/Trainer/Status',
      origin: `${crossApiOrigin}/api`
    });
  }

  function onBubbleDismiss(bubble, defaultDismiss) {
    // default dismiss is called when the user clicks on the close button
    // set status to deffered if the user clicks on the close button
    // set status to read if the user clicks on the primary button
    setLoading(true);

    if (defaultDismiss) {
      setUrlChanged(false);
    }

    const status = defaultDismiss ? ViewStatus.deferred : ViewStatus.read;
    setBubbleStatus(bubble.id, status);

    setStepIndex(0);
    removeElement();
    setTarget(null);

    setTimeout(() => {
      setLoading(false);
    }, 100);

    fetchBubbleStatus({ bubbleId: bubble.id, status });
  }

  function renderBubble(bubble) {
    if (loading) {
      return null;
    }

    const targetString = getBubbleTarget(bubble);

    if (!targetString) return null;

    if (!target) {
      setTarget(targetString);
    }

    if (!targetElement) {
      return null;
    }

    const hasSubSteps = bubble.subSteps?.length;
    let contentText = bubble.mainText;
    let { title } = bubble;

    if (hasSubSteps) {
      title = bubble.subSteps[stepIndex].title;
      contentText = bubble.subSteps[stepIndex].mainText;
    }

    return (
      <TeachingBubble
        key={bubble.id}
        calloutProps={{
          isBeakVisible: !target?.includes('#help-bubble'),
          directionalHint: target?.includes('top') ? 1 : undefined,
          dismissOnTargetClick: true,
          layerProps: { styles: { root: { zIndex: 10000000, opacity: 0.9 } } }
        }}
        focusTrapZoneProps={{
          disabled: true
        }}
        styles={{
          subComponentStyles: {
            callout: {
              calloutMain: {
                borderRadius: '8px',
                background: `${getTheme().palette.themeDarker} !important`
              },
              beak: { background: getTheme().palette.themeDarker }
            }
          },
          imageContent: { margin: 10 },
          closeButton: { borderRadius: 4 }
        }}
        target={target}
        primaryButtonProps={{
          children: bubble.primaryButtonLabel,
          onClickCapture: () => {
            if (hasSubSteps && stepIndex < bubble.subSteps.length - 1) {
              setStepIndex(stepIndex + 1);

              return;
            }

            onBubbleDismiss(bubble, false);
          },
          styles: { root: { borderRadius: 4 } }
        }}
        illustrationImage={{ src: bubble.imgSrc, imageFit: 1 }}
        hasCloseButton
        onDismiss={(event) => {
          let { target } = event;
          let isCancelButton = false;

          // check if target itself has the class
          if (target?.classList?.contains('ms-TeachingBubble-closebutton')) {
            isCancelButton = true;
          }

          // check if any parent of the target has the class
          while (target?.parentNode) {
            target = target.parentNode;
            if (target.classList && target.classList.contains('ms-TeachingBubble-closebutton')) {
              isCancelButton = true;
            }
          }

          if (isCancelButton) {
            onBubbleDismiss(bubble, true);
          }

          // check if the target is the bubble itself or any of its children
          if (
            (target instanceof Node && targetElement.contains(target)) ||
            event.target === targetElement ||
            event.target?.parentNode === targetElement ||
            event.target?.parentNode?.parentNode === targetElement ||
            event.target?.parentNode?.parentNode?.parentNode === targetElement ||
            event.target?.parentNode?.parentNode?.parentNode.parentNode === targetElement
          ) {
            onBubbleDismiss(bubble, false);
          }
        }}
        headline={title}
      >
        {contentText}
      </TeachingBubble>
    );
  }

  const bubbleToRender = bubblesToRender.find((bubble) => {
    const bubbleWithMatchedPath = bubble.paths.find((pathItem) => {
      const { path, params } = pathItem;
      const matchedPath = matchPath(path, pathname);

      if (matchedPath) {
        return checkIfParamsMatchCurrentPath(params, matchedPath);
      }

      return false;
    });

    return bubbleWithMatchedPath;
  });

  return ReactDOM.createPortal(
    <Container>
      <div className="center" id="help-bubble-center" />
      <div className="top-left" id="help-bubble-top-left" />
      <div className="top-right" id="help-bubble-top-right" />
      <div className="bottom-left" id="help-bubble-bottom-left" />
      <div className="bottom-right" id="help-bubble-bottom-right" />
      {bubbleToRender && renderBubble(bubbleToRender)}
    </Container>,
    document.querySelector('body')
  );
}
