import { ApiError, ApiErrorType, fetchAIBlob, fetchAIJson } from '../../../services/api2';
import {
  ApiObjectVariant,
  createApiObjectDataFromPlainObject,
  createSmallApiObjectDataFromPlainObject,
  IApiObjectCache,
  IApiObjectData,
  IApiObjectType,
  LocalApiObject,
  makeApiObjectVariantKey
} from '../object';
import { IApiObjectArray, Uuid, VoidParams } from './base-types';
import { createFieldRefs } from './utils';
import {
  EMPTY_START_SETTINGS,
  IStartSettingsV1,
  StartSettingsV1
} from '../../../pages/AI/components/StartFormSettings/model';
import { IPublisher, KNOWLEDGE_BASE } from './knowledgeBases';

interface IBaseDataSourceItem {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface IPlugin extends IBaseDataSourceItem {
  type: 'plugin';
  isEnabled: boolean;
}

export interface IAgentWebsite {
  id: Uuid;
  createdAt: string;
  updatedAt: string;
  name: string;
  url: string;
}

export interface IAgentKnowledgeBase {
  id: string;
  isEnabled: boolean;
}

export interface IAgentConfigurableFields {
  name: string;
  description: string;
  systemMessage: string;
  knowledgeBases: IAgentKnowledgeBase[] | null;
  plugins: IPlugin[] | null;
  subAgents: IOrderedSubAgent[] | null;
  publisher: IPublisher;
  publishedForTenant: boolean;
  authorizedTeams: Uuid[] | null;
  fields: IStartSettingsV1;
  maximumIterations: number;
  temperature: number | null;
}

export interface IAgent extends IApiObjectData, IAgentConfigurableFields {
  id: Uuid;
  permissions: { canUse: boolean; canEdit: boolean };
  hasParentAgents: boolean;
  hasSubAgents: boolean;
  disabled: boolean;
  /** Opaque code that changes when the thumbnail is updated */
  thumbnailHashCode: number;
  favorited: boolean;
}

export interface IOrderedSubAgent extends LocalApiObject<IAgent> {
  index: number;
}

/** Parses agent data from the backend to IAgentConfigurableFields */
export function parseAgentConfigurableFields(data: unknown): IAgentConfigurableFields {
  let fields: IStartSettingsV1;
  try {
    const data2 = data as { fields: string | null };
    if (data2.fields) {
      const dataFields = typeof data2.fields === 'string' ? JSON.parse(data2.fields) : data2.fields;
      fields = StartSettingsV1.parse(dataFields);
    } else {
      fields = EMPTY_START_SETTINGS;
    }
  } catch (error) {
    throw new ApiError(ApiErrorType.ClientSchema, 406, error.toString(), undefined, error);
  }

  return { ...(data as IAgentConfigurableFields), fields };
}

export function parseAgentData(data: unknown): LocalApiObject<IAgent> {
  const data2 = data as LocalApiObject<IAgent>;

  return {
    id: data2.id,
    ...parseAgentConfigurableFields(data),
    permissions: data2.permissions,
    hasSubAgents: data2.hasSubAgents,
    hasParentAgents: data2.hasParentAgents,
    disabled: data2.disabled,
    thumbnailHashCode: data2.thumbnailHashCode,
    favorited: data2.favorited
  };
}

export enum AgentIncludes {
  Plugins = 'plugins',
  KnowledgeBases = 'knowledgeBases',
  AuthorizedTeams = 'authorizedTeams',
  SubAgents = 'subAgents',
  Publisher = 'publisher',
  Permissions = 'permissions',
  Favorited = 'favorited'
}

function compileAgentIncludes(includes: AgentIncludes[]): string {
  const search = new URLSearchParams();

  for (const include of includes) {
    search.append('includes', include);
  }

  return search.size ? `?${search.toString()}` : '';
}

export const AGENT: IApiObjectType<Uuid, IAgent, IAgent> = {
  id: 'agent/:id',
  createRefs(cache: IApiObjectCache, data: IAgent): void {
    if (data.knowledgeBases) {
      createFieldRefs(
        cache,
        data,
        data.knowledgeBases.map((kb, index) => ({
          path: `knowledgeBases/${index}`,
          type: KNOWLEDGE_BASE,
          params: () => kb.id,
          includes: [],
          sparseRef: () => ({ id: kb.id, isEnabled: kb.isEnabled })
        }))
      );
    }
  },
  async load(cache: IApiObjectCache, id: Uuid, abort: AbortSignal, { includes }): Promise<IAgent> {
    if (!includes) throw new Error('AGENT requires includes');

    const url = `agents/${encodeURIComponent(id)}${compileAgentIncludes(
      includes as AgentIncludes[]
    )}`;

    const result = await fetchAIJson({ url, abort }, { addUserId: true });

    return createSmallApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(AGENT, id, ApiObjectVariant.Small, includes),
      parseAgentData(result)
    ) as IAgent;
  }
};

export interface IAgentThumbnail extends IApiObjectData {
  data: Blob | null;
}

interface IAgentThumbnailId {
  id: string;
  thumbnailHashCode: number;
}

export const AGENT_THUMBNAIL: IApiObjectType<IAgentThumbnailId, IAgentThumbnail> = {
  id: 'agents/:agent/thumbnail',
  // immutable with thumbnailHashCode
  loadDedupIntervalSecs: 86400,
  createRefs() {
    // nothing
  },
  async load(
    cache: IApiObjectCache,
    params: IAgentThumbnailId,
    abort: AbortSignal
  ): Promise<IAgentThumbnail> {
    const url = `agents/${encodeURIComponent(params.id)}/thumbnail?_key=${
      params.thumbnailHashCode
    }`;

    try {
      const result = await fetchAIBlob({ url, abort }, { addUserId: true });
      return createApiObjectDataFromPlainObject(
        cache,
        makeApiObjectVariantKey(AGENT_THUMBNAIL, params),
        { data: result }
      ) as IAgentThumbnail;
    } catch (error) {
      if (error instanceof ApiError && error.status === 404) {
        return createApiObjectDataFromPlainObject(
          cache,
          makeApiObjectVariantKey(AGENT_THUMBNAIL, params),
          { data: null }
        ) as IAgentThumbnail;
      }
      throw error;
    }
  }
};

export const AGENTS: IApiObjectType<VoidParams, IApiObjectArray<IAgent>> = {
  id: 'agents',
  createRefs(cache: IApiObjectCache, data: IApiObjectArray<IAgent>, key) {
    createFieldRefs(
      cache,
      data,
      data.items.map((item, index) => ({
        path: `items/${index}`,
        type: AGENT,
        includes: key.includes,
        params: () => item.id
      })),
      // always insert to force update
      false
    );
  },
  async load(
    cache: IApiObjectCache,
    params: VoidParams,
    abort: AbortSignal,
    { includes }
  ): Promise<IApiObjectArray<IAgent>> {
    if (!includes) throw new Error('AGENTS requires includes');

    const url = `agents${compileAgentIncludes(includes as AgentIncludes[])}`;
    const result = await fetchAIJson({ url, abort }, { addUserId: true });

    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(AGENTS, params, ApiObjectVariant.Small, includes),
      {
        items: result.map(parseAgentData)
      }
    ) as IApiObjectArray<IAgent>;
  }
};
