import { fetchJson } from '../../../services/api2';
import { DepartmentType, MemberRole, TeamType } from '../../../types';
import {
  AsSmallApiObjectData,
  createApiObjectDataFromPlainObject,
  IApiObjectCache,
  IApiObjectData,
  IApiObjectType,
  makeApiObjectVariantKey
} from '../object';
import { IApiObjectArray, ITextItem, Ref, Uuid } from './base-types';
import { COUNTRY, ICountryItem, ILocationItem, LOCATION } from './locations';
import { createFieldRefs, simpleIdObject } from './utils';

export interface IUser extends IApiObjectData {
  userId: Uuid;
  login: string | null;
  name: string | null;
  email: string | null;
  pictureUrl: string | null;
  tenantId: Uuid;
}

export interface IMemberItem {
  userId: Uuid;
  login: string | null;
  name: string | null;
  email: string | null;
  pictureUrl: string | null;
  tenantId: Uuid;
  teamId: Uuid;
  roleId: MemberRole;
}

export interface ITeamPermissions {
  update: boolean;
  delete: boolean;
}

// TODO
export enum TeamStatus {
  Unknown1 = 1,
  Unknown2 = 2
}

export interface ITeam extends IApiObjectData {
  id: Uuid;
  parent: Ref<ISmallTeamItem> | null;
  intId: number;
  title: string | null;
  allTitles: ITextItem[] | null;
  readonly displayTitle: string | null;
  typeId: TeamType;
  departmentId: DepartmentType;
  country: Ref<ICountryItem> | null;
  location: Ref<ILocationItem> | null;
  members: IMemberItem[] | null;
  permissions: ITeamPermissions;
  status: TeamStatus;
}

export interface ISmallTeamItem extends AsSmallApiObjectData<ITeam> {
  id: Uuid;
  title: string | null;
}

export const USER = simpleIdObject<IUser>({
  id: 'User',
  url: (id) => `User/Get?epId=${encodeURIComponent(id)}`
});

export type IUserRef = Pick<IUser, 'userId' | 'email'>;
export const SPARSE_USER_REF = (user: IUser) => ({ userId: user.userId, email: user.email });

export const TEAM: IApiObjectType<string, ITeam, ISmallTeamItem> = simpleIdObject<ITeam>({
  id: 'Team',
  url: (id) => `Team/${id}`,
  fields: [
    { path: 'country', type: COUNTRY, params: (data) => data.country?.id },
    { path: 'location', type: LOCATION, params: (data) => data.location?.id }
  ]
});

export type ITeamRef = Pick<ITeam, 'id'>;
export const SPARSE_TEAM_REF = (team: ITeam) => ({ id: team.id });

export interface IUserSearchResult extends IApiObjectData {
  consentNeeded: boolean;
  items: Ref<IUser>[];
}

export enum UserSearchType {
  All = 0,
  TeamMembers = 1,
  AssignTasks = 2,
  AssignApprovals = 3,
  ReAssignTask = 4,
  RoutePersonFields = 5,
  MentioningInComments = 6,
  UseInRouteDefinitions = 7,
  PersonDataFields = 8
}

export interface IUserSearchApiRequest {
  searchTerm?: string;
  type: UserSearchType;
  teamId?: string;
}

export interface ITeamSearchApiRequest {
  searchTerm?: string;
  userId?: Uuid;
  userId2?: Uuid;
}

export const USER_SEARCH: IApiObjectType<IUserSearchApiRequest, IUserSearchResult> = {
  id: 'User/Search',
  createRefs(cache: IApiObjectCache, data: IUserSearchResult) {
    createFieldRefs(
      cache,
      data,
      data.items.map((user, index) => ({
        path: `items/${index}`,
        type: USER,
        params: () => user.userId
      }))
    );
  },
  async load(
    cache: IApiObjectCache,
    params: IUserSearchApiRequest,
    abort: AbortSignal
  ): Promise<IUserSearchResult> {
    const urlBase = 'User/Search';
    const urlParams = new URLSearchParams();

    urlParams.append('searchTerm', params.searchTerm ?? '***');
    urlParams.append('type', params.type.toString());
    if (params.teamId) urlParams.append('teamId', params.teamId);

    const url = `${urlBase}?${urlParams}`;

    const result = await fetchJson({ url, abort });
    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(USER_SEARCH, params),
      result
    ) as IUserSearchResult;
  }
};

export const TEAM_SEARCH: IApiObjectType<ITeamSearchApiRequest, IApiObjectArray<ISmallTeamItem>> = {
  id: 'Team/Search',
  createRefs(cache: IApiObjectCache, data: IApiObjectArray<ISmallTeamItem>) {
    createFieldRefs(
      cache,
      data,
      data.items.map((item, index) => ({
        path: `items/${index}`,
        type: TEAM,
        params: () => item.id
      }))
    );
  },
  async load(
    cache: IApiObjectCache,
    params: ITeamSearchApiRequest,
    abort: AbortSignal
  ): Promise<IApiObjectArray<ISmallTeamItem>> {
    const urlBase = 'Teams';
    const urlParams = new URLSearchParams();

    if (params.searchTerm) urlParams.append('searchTerms', params.searchTerm);
    if (params.userId) urlParams.append('userId', params.userId);
    if (params.userId2) urlParams.append('userId', params.userId2);

    const url = `${urlBase}?${urlParams}`;

    const result = await fetchJson({ url, abort });
    return createApiObjectDataFromPlainObject(cache, makeApiObjectVariantKey(TEAM_SEARCH, params), {
      items: result
    }) as IApiObjectArray<ISmallTeamItem>;
  }
};
