// components for showing errors as a failure state

import { useEffect, useMemo, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { exists, t } from 'i18next';
import { Callout, DefaultButton, Icon, IconButton } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { ApiError, IProblemDetails } from '../services/api2';
import { ai } from '../features/ErrorHandling/components/AppInsights';

/** The area we found the error in. */
export enum ErrorDomain {
  Any = 'any',
  Task = 'task',
  Project = 'project'
}
/** The action we were taking during to the error. */
export enum ErrorAction {
  Any = 'any',
  Mutation = 'mutation'
}

/** Matches an error from the backend. Note that `{}` will be a wildcard (with lower matching priority). */
type ErrorMatch = {
  /** Matches an HTTP status. */
  status?: number;
  /** Matches a message exactly. */
  messageExact?: string;
};

/** Localization IDs for backend errors */
const ERROR_IDS: Record<ErrorDomain, Partial<Record<ErrorAction, Record<string, ErrorMatch[]>>>> = {
  any: {},
  task: {
    any: {
      'not-found': [{ status: 404, messageExact: 'task not found' }],
      'no-perms': [{ status: 403, messageExact: 'no permissions for this task' }]
    },
    mutation: {
      'reopen-with-completed-parent': [
        {
          status: 409,
          messageExact: 'Cannot re-open a completed task with a completed parent task.'
        }
      ],
      'change-dates-completed': [
        {
          status: 400,
          messageExact:
            'Task dates cannot be changed, because the task is completed or all children are completed.'
        }
      ]
    }
  },
  project: {
    any: {},
    mutation: {
      'circular-dependency': [{ status: 400, messageExact: 'Project has circular dependency.' }],
      'incomplete-subtasks': [
        { status: 409, messageExact: 'Task has subtasks that are not completed.' }
      ],
      'move-into-completed-parent': [
        { status: 400, messageExact: 'New parent task is already completed.' }
      ],
      'reorder-completed': [
        { status: 400, messageExact: 'Task is completed and cannot be moved.' }
      ],
      unknown: [{}]
    }
  }
};

function findErrorLocalization(
  domain: ErrorDomain,
  action: ErrorAction,
  error: IProblemDetails,
  useWildcards = true
) {
  const domainData = ERROR_IDS[domain];
  if (!domainData) return null;
  const actionData = domainData[action];
  if (!actionData) return null;

  const id = Object.entries(actionData).find(([, matches]) =>
    matches.some((match) => {
      const isWildcard = Object.keys(match).length === 0;
      if (isWildcard && !useWildcards) return false;

      const statusMatches = 'status' in match ? match.status === error.status : true;
      const messageMatches = 'messageExact' in match ? match.messageExact === error.title : true;
      return statusMatches && messageMatches;
    })
  )?.[0];

  return id ? { domain, action, id } : null;
}

function maybeLocalizeError(
  domains: ErrorDomain[],
  action: ErrorAction | undefined,
  error: IProblemDetails
): null | { title: string; detail?: string; hideTraceId: boolean } {
  let localization: { domain: ErrorDomain; action: ErrorAction; id: string } | null = null;

  const domainsList = domains.includes(ErrorDomain.Any) ? domains : [...domains, ErrorDomain.Any];

  for (const useWildcards of [false, true]) {
    for (const domain of domainsList) {
      if (action) {
        localization = findErrorLocalization(domain, action, error, useWildcards);
      }

      if (localization) break;

      if (action !== ErrorAction.Any) {
        localization = findErrorLocalization(domain, ErrorAction.Any, error, useWildcards);
      }

      if (localization) break;
    }

    if (localization) break;
  }

  if (!localization) return null;

  const localizedTitle = t(
    `error.domains.${localization.domain}.${localization.action}.${localization.id}.title`
  );
  const detailKey = `error.domains.${localization.domain}.${localization.action}.${localization.id}.detail`;
  const localizedDetail = exists(detailKey) ? t(detailKey) : null;
  const hideTraceId = exists(
    `error.domains.${localization.domain}.${localization.action}.${localization.id}.hideTraceId`
  );

  return { title: localizedTitle, detail: localizedDetail, hideTraceId };
}

export type ErrorLike =
  | IProblemDetails
  | { title: string; detail: string }
  | ApiError
  | { toString: () => string }
  | string;

export interface IShowErrorProps {
  /**
   * The error we want to show.
   *
   * This also supports Promises that resolve or reject to errors,
   * because this is something that happens in fetchRequest.
   * Please remove this in the future, because it's a bit nonsensical.
   */
  error: ErrorLike | Promise<ErrorLike>;

  /** If passed: shows a retry button for certain types of errors */
  onRetry?: () => void;

  /** Area where the error was encountered. Used for localization. */
  domain?: ErrorDomain;
  /**
   * Allows specifying multiple error domains. The first matching domain will be used.
   *
   * Mutually exclusive with `domain`.
   */
  domains?: ErrorDomain[];
  /** Action where the error was encountered. Used for localization. */
  action?: ErrorAction;
}

const BlockErrorStyled = styled.div`
  background: rgb(${(props) => props.theme.showError.blockBackground});
  color: rgb(${(props) => props.theme.showError.blockForeground});
  border-radius: ${(props) => props.theme.showError.cornerRadius};
  margin: 1em;
  padding: 0.5em;
  text-align: left;

  &.is-in-callout,
  &.is-embedded {
    margin: 0;
    border-radius: 0;
  }
  &.is-embedded {
    background: transparent;
    padding: 0;
    color: inherit;
  }

  .error-title {
    font-weight: bold;
    overflow-wrap: break-word;
  }

  .error-detail {
    overflow-wrap: break-word;
  }

  .error-trace-id {
    font-size: smaller;
    opacity: 0.7;
  }

  .retry-button-container {
    margin-top: 0.5em;
    display: flex;
    justify-content: end;
  }
`;

function useErrorAsProblemDetails(error: ErrorLike | Promise<ErrorLike>): IProblemDetails {
  const [resolvedError, setResolvedError] = useState<ErrorLike | null>(null);

  useEffect(() => {
    let isCurrent = true;
    if (error instanceof Promise) {
      error
        .then((result) => {
          if (isCurrent) setResolvedError(result);
        })
        .catch((error) => {
          if (isCurrent) setResolvedError(error);
          // rethrow. we are not handling, only inspecting
          throw error;
        });
    }
    return () => {
      isCurrent = false;
    };
  }, [error]);

  return useMemo(() => {
    if (error instanceof Promise) {
      return toProblemDetails(resolvedError) ?? { type: 'about:blank', title: '???', status: 0 };
    }

    return toProblemDetails(error);
  }, [error, resolvedError]);
}

function getErrorTitleAndDetail(details: IProblemDetails) {
  if (details.status === 403) {
    const title = t('error.403');
    const detail = details.title !== title ? details.title : null;
    return { title, detail };
  }
  if (details.status === 404) {
    const title = t('error.404');
    const detail = details.title !== title ? details.title : null;
    return { title, detail };
  }
  if (!details.title) {
    return { title: t('error.unknown'), detail: details.detail };
  }
  return details;
}

function useLocalizedError(
  details: IProblemDetails,
  {
    domain,
    domains,
    action
  }: { domain?: ErrorDomain; domains?: ErrorDomain[]; action?: ErrorAction }
) {
  return useMemo(() => {
    const { title, detail } = getErrorTitleAndDetail(details);
    const traceId = details.traceId ?? ai?.getTraceCtx()?.getTraceId();

    if (domain || domains) {
      const domainList = domains || [domain];
      const localized = maybeLocalizeError(domainList, action, details);
      if (localized) {
        return {
          title: localized.title,
          detail: localized.detail ?? detail ?? title, // fall back to existing error unless overridden
          traceId: localized.hideTraceId ? null : traceId
        };
      }
    }

    return { title, detail, traceId };
  }, [domain, domains, action, details]);
}

/** Shows an error as a block-level element */
export function ShowError({
  error,
  onRetry,
  domain,
  domains,
  action,
  isInCallout,
  isEmbedded,
  className
}: IShowErrorProps & { isInCallout?: boolean; isEmbedded?: boolean; className?: string }) {
  const details = useErrorAsProblemDetails(error);
  const theme = useTheme();

  const { title, detail, traceId } = useLocalizedError(details, { domain, domains, action });

  return (
    <BlockErrorStyled
      className={`${isInCallout ? 'is-in-callout' : ''} ${isEmbedded ? 'is-embedded' : ''} ${
        className ?? ''
      }`}
    >
      <div className="error-title">{title}</div>
      {detail ? <div className="error-detail">{detail}</div> : null}
      {!detail && details.errors ? (
        <div className="error-detail">
          <ErrorList errors={details.errors} />
        </div>
      ) : null}
      {traceId ? <div className="error-trace-id">Trace-ID: {traceId}</div> : null}

      {onRetry && canErrorBeRetried(details) ? (
        <div className="retry-button-container">
          <DefaultButton
            styles={{
              root: {
                background: `rgb(${theme.showError.blockForeground} / 0.05)`,
                borderColor: `rgb(${theme.showError.blockForeground} / 0.5)`,
                color: 'inherit'
              },
              rootHovered: {
                background: `rgb(${theme.showError.blockForeground} / 0.3)`,
                color: 'inherit'
              },
              rootPressed: {
                background: `rgb(${theme.showError.blockForeground} / 0.5)`,
                color: 'inherit'
              }
            }}
            onClick={onRetry}
          >
            {t('error.retryButtonLabel') as string}
          </DefaultButton>
        </div>
      ) : null}
    </BlockErrorStyled>
  );
}

const ErrorListStyled = styled.ul`
  position: relative;
  padding: 0.5em;
  margin: 0.2em 0;
  border: 1px solid rgb(${(props) => props.theme.showError.blockForeground} / 0.4);
  border-radius: 0.3em;
  list-style: none;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.1em 0.5em;
  font-size: smaller;

  > .c-error-item {
    display: contents;

    > .c-item-key {
      font-weight: 500;
    }
    > .c-item-values {
      white-space: pre-wrap;
      overflow-wrap: break-word;
    }
  }

  &:not(.is-open) {
    max-height: 3.3em;
    overflow: hidden;
  }
  > .c-collapse {
    position: absolute;
    border: none;
    font: inherit;
    color: inherit;
    display: flex;
    justify-content: end;
    align-items: end;
    left: 0;
    bottom: 0;
    right: 0;
    height: 1em;
    padding-right: 0.5em;
    padding-bottom: 0.1em;
    background: linear-gradient(
      to bottom,
      rgb(${(props) => props.theme.showError.blockBackground} / 0),
      rgb(${(props) => props.theme.showError.blockBackground})
    );
    cursor: default;

    * {
      opacity: 0.5;
    }
    &:hover * {
      opacity: 1;
    }
  }
  &.is-open > .c-collapse {
    background: none;
  }
`;

function ErrorList({ errors }: { errors: Record<string, string[]> }) {
  const [open, setOpen] = useState(false);

  return (
    <ErrorListStyled className={open ? 'is-open' : ''}>
      {Object.entries(errors).map(([key, values]) => (
        <li className="c-error-item" key={key}>
          <div className="c-item-key">{key}</div>
          <div className="c-item-values">{values.join('\n')}</div>
        </li>
      ))}

      <button
        className="c-collapse"
        type="button"
        onClick={() => setOpen((open) => !open)}
        aria-label="expand/collapse"
      >
        <Icon iconName={open ? 'ChevronUp' : 'ChevronDown'} />
      </button>
    </ErrorListStyled>
  );
}

const IconErrorStyled = styled.span`
  cursor: default;
  color: rgb(${(props) => props.theme.showError.iconColor});
`;

/** Shows an error as a small icon with a callout for details */
export function ShowErrorIcon({
  error,
  onRetry,
  domain,
  domains,
  action,
  className
}: IShowErrorProps & { className?: string }) {
  const [calloutOpen, setCalloutOpen] = useState(false);
  const id = useId('error-icon-');

  const closeCalloutTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  useEffect(() => {
    return () => clearTimeout(closeCalloutTimeout.current);
  }, []);

  return (
    <IconErrorStyled id={id} className={className}>
      <Icon
        iconName="Error"
        onPointerOver={() => {
          clearTimeout(closeCalloutTimeout.current);
          setCalloutOpen(true);
        }}
        onPointerOut={() => {
          closeCalloutTimeout.current = setTimeout(() => {
            setCalloutOpen(false);
          }, 400);
        }}
      />
      {calloutOpen ? (
        <Callout
          target={`#${id}`}
          beakWidth={0}
          onPointerOver={() => {
            clearTimeout(closeCalloutTimeout.current);
            setCalloutOpen(true);
          }}
          onPointerOut={() => {
            setCalloutOpen(false);
          }}
        >
          <ShowError
            error={error}
            onRetry={onRetry}
            domain={domain}
            domains={domains}
            action={action}
            isInCallout
          />
        </Callout>
      ) : null}
    </IconErrorStyled>
  );
}

const InlineErrorStyled = styled.div`
  background: rgb(${(props) => props.theme.showError.inlineBackground});
  color: rgb(${(props) => props.theme.showError.inlineForeground});
  border-radius: 100px;
  display: inline-block;
  padding-left: 0.3em;
  line-height: 1.5em;

  .retry-button {
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin-left: 0.2em;
    vertical-align: -0.1em;

    .retry-icon {
      font-size: 0.9em;
    }
  }
`;

/** Shows an error as an inline block */
export function ShowErrorInline({ error, onRetry, domain, domains, action }: IShowErrorProps) {
  const details = useErrorAsProblemDetails(error);
  const { title } = useLocalizedError(details, { domain, domains, action });
  const theme = useTheme();

  return (
    <InlineErrorStyled>
      {title}
      {onRetry && canErrorBeRetried(details) ? (
        <IconButton
          className="retry-button"
          onClick={onRetry}
          iconProps={{ className: 'retry-icon', iconName: 'Refresh' }}
          styles={{
            root: {
              color: 'inherit'
            },
            rootHovered: {
              background: `rgb(${theme.showError.inlineForeground} / 0.3)`,
              color: 'inherit'
            },
            rootPressed: {
              background: `rgb(${theme.showError.inlineForeground} / 0.5)`,
              color: 'inherit'
            }
          }}
        />
      ) : null}
    </InlineErrorStyled>
  );
}

function toProblemDetails(error: ErrorLike) {
  if (error instanceof ApiError) {
    return error.details;
  }

  if (
    error &&
    typeof error === 'object' &&
    'type' in error &&
    'title' in error &&
    'status' in error
  ) {
    return error as IProblemDetails;
  }

  if (error && typeof error === 'object' && 'title' in error && 'detail' in error) {
    const error2 = error as { title: string; detail: string };
    return {
      type: 'about:blank',
      status: 0,
      title: error2.title,
      detail: error2.detail
    };
  }

  if (
    error instanceof TypeError &&
    (error.message === 'Failed to fetch' || // Chromium
      error.message === 'Load failed' || // Safari
      error.message.includes('NetworkError')) // Firefox
  ) {
    return {
      type: 'about:blank',
      status: 499,
      title: t('error.fetchFailed')
    };
  }

  return {
    type: 'about:blank',
    status: 0,
    title: error?.toString() ?? '?'
  };
}

function canErrorBeRetried(error: IProblemDetails) {
  const NON_RETRYABLE_STATUSES = [400, 401, 403, 404];
  return !NON_RETRYABLE_STATUSES.includes(error.status);
}
