import {
  ApiObjectVariant,
  CACHE,
  createApiObjectDataFromPlainObject,
  createApiObjectField,
  IApiObjectCache,
  IApiObjectData,
  IApiObjectDataSmall,
  IApiObjectType,
  makeApiObjectVariantKey,
  REFS
} from '../object';
import {
  DateTime,
  IApiObjectArray,
  IIdStringPair,
  ITextItem,
  Ref,
  Uuid,
  VoidParams
} from './base-types';
import { ITeam, IUser, TEAM, USER } from './users';
import { createFieldRefs, FieldRefSpec, simpleIdObject } from './utils';
import {
  IIdentifiableObject,
  ProcessOutcome,
  QueryFieldUsage,
  RouteDefinitionStatus,
  RouteFieldType,
  RouteFieldVisibility,
  RouteInstanceStatus,
  RouteStepType
} from '../../../types';
import { IApiListFetchResult, IApiListPage, IApiListType } from '../list';
import { fetchJson } from '../../../services/api2';
import { ITagItem, TAG } from './tags';
import { ILocationItem, LOCATION } from './locations';
import { IProcessTemplateStep, PROCESS_TEMPLATE_STEP } from './process-template-steps';
import {
  IProcessField,
  IProcessFieldGroup,
  IProcessFieldRef,
  PROCESS_FIELD,
  PROCESS_FIELD_GROUP,
  SPARSE_PROCESS_FIELD_REF
} from './process-fields';
import { IDashboardNumberItem } from './tasks';
import { UUID_NULL } from '../../../utils/helpers';
import { IPictureItem } from './files';
import {
  getProcessInstanceStepFieldLinkFieldRefs,
  IProcessInstanceStep,
  IProcessInstanceStepFieldLink
} from './process-instance-steps';

export interface IEndToEndType extends IApiObjectData {
  id: Uuid;
  name: string | null;
  allNames: ITextItem[] | null;
  description: string | null;
  allDescriptions: ITextItem[] | null;
  sortOrder: number;
}

export const END_TO_END_TYPE: IApiObjectType<Uuid, IEndToEndType> = simpleIdObject({
  id: 'EndToEndType',
  url: (id) => `EndToEndType/${id}`
});

export interface IProcessType extends IApiObjectData {
  id: Uuid;
  name: string | null;
  allNames: ITextItem[] | null;
  description: string | null;
  allDescriptions: ITextItem[] | null;
  sortOrder: number;
  processGrouping: string | null;
  category: Ref<IProcessTypeCategory> | null;
}

export interface IProcessTypeCategory extends IApiObjectData {
  id: Uuid;
  name: string | null;
  allNames: ITextItem[] | null;
  description: string | null;
  allDescriptions: ITextItem[] | null;
  sortOrder: number;
}

export const PROCESS_TYPE_CATEGORY: IApiObjectType<Uuid, IProcessTypeCategory> = simpleIdObject({
  id: 'ProcessType/Category',
  url: (id) => `ProcessType/Category/${id}`
});

export const PROCESS_TYPE: IApiObjectType<Uuid, IProcessType> = simpleIdObject({
  id: 'ProcessType',
  url: (id) => `ProcessType/${id}`,
  fields: [
    {
      path: 'category',
      type: PROCESS_TYPE_CATEGORY,
      params: (data) => data.category?.id
    }
  ]
});

export const END_TO_END_TYPES: IApiObjectType<VoidParams, IApiObjectArray<IEndToEndType>> = {
  id: 'EndToEndTypes',
  createRefs(cache: IApiObjectCache, data: IApiObjectArray<IEndToEndType>) {
    return createFieldRefs(
      cache,
      data,
      data.items.map((item, index) => ({
        path: `items/${index}`,
        type: END_TO_END_TYPE,
        params: () => item.id
      }))
    );
  },
  async load(
    cache: IApiObjectCache,
    params: VoidParams,
    abort: AbortSignal
  ): Promise<IApiObjectArray<IEndToEndType>> {
    const result = await fetchJson({ url: 'EndToEndTypes', abort });

    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(END_TO_END_TYPES, params),
      { items: result }
    ) as IApiObjectArray<IEndToEndType>;
  }
};

export const PROCESS_TYPES: IApiObjectType<VoidParams, IApiObjectArray<IProcessType>> = {
  id: 'ProcessTypes',
  createRefs(cache: IApiObjectCache, data: IApiObjectArray<IProcessType>) {
    return createFieldRefs(
      cache,
      data,
      data.items.map((item, index) => ({
        path: `items/${index}`,
        type: PROCESS_TYPE,
        params: () => item.id
      }))
    );
  },
  async load(
    cache: IApiObjectCache,
    params: VoidParams,
    abort: AbortSignal
  ): Promise<IApiObjectArray<IProcessType>> {
    const result = await fetchJson({ url: 'ProcessTypes', abort });

    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(PROCESS_TYPES, params),
      { items: result }
    ) as IApiObjectArray<IProcessType>;
  }
};

export const PROCESS_TYPE_CATEGORIES: IApiObjectType<
  VoidParams,
  IApiObjectArray<IProcessTypeCategory>
> = {
  id: 'ProcessType/Categories',
  createRefs(cache: IApiObjectCache, data: IApiObjectArray<IProcessTypeCategory>) {
    return createFieldRefs(
      cache,
      data,
      data.items.map((item, index) => ({
        path: `items/${index}`,
        type: PROCESS_TYPE_CATEGORY,
        params: () => item.id
      }))
    );
  },
  async load(
    cache: IApiObjectCache,
    params: VoidParams,
    abort: AbortSignal
  ): Promise<IApiObjectArray<IProcessTypeCategory>> {
    const result = await fetchJson({ url: 'ProcessType/Categories', abort });

    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(PROCESS_TYPE_CATEGORIES, params),
      { items: result }
    ) as IApiObjectArray<IProcessTypeCategory>;
  }
};

// TODO: needs explicit small variant
export interface IProcessTemplate extends IApiObjectData {
  id: Uuid | null;
  name: string | null;
  allNames: ITextItem[] | null;
  description: string | null;
  allDescriptions: ITextItem[] | null;

  steps: Ref<IProcessTemplateStep>[] | null;

  version: string | null;
  duration: number;

  creationDate: DateTime | null;
  creator: Ref<IUser>;
  editDate: DateTime;
  editor: Ref<IUser>;
  team: Ref<ITeam>;

  endToEndType: Ref<IEndToEndType> | null;
  processType: Ref<IProcessType> | null;
  location: Ref<ILocationItem>;
  pic: IPictureItem | null;
  tags: Ref<ITagItem>[];

  /** @see ProcessTemplateUsage */
  usage: number;
  visibility: ProcessVisibility;
  startAsync: boolean;
  status: RouteDefinitionStatus;
  dataFields: IProcessTemplateDataField[] | null;
  serviceData: IExternalServiceData | null;
  linkData: IExternalLinkData | null;
  permissions: IProcessTemplatePermissions;
  instancePermissions: IProcessTemplateInstancePermissions;
  fieldChangesByCreator: IProcessInstanceFieldChangePermission;
  fieldChangesByInvolved: IProcessInstanceFieldChangePermission;

  isFavorite: boolean;
  isFollowing: boolean | null;
  startUrl: string | null;
  instancesUrl: string | null;

  fileIds: Uuid[] | null;

  assignedTeams: Ref<ITeam>[];
  assignedUsers: Ref<IUser>[];
  followingTeams: Ref<ITeam>[];
  fullReadTeams: Ref<ITeam>[];

  includedPages: Uuid[];
}

export interface IProcessTemplateDataField {
  field: IProcessFieldRef | null;
  showInList: boolean | null;
}

export interface IProcessInstanceFieldChangePermission {
  allow: boolean;
  upToStepId: Uuid | null;
}

/** has refs */
export interface IExternalServiceData {
  url: string | null;
  async: boolean;
  method: string | null;
  connection: unknown; // TODO
  listConnection: unknown; // TODO
  inputFields: IExternalServiceInputField[] | null;
  returnedFields: IExternalServiceReturnField[] | null;
}

function getExternalServiceDataFieldRefsSparse(
  basePath: string,
  obj: IExternalServiceData
): FieldRefSpec<unknown>[] {
  return [
    ...(obj.inputFields?.flatMap((field, index) =>
      getExternalServiceInputFieldFieldRefsSparse(`${basePath}inputFields/${index}/`, field)
    ) ?? []),
    ...(obj.returnedFields?.flatMap((field, index) =>
      getExternalServiceReturnFieldFieldRefsSparse(`${basePath}returnedFields/${index}/`, field)
    ) ?? [])
  ];
}

/** has refs */
export interface IExternalServiceInputField {
  internalName: string | null;
  formatString: string | null;
  name: string | null;
  description: string | null;
  isRequired: boolean;
  defaultValue: string | null;
  usage: QueryFieldUsage;
  mappedField: IProcessFieldRef;
}

function getExternalServiceInputFieldFieldRefsSparse(
  basePath: string,
  obj: IExternalServiceInputField
): FieldRefSpec<unknown>[] {
  return [
    {
      path: `${basePath}mappedField`,
      type: PROCESS_FIELD,
      sparseRef: SPARSE_PROCESS_FIELD_REF,
      params: () => obj.mappedField?.id
    }
  ];
}

/** has refs */
export interface IExternalServiceReturnField {
  internalName: string | null;
  name: string | null;
  description: string | null;
  fieldType: RouteFieldType;
  mappedField: IProcessFieldRef;
}

function getExternalServiceReturnFieldFieldRefsSparse(
  basePath: string,
  obj: IExternalServiceReturnField
): FieldRefSpec<unknown>[] {
  return [
    {
      path: `${basePath}mappedField`,
      type: PROCESS_FIELD,
      sparseRef: SPARSE_PROCESS_FIELD_REF,
      params: () => obj.mappedField?.id
    }
  ];
}

export interface IExternalLinkData {
  url: string | null;
  displayText: string | null;
  allDisplayTexts: ITextItem[] | null;
}

export interface IProcessTemplatePermissions {
  start: boolean;
  edit: boolean;
  delete: boolean;
}

export interface IProcessTemplateInstancePermissions {
  creatorCanAbort: boolean;
  creatorCanDelete: boolean;
}

export const ProcessTemplateUsage = {
  /** A normal Evocom process template */
  AsEpProcess: 1,
  /** Can be used in integration steps */
  InIntegration: 1 << 1,
  /** Can be used in lookup or external data fields */
  InFields: 1 << 2,
  /** May be started by external users */
  ExternalStart: 1 << 3,
  /** May be started from a task's panel (when defined in the task's process template) */
  StartFromTask: 1 << 4,
  /** May be started from the new menu in task management */
  StartFromTasks: 1 << 5,
  /** May be started from a project or project task */
  StartFromProject: 1 << 6
};

export enum ProcessTemplateStatus {
  Draft = 0,
  Published = 1,
  Deleted = 2
}

/**
 * A small process template is identical in *schema* to a process template,
 * but fields such as `steps` will simply be empty.
 */
export type ISmallProcessTemplate = Omit<IProcessTemplate, keyof IApiObjectData> &
  IApiObjectDataSmall;

export interface IProcessTemplateParams {
  id: string;
  status?: ProcessTemplateStatus;
  version?: string;
}
export const PROCESS_TEMPLATE: IApiObjectType<
  IProcessTemplateParams,
  IProcessTemplate,
  ISmallProcessTemplate
> = {
  id: 'Route/Definition',
  createRefs(cache: IApiObjectCache, data: IProcessTemplate) {
    createFieldRefs(cache, data, [
      { path: 'creator', type: USER, params: () => data.creator?.userId },
      { path: 'editor', type: USER, params: () => data.editor?.userId },
      { path: 'team', type: TEAM, variant: ApiObjectVariant.Small, params: () => data.team?.id },
      { path: 'location', type: LOCATION, params: () => data.location?.id },

      ...(data.steps?.map((step, index) => ({
        path: `steps/${index}`,
        type: PROCESS_TEMPLATE_STEP,
        params: () => ({ id: step.id, version: data.version })
      })) ?? []),

      ...(data.dataFields?.map(
        (field, index) =>
          ({
            path: `dataFields/${index}/field`,
            type: PROCESS_FIELD,
            variant: ApiObjectVariant.Small,
            sparseRef: SPARSE_PROCESS_FIELD_REF,
            params: () =>
              field.field?.id === UUID_NULL
                ? `data:${(field.field as unknown as { name: string }).name}`
                : field.field?.id
          } as FieldRefSpec<unknown>)
      ) ?? []),

      ...(data.endToEndType
        ? [{ path: `endToEndType`, type: END_TO_END_TYPE, params: () => data.endToEndType.id }]
        : []),
      ...(data.processType
        ? [{ path: `processType`, type: PROCESS_TYPE, params: () => data.processType.id }]
        : []),

      ...(data.tags?.map((tag, index) => ({
        path: `tags/${index}`,
        type: TAG,
        params: () => tag.id
      })) ?? []),

      ...(data.assignedTeams?.map((team, index) => ({
        path: `assignedTeams/${index}`,
        type: TEAM,
        params: () => team.id
      })) ?? []),

      ...(data.assignedUsers?.map((user, index) => ({
        path: `assignedUsers/${index}`,
        type: USER,
        params: () => user.userId
      })) ?? []),

      ...(data.followingTeams?.map((team, index) => ({
        path: `followingTeams/${index}`,
        type: TEAM,
        variant: ApiObjectVariant.Small,
        params: () => team.id
      })) ?? []),

      ...(data.fullReadTeams?.map((team, index) => ({
        path: `fullReadTeams/${index}`,
        type: TEAM,
        variant: ApiObjectVariant.Small,
        params: () => team.id
      })) ?? []),

      ...(data.serviceData
        ? getExternalServiceDataFieldRefsSparse(`serviceData/`, data.serviceData)
        : [])
    ]);
  },
  async load(
    cache: IApiObjectCache,
    params: IProcessTemplateParams,
    abort: AbortSignal
  ): Promise<IProcessTemplate> {
    const { id, status, version } = params;

    const searchParams = new URLSearchParams();
    if (typeof status === 'number') searchParams.set('status', status.toString());
    if (version) searchParams.set('version', version);

    const result = await fetchJson({
      url: `Route/Definition/${id}${searchParams.size ? `?${searchParams}` : ''}`,
      abort
    });

    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(PROCESS_TEMPLATE, params),
      result
    ) as IProcessTemplate;
  }
};

export enum ProcessTemplateType {
  All = 0,
  Internal = 1,
  External = 2,
  ToStart = 3,
  ForIntegration = 4,
  InExternalFields = 5,
  InLookupFields = 6,
  ForExternalLinks = 7,
  StartFromTaskPanel = 8,
  StartFromTaskManagement = 9,
  StartFromProject = 10
}

export interface ISearchProcessTemplatesApiRequest {
  status?: RouteDefinitionStatus;
  teamId?: Uuid;
  endToEndTypeId?: Uuid;
  processTypeId?: Uuid;
  locationId?: Uuid;
  searchTerms?: string;
  type?: ProcessTemplateType;
  /** If true, only definitions the current user can edit will be returned. Default is false. */
  onlyEditable?: boolean;
  onlyFavorites?: boolean;
  tagId?: Uuid[];
  itemsPerPage?: number;
}

export const PROCESS_TEMPLATES: IApiListType<
  ISearchProcessTemplatesApiRequest,
  typeof PROCESS_TEMPLATE,
  void,
  ApiObjectVariant.Small
> = {
  id: 'Route/Definitions',
  async fetchPage(
    cache: IApiObjectCache,
    params: ISearchProcessTemplatesApiRequest,
    pageIndex: number,
    _?: IApiListPage<typeof PROCESS_TEMPLATE>,
    abort?: AbortSignal
  ): Promise<IApiListFetchResult<typeof PROCESS_TEMPLATE>> {
    const urlBase = 'Route/Definitions';
    const urlParams = new URLSearchParams();

    urlParams.append('pageIndex', pageIndex.toString());

    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        for (const item of value) {
          urlParams.append(key, item);
        }
      } else if (value) {
        urlParams.append(key, value);
      }
    });

    const url = `${urlBase}?${urlParams}`;

    const { items, searchProps } = await fetchJson({ url, abort });
    return {
      items: items.map((item: object) => {
        const id = makeApiObjectVariantKey(
          PROCESS_TEMPLATE,
          {
            id: (item as IProcessTemplate).id,
            version: (item as IProcessTemplate).version
          },
          ApiObjectVariant.Small
        );
        const idWithoutVersion = makeApiObjectVariantKey(
          PROCESS_TEMPLATE,
          { id: (item as IProcessTemplate).id },
          ApiObjectVariant.Small
        );
        cache.insertObjectData(createApiObjectDataFromPlainObject(cache, id, item));
        cache.insertObjectData(createApiObjectDataFromPlainObject(cache, idWithoutVersion, item));
        return id;
      }),
      searchProps
    };
  }
};

export interface IProcessTemplateFilterPropsSearchApiRequest {
  searchTerm?: string;
  tenantId: string;
}

export const TENANT_PROCESS_TEMPLATES: IApiObjectType<
  IProcessTemplateFilterPropsSearchApiRequest,
  IApiObjectArray<IIdentifiableObject>
> = {
  id: 'tenants/{tenantId}/TenantProcessTemplates',
  createRefs() {
    // nothing to do
  },
  async load(
    cache: IApiObjectCache,
    params: IProcessTemplateFilterPropsSearchApiRequest,
    abort: AbortSignal
  ): Promise<IApiObjectArray<IIdentifiableObject>> {
    const urlBase = `tenants/${params.tenantId}/TenantProcessTemplates`;
    const urlParams = new URLSearchParams();

    urlParams.append('searchTerm', params.searchTerm);

    const url = `${urlBase}?${urlParams}`;

    const result = await fetchJson({ url, abort });
    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(TENANT_PROCESS_TEMPLATES, params),
      { items: result }
    ) as IApiObjectArray<IIdentifiableObject>;
  }
};

export interface ISmallProcessTemplateForUser {
  id: Uuid;
  name: string | null;
  version: string | null;
  team: Ref<ITeam>;
  userCanEdit: boolean;
}

export interface IProcessFieldEditValidation extends IApiObjectData {
  owner: Ref<IUser> | null;
  involvedDefinitions: ISmallProcessTemplateForUser[];
  involvedGroups: Ref<IProcessFieldGroup>[] | null;
}

export const PROCESS_FIELD_VALIDATE_EDIT: IApiObjectType<Uuid, IProcessFieldEditValidation> = {
  id: 'Route/Field/ValidateEdit',
  createRefs(cache: IApiObjectCache, data: IProcessFieldEditValidation) {
    createFieldRefs(cache, data, [{ path: 'owner', type: USER, params: () => data.owner?.userId }]);
    createFieldRefs(
      cache,
      data,
      data.involvedDefinitions?.map((def, index) => ({
        path: `involvedDefinitions/${index}/team`,
        type: TEAM,
        variant: ApiObjectVariant.Small,
        params: () => def.team?.id
      })) ?? []
    );
    createFieldRefs(
      cache,
      data,
      data.involvedGroups?.map((group, index) => ({
        path: `involvedGroups/${index}`,
        type: PROCESS_FIELD_GROUP,
        params: () => group.id
      })) ?? []
    );
  },
  async load(
    cache: IApiObjectCache,
    id: Uuid,
    abort: AbortSignal
  ): Promise<IProcessFieldEditValidation> {
    const result = await fetchJson({
      method: 'PUT',
      url: `Route/Field/ValidateEdit/${id}`,
      abort
    });
    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(PROCESS_FIELD_VALIDATE_EDIT, id),
      result
    ) as IProcessFieldEditValidation;
  }
};

export interface IRouteFieldGroupEditValidation extends IApiObjectData {
  involvedDefinitions: ISmallProcessTemplateForUser[] | null;
  involvedInstances: Ref<IProcessInstance>[] | null;
}

export const PROCESS_FIELD_GROUP_VALIDATE_EDIT: IApiObjectType<
  Uuid,
  IRouteFieldGroupEditValidation
> = {
  id: 'Route/FieldGroup/ValidateEdit',
  createRefs(cache: IApiObjectCache, data: IRouteFieldGroupEditValidation) {
    createFieldRefs(
      cache,
      data,
      data.involvedDefinitions.map((def, index) => ({
        path: `involvedDefinitions/${index}/team`,
        type: TEAM,
        variant: ApiObjectVariant.Small,
        params: () => def.team?.id
      }))
    );
    createFieldRefs(
      cache,
      data,
      data.involvedInstances?.map((inst, index) => ({
        path: `involvedInstances/${index}`,
        type: PROCESS_INSTANCE,
        params: () => inst.id
      }))
    );
  },
  async load(
    cache: IApiObjectCache,
    id: Uuid,
    abort: AbortSignal
  ): Promise<IRouteFieldGroupEditValidation> {
    const result = await fetchJson({
      method: 'PUT',
      url: `Route/FieldGroup/ValidateEdit/${id}`,
      abort
    });
    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(PROCESS_FIELD_GROUP_VALIDATE_EDIT, id),
      result
    ) as IRouteFieldGroupEditValidation;
  }
};

export interface IIntegratedServiceTestModel extends IApiObjectData {
  serviceId: Uuid;
  skip: boolean;
  allowInvoke: boolean;
  /** has refs */
  outboundFields: IIntegratedServiceTestModelField[];
  /** has refs */
  inboundFields: IIntegratedServiceTestModelField[];
}

/** has refs */
export interface IIntegratedServiceTestModelField {
  id: Uuid;
  field: Ref<IProcessField>;
  externalFieldName: string | null;
  visibility: RouteFieldVisibility;
  value: unknown;
}

export const PROCESS_SERVICE_TEST_MODEL: IApiObjectType<Uuid, IIntegratedServiceTestModel> =
  simpleIdObject({
    id: `Route/Instance/ServiceTestModel`,
    url: (id) => `Route/Instance/ServiceTestModel/${id}`,
    dynFields: (data) => [
      ...(data.outboundFields?.map((field, index) => ({
        path: `outboundFields/${index}/field`,
        type: PROCESS_FIELD,
        params: () => field.field?.id
      })) ?? []),
      ...(data.inboundFields?.map((field, index) => ({
        path: `inboundFields/${index}/field`,
        type: PROCESS_FIELD,
        params: () => field.field?.id
      })) ?? [])
    ]
  });

export enum ProcessInstancesType {
  All = 0,
  Mine = 1,
  Mentioned = 2,
  Completed = 3
}

export enum ProcessInstancesViewType {
  ProdOnly = 0,
  All = 1,
  TestsOnly = 2
}

export interface IProcessInstancesGroupedParams {
  type?: ProcessInstancesType;
  statuses?: number[];
  creatorIds?: Uuid[];
  searchTerms?: string;
  parentInstanceId?: Uuid;
  parentProjectId?: Uuid;
  parentTaskId?: Uuid;
  viewType?: ProcessInstancesViewType;
  tagId?: Uuid;
}

export interface IProcessInstanceGroup {
  id: Uuid;
  name: string | null;
  description: string | null;
  creationDate: DateTime | null;
  creator: Ref<IUser>;
  team: Ref<ITeam>;
  numberOfInstances: number;
  colorIndex: number;
}

export const PROCESS_INSTANCES_GROUPED: IApiObjectType<
  IProcessInstancesGroupedParams,
  IApiObjectArray<IProcessInstanceGroup>
> = {
  id: 'Route/Instances/Grouped',
  createRefs(cache: IApiObjectCache, data: IApiObjectArray<IProcessInstanceGroup>) {
    createFieldRefs(
      cache,
      data,
      data.items.flatMap((group, groupIndex) => [
        { path: `items/${groupIndex}/creator`, type: USER, params: () => group.creator?.userId },
        {
          path: `items/${groupIndex}/team`,
          type: TEAM,
          variant: ApiObjectVariant.Small,
          params: () => group.team?.id
        }
      ])
    );
  },
  async load(
    cache: IApiObjectCache,
    params: IProcessInstancesGroupedParams,
    abort: AbortSignal
  ): Promise<IApiObjectArray<IProcessInstanceGroup>> {
    const search = new URLSearchParams();
    for (const [k, v] of Object.entries(params)) {
      if (Array.isArray(v)) {
        for (const item of v) {
          search.append(k, item);
        }
      } else if (v !== null && v !== undefined) {
        search.append(k, v);
      }
    }

    const result = await fetchJson({ url: `Route/Instances/Grouped?${search}`, abort });

    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(PROCESS_INSTANCES_GROUPED, params),
      { items: result }
    ) as IApiObjectArray<IProcessInstanceGroup>;
  }
};

export enum ProcessInstancesSortType {
  CreationDate = 1,
  CreatedBy = 2,
  Name = 3,
  CurrentStep = 4,
  Progress = 5,
  PlannedEndDate = 6,
  CurrentEndDate = 7,
  DataField = 100
}

export enum ProcessInstancesFilterType {
  None = 0,
  CreatedBy = 2,
  CurrentStep = 4,
  Progress = 5,
  DataField = 100
}

export interface IProcessInstancesParams {
  type?: ProcessInstancesType;
  statuses?: RouteInstanceStatus[];
  creatorIds?: Uuid[];
  definitionId?: Uuid;
  searchTerms?: string;
  tagId?: Uuid;
  parentInstanceId?: Uuid;
  parentProjectId?: Uuid;
  parentTaskId?: Uuid;
  viewType?: ProcessInstancesViewType;
  durationGroup?: number;
  sortType?: ProcessInstancesSortType;
  sortAscending?: boolean;
  /** The ID of a field to sort by, for the DataField sorting type */
  sortField?: Uuid;

  filterValues?: IFieldFilterValue[];

  itemsPerPage?: number;
}

export interface IProcessInstanceFilterPropsParams extends IProcessInstancesParams {
  filterType: ProcessInstancesFilterType;
  /** The ID of a field to get filter props for, for the DataField filter type */
  filterFieldId: Uuid;
}

export interface IFieldFilterValue {
  filterType: ProcessInstancesFilterType;
  fieldId: Uuid;
  value: string | null;
}

/** @see IProcessInstancesParams */
export interface IProcessInstancesNumbersParams {
  type?: ProcessInstancesType;
  statuses?: number[];
  creatorIds?: Uuid[];
  definitionIds?: Uuid[];
  searchTerms?: string;
  filterType?: ProcessInstancesFilterType;
  filterField?: Uuid;
  filterValue?: string;
}

/** @see IProcessInstancesParams */
export interface IProcessInstancesNumbersByStepsParams {
  type?: ProcessInstancesType;
  statuses?: number[];
  creatorIds?: Uuid[];
  definitionId: Uuid;
  searchTerms?: string;
}

export interface IProcessInstanceForList extends IApiObjectDataSmall {
  id: Uuid;
  intId: number;
  routeDefinitionId: Uuid;
  routeDefinitionName: string | null;
  name: string | null;
  status: RouteInstanceStatus;
  outcome: ProcessOutcome;
  percentComplete: number;
  readonly version: string | null;
  imageId: Uuid;
  tags: Ref<ITagItem>[];
  creationDate: DateTime;
  creator: Ref<IUser>;
  team: Ref<ITeam>;
  currentStepName: string | null;
  currentEndDate: DateTime | null;
  plannedEndDate: DateTime | null;
  /** has refs */
  columns: IProcessInstanceColumnValue[] | null;
  permissions: IProcessInstancePermissions;
}

export interface IProcessInstance extends IApiObjectData {
  id: Uuid;
  intId: number;
  routeDefinitionId: Uuid;
  routeDefinitionName: string | null;
  allRouteDefinitionNames: ITextItem[] | null;

  parentInstanceId: Uuid | null;
  parentInstanceName: string | null;
  parentStepId: Uuid | null;
  parentTaskId: Uuid | null;
  parentTaskName: string | null;
  parentProjectId: Uuid | null;
  prevSiblingId: Uuid | null;
  nextSiblingId: Uuid | null;

  name: string | null;
  description: string | null;
  allDescriptions: ITextItem[] | null;

  /** These are not refs because they're not true steps; they have missing data. */
  steps: IProcessInstanceStep[];

  status: RouteInstanceStatus;
  outcome: ProcessOutcome;
  visibility: ProcessVisibility;
  percentComplete: number;

  version: string | null;
  imageId: Uuid;

  tags: Ref<ITagItem>[] | null;

  creationDate: DateTime;
  creator: Ref<IUser>;
  editDate: DateTime;
  editor: Ref<IUser>;
  team: Ref<ITeam>;

  currentStepName: string | null;
  currentEndDate: DateTime;
  plannedEndDate: DateTime;

  /** has refs */
  columns: IProcessInstanceColumnValue[] | null;
  permissions: IProcessInstancePermissions;

  includedPages: Uuid[] | null;
  followerIds: Uuid[] | null;
  location: Ref<ILocationItem>;
  firstTaskId: Uuid | null;
  url: string | null;
}

export interface IFullProcessInstanceWithSteps extends Omit<IProcessInstance, 'steps'> {
  steps: IProcessInstanceStep[];
}

export enum ProcessVisibility {
  All = 0,
  Confidential = 1,
  Testing = 4
}

/** has refs */
export interface IProcessInstanceColumnValue {
  field: Ref<IProcessField>;
  id: Uuid;
  name: string | null;
  fieldType: RouteFieldType;
  value: unknown;
}

export interface IProcessInstancePermissions {
  readExcel: boolean;
  readSummary: boolean;
  abort: boolean;
  delete: boolean;
}

export const PROCESS_INSTANCE: IApiObjectType<Uuid, IProcessInstance, IProcessInstanceForList> =
  simpleIdObject({
    id: `Route/Instance`,
    url: (id) => `Route/Instance/${id}`,
    fields: [
      { path: 'creator', type: USER, params: (data) => data.creator?.userId },
      { path: 'editor', type: USER, params: (data) => data.editor?.userId },
      {
        path: 'team',
        type: TEAM,
        variant: ApiObjectVariant.Small,
        params: (data) => data.team?.id
      },
      {
        path: 'location',
        type: LOCATION,
        params: (data) => data.location?.id
      }
    ],
    dynFields: (data) => [
      ...(data.tags?.map((tag, index) => ({
        path: `tags/${index}`,
        type: TAG,
        params: () => tag.id
      })) ?? []),

      ...(data.columns?.map((col, index) => ({
        path: `columns/${index}/field`,
        type: PROCESS_FIELD,
        params: () => (col.field?.id === UUID_NULL ? `data:${col.field.name}` : col.field?.id)
      })) ?? [])
    ]
  });

export interface IProcessInstancesData {
  columns: IProcessInstanceColumnHeader[];
  definition: IProcessTemplate;
}

export interface IProcessInstanceColumnHeader {
  id: Uuid;
  name: string | null;
  fieldType: RouteFieldType;
  sortType: number;
  filterType: number;
}

export const PROCESS_INSTANCES: IApiListType<
  IProcessInstancesParams,
  typeof PROCESS_INSTANCE,
  IProcessInstancesData,
  ApiObjectVariant.Small
> = {
  id: `Route/Instances`,
  async fetchPage(
    cache: IApiObjectCache,
    params: IProcessInstancesParams,
    pageIndex: number,
    _?: IApiListPage<typeof PROCESS_INSTANCE, IProcessInstancesData, ApiObjectVariant.Small>,
    abort?: AbortSignal
  ): Promise<IApiListFetchResult<typeof PROCESS_INSTANCE, IProcessInstancesData>> {
    let result: ReturnType<typeof fetchJson>;
    if (params.filterValues) {
      result = fetchJson({
        url: `Route/Instances`,
        method: 'POST',
        body: {
          ...params,
          pageIndex
        },
        abort
      });
    } else {
      const search = new URLSearchParams();
      for (const [k, v] of Object.entries(params)) {
        if (Array.isArray(v)) {
          for (const item of v) {
            search.append(k, item);
          }
        } else if (v !== null && v !== undefined) {
          search.append(k, v);
        }
      }

      search.set('pageIndex', pageIndex.toString());

      result = fetchJson({
        url: `Route/Instances?${search}`,
        abort
      });
    }

    const { items, searchProps, columns, definition } = await result;

    const defId = makeApiObjectVariantKey(
      PROCESS_TEMPLATE,
      { id: (definition as IProcessTemplate).id },
      ApiObjectVariant.Small
    );
    cache.insertObjectData(createApiObjectDataFromPlainObject(cache, defId, definition));

    const data = { [CACHE]: cache, [REFS]: new Map(), columns, definition };
    createApiObjectField(data, 'definition', defId);

    return {
      items: items.map((item: object) => {
        const id = makeApiObjectVariantKey(
          PROCESS_INSTANCE,
          (item as IProcessInstanceForList).id,
          ApiObjectVariant.Small
        );
        cache.insertObjectData(createApiObjectDataFromPlainObject(cache, id, item));
        return id;
      }),
      searchProps,
      data
    };
  }
};

export interface IDashboardNumberItemGroup {
  groupId: number;
  numbers: IDashboardNumberItem[] | null;
}

export const PROCESS_INSTANCES_NUMBERS: IApiObjectType<
  IProcessInstancesNumbersParams,
  IApiObjectArray<IDashboardNumberItemGroup>
> = {
  id: 'Route/Instances/Numbers',
  createRefs() {
    // nothing to do
  },
  async load(
    cache: IApiObjectCache,
    params: IProcessInstancesNumbersParams,
    abort: AbortSignal
  ): Promise<IApiObjectArray<IDashboardNumberItemGroup>> {
    const search = new URLSearchParams();
    for (const [k, v] of Object.entries(params)) {
      if (Array.isArray(v)) {
        for (const item of v) {
          search.append(k, item);
        }
      } else if (v !== null && v !== undefined) {
        search.append(k, v);
      }
    }

    const result = await fetchJson({ url: `Route/Instances/Numbers?${search}`, abort });
    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(PROCESS_INSTANCES_NUMBERS, params),
      { items: result }
    ) as IApiObjectArray<IDashboardNumberItemGroup>;
  }
};
export const PROCESS_INSTANCES_NUMBERS_BY_STEPS: IApiObjectType<
  IProcessInstancesNumbersByStepsParams,
  IApiObjectArray<IDashboardNumberItemGroup>
> = {
  id: 'Route/Instances/Numbers',
  createRefs() {
    // nothing to do
  },
  async load(
    cache: IApiObjectCache,
    params: IProcessInstancesNumbersByStepsParams,
    abort: AbortSignal
  ): Promise<IApiObjectArray<IDashboardNumberItemGroup>> {
    const search = new URLSearchParams();
    for (const [k, v] of Object.entries(params)) {
      if (Array.isArray(v)) {
        for (const item of v) {
          search.append(k, item);
        }
      } else if (v !== null && v !== undefined) {
        search.append(k, v);
      }
    }

    const result = await fetchJson({ url: `Route/Instances/NumbersBySteps?${search}`, abort });
    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(PROCESS_INSTANCES_NUMBERS_BY_STEPS, params),
      { items: result }
    ) as IApiObjectArray<IDashboardNumberItemGroup>;
  }
};

export interface IProcessInstanceFilterProps extends IApiObjectData {
  textValues: string[] | null;
  numberValues: number[] | null;
  boolValues: boolean[] | null;
  dateValues: DateTime[] | null;
  userValues: Ref<IUser>[] | null;
  linkValues: IProcessFieldLinkValue[] | null;
  lookupValues: IIdStringPair[] | null;
  hasEmptyValue: boolean;
}

export interface IProcessFieldLinkValue {
  fileId: Uuid;
  url: string | null;
  downloadUrl: string | null;
  wopiUrl: string | null;
  text: string | null;
}

export const PROCESS_INSTANCES_FILTER_PROPS: IApiObjectType<
  IProcessInstanceFilterPropsParams,
  IProcessInstanceFilterProps
> = {
  id: 'Route/Instances/FilterProps',
  createRefs(cache, data) {
    if (data.userValues) {
      createFieldRefs(
        cache,
        data,
        data.userValues.map((user, index) => ({
          path: `userValues/${index}`,
          type: USER,
          params: () => user.userId,
          variant: ApiObjectVariant.Small
        }))
      );
    }
  },
  async load(cache, params, abort) {
    const result = await fetchJson({
      url: `Route/Instances/FilterProps`,
      method: 'POST',
      body: params,
      abort
    });

    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(PROCESS_INSTANCES_FILTER_PROPS, params),
      result
    ) as IProcessInstanceFilterProps;
  }
};

export const PROCESS_INSTANCE_FIELD_VALUES_BY_INSTANCE = simpleIdObject<
  IApiObjectArray<IProcessInstanceStepFieldLink>
>({
  id: 'Route/InstanceFieldValues/ByInstance',
  url: (id) => `Route/InstanceFieldValues/ByInstance?instanceId=${encodeURIComponent(id)}`,
  wrapInArray: true,
  dynFields(data) {
    return data.items.flatMap((item, index) =>
      getProcessInstanceStepFieldLinkFieldRefs(`items/${index}/`, item)
    );
  }
});

export interface IInstanceFieldValuesHistoryParams {
  /** The ID of a field to get the history for */
  fieldId: Uuid;
  /** The ID of a route instance - gets the history for the whole process */
  instanceId?: Uuid;
  /** The ID of a task - gets the history for this task */
  taskId?: Uuid;
}

export interface IFieldValueChangePermissions {
  allowChanges: boolean;
  isResponsibleTeamMember: boolean;
  isCreator: boolean;
  isCreatorAllowed: boolean;
  creatorStepName: string | null;
  creatorStepPassed: boolean;
  isInvolved: boolean;
  isInvolvedAllowed: boolean;
  involvedStepName: string | null;
  involvedStepPassed: boolean;
  isIntegratedProcess: boolean;
  isEditableInIntegration: boolean;
}

export enum FieldValueEditType {
  FromTask = 1,
  Later = 2
}

export interface IFieldValueHistoryItem {
  value: unknown;
  editDate: DateTime;
  editor: Ref<IUser>;
  editType: FieldValueEditType;
  stepName: string;
  stepType: RouteStepType;
}

export interface IFieldValueHistoryResultItem extends IApiObjectData {
  permissions: IFieldValueChangePermissions;
  /** If true, this field determines the name of the process instance. */
  isNamingField: boolean;
  history: IFieldValueHistoryItem[];
}

export const PROCESS_INSTANCE_FIELD_VALUES_HISTORY: IApiObjectType<
  IInstanceFieldValuesHistoryParams,
  IFieldValueHistoryResultItem
> = {
  id: 'Route/InstanceFieldValues/History',
  createRefs(_: IApiObjectCache, data: IFieldValueHistoryResultItem) {
    return data.history.flatMap((entry, index) => [
      { path: `history/${index}/editor`, type: USER, params: () => entry.editor?.userId }
    ]);
  },
  async load(
    cache: IApiObjectCache,
    params: IInstanceFieldValuesHistoryParams,
    abort: AbortSignal
  ): Promise<IFieldValueHistoryResultItem> {
    const url = `Route/InstanceFieldValues/History?${new URLSearchParams(Object.entries(params))}`;
    const result = await fetchJson({ url, abort });
    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(PROCESS_INSTANCE_FIELD_VALUES_HISTORY, params),
      result
    ) as IFieldValueHistoryResultItem;
  }
};
