/* eslint-disable max-classes-per-file */

import { createContext, useContext, useEffect, useRef } from 'react';
import {
  ChatBackendStatus,
  ChatConnectionStatus,
  ChatHistoryStatus,
  IChatItem,
  IChatItemAssistant,
  ILocalFunctionToolDefinition,
  IOpaqueTypingAgent,
  IPendingMessage
} from './model';
import { ApiDataCache } from '../../hooks/api2/cache';
import EventEmitter from './EventEmitter';

export interface IGetChatOptions {
  chatId?: string | null;
  agent?: { id: string; version: number } | null;
  knowledgeBaseId?: string;
  initialTemperature?: number;

  direct?: {
    showError: (error: Error) => void;
    toolsRef: { current: ILocalFunctionToolDefinition[] };
    systemMessage: string;
  };
}

type ChatConstructor = { new (store: ChatStore, options: IGetChatOptions): Chat };

// we don't set a default ChatStore because that wouldn't make sense anyway
export const ChatStoreContext = createContext<ChatStore>(null as unknown as ChatStore);

export function useChatStore() {
  return useContext(ChatStoreContext);
}

export class ChatStore {
  cache: ApiDataCache;

  chats = new Set<Chat>();

  constructor(cache: ApiDataCache) {
    this.cache = cache;
  }

  private getChatForOptions<C extends ChatConstructor>(
    Class: C,
    options: IGetChatOptions
  ): InstanceType<C> {
    if (options.chatId) {
      for (const chat of this.chats.values()) {
        if (chat.backendId === options.chatId && chat instanceof Class) {
          return chat as InstanceType<C>;
        }
      }
    }

    const chat = new Class(this, options);
    this.chats.add(chat);
    return chat as InstanceType<C>;
  }

  getChat<C extends ChatConstructor>(
    Class: C,
    options: IGetChatOptions
  ): ChatSubscription<InstanceType<C>> {
    const chat = this.getChatForOptions(Class, options);
    return new ChatSubscription(this, options, chat);
  }
}

export class ChatSubscription<C extends Chat> {
  store: ChatStore;

  readonly options: IGetChatOptions;

  chat: C;

  constructor(store: ChatStore, options: IGetChatOptions, chat: C) {
    this.store = store;
    this.options = options;
    this.chat = chat;

    this.chat.subscribers.add(this);
  }

  #closed = false;

  close() {
    if (this.#closed) {
      // eslint-disable-next-line no-console
      console.error('ChatSubscription double free');
      return;
    }

    this.#closed = true;
    this.chat.subscribers.delete(this);
    if (!this.chat.subscribers.size) {
      this.chat.privateClose();
    }
    this.chat = null as unknown as C;
  }
}

export type ChatEventMap = {
  connectionStatusChange: { status: ChatConnectionStatus };
  disconnect: { error?: Error };

  backendChange: void;
  backendError: { error: Error };
  backendStatusChange: { status: ChatBackendStatus };

  interactionStatusChange: void;
  interactionLoadError: { error: Error };
  interactionChange: { interaction: ChatInteraction | null };

  itemsChange: { items: Readonly<IChatItem[]> };
  remoteMessageCompleted: { item: IChatItemAssistant };
  pendingMessagesChange: void;

  typingChange: { items: Readonly<IOpaqueTypingAgent[]> };

  waitingForResponseChange: { waiting: boolean };

  historyStatusChange: { status: ChatHistoryStatus };
  historyLoadError: { error: Error };
};

export abstract class Chat extends EventEmitter<ChatEventMap> {
  backendId: string | null = null;

  store: ChatStore;

  /** subscribers currently keeping this chat alive */
  subscribers = new Set<ChatSubscription<this>>();

  protected constructor(store: ChatStore) {
    super();
    this.store = store;
  }

  //#region State Properties

  /** Messages that haven't been sent or confirmed by the server yet. */
  abstract readonly pendingMessages: IPendingMessage[];

  isLoadingInteraction = false;

  nextInteraction: ChatInteraction | null = null;

  #connectionStatus = ChatConnectionStatus.NotConnected;

  get connectionStatus() {
    return this.#connectionStatus;
  }

  protected set connectionStatus(status: ChatConnectionStatus) {
    if (status === this.#connectionStatus) return;
    this.#connectionStatus = status;
    this.dispatchEvent('connectionStatusChange', { status });
  }

  #historyStatus = ChatHistoryStatus.NotReady;

  get historyStatus() {
    return this.#historyStatus;
  }

  protected set historyStatus(status: ChatHistoryStatus) {
    if (status === this.#historyStatus) return;
    this.#historyStatus = status;
    this.dispatchEvent('historyStatusChange', { status });
  }

  #waitingForResponse = false;

  /**
   * We're in this state after we've successfully sent a message, before we've received a response from the server.
   */
  get waitingForResponse() {
    return this.#waitingForResponse;
  }

  protected set waitingForResponse(waiting: boolean) {
    if (waiting === this.#waitingForResponse) return;
    this.#waitingForResponse = waiting;
    this.dispatchEvent('waitingForResponseChange', { waiting });
  }

  #backendStatus = ChatBackendStatus.None;

  get backendStatus() {
    return this.#backendStatus;
  }

  protected set backendStatus(status: ChatBackendStatus) {
    if (status === this.#backendStatus) return;
    this.#backendStatus = status;
    this.dispatchEvent('backendStatusChange', { status });
  }

  #items: Readonly<IChatItem[]> = [];

  get items(): Readonly<IChatItem[]> {
    return this.#items;
  }

  protected set items(items: Readonly<IChatItem[]>) {
    this.#items = items;
    this.dispatchEvent('itemsChange', { items: this.items });
  }

  #opaqueTyping: Readonly<IOpaqueTypingAgent[]> = [];

  get opaqueTyping(): Readonly<IOpaqueTypingAgent[]> {
    return this.#opaqueTyping;
  }

  protected set opaqueTyping(items: Readonly<IOpaqueTypingAgent[]>) {
    this.#opaqueTyping = items;
    this.dispatchEvent('typingChange', { items: this.#opaqueTyping });
  }

  //#endregion

  //#region Abstract Methods

  /** Returns the next interaction in this chat. */
  protected abstract implGetNextInteraction(): Promise<ChatInteraction>;

  /** Closes all open handles. */
  protected abstract implClose(): void;

  abstract retryLoadingHistory(): void;

  abstract canRetryAssistantMessage(item: IChatItemAssistant): boolean;

  abstract retryAssistantMessage(item: IChatItemAssistant): Promise<void>;

  abstract retryPendingMessage(item: IPendingMessage): Promise<void>;

  abstract canReconnect(): boolean;

  abstract reconnect(): void;

  //#endregion

  //#region Next Interaction

  /** Invalidates the next interaction, e.g. because of a state change. */
  protected invalidateNextInteraction() {
    this.nextInteraction?.privateClose();
    this.nextInteraction = null;
    this.nextInteractionPromise = null;
    this.nextInteractionInvalidationCounter += 1;
    this.isLoadingInteraction = false;

    this.dispatchEvent('interactionStatusChange', null);
    this.dispatchEvent('interactionChange', { interaction: this.nextInteraction });
  }

  private nextInteractionPromise: Promise<ChatInteraction> | null = null;

  private nextInteractionInvalidationCounter = 0;

  /**
   * Returns the next interaction in this chat.
   * This is not necessarily a new interaction instance,
   * but could be a previous one that hasn't yet been submitted.
   */
  async getNextInteraction(): Promise<ChatInteraction> {
    if (this.nextInteraction) {
      return this.nextInteraction;
    }
    if (this.nextInteractionPromise) {
      return this.nextInteractionPromise;
    }

    this.isLoadingInteraction = true;
    const counter = this.nextInteractionInvalidationCounter;
    const promise = this.implGetNextInteraction()
      .then((interaction) => {
        if (counter !== this.nextInteractionInvalidationCounter) {
          throw new Error('invalidated');
        }

        this.nextInteraction = interaction;
        this.dispatchEvent('interactionChange', { interaction: this.nextInteraction });
        return interaction;
      })
      .finally(() => {
        if (counter !== this.nextInteractionInvalidationCounter) {
          return;
        }

        this.isLoadingInteraction = false;
        this.nextInteractionPromise = null;
        this.dispatchEvent('interactionStatusChange', null);
      });

    this.nextInteractionPromise = promise;
    this.dispatchEvent('interactionStatusChange', null);

    return promise;
  }

  privateEndInteraction(interaction: ChatInteraction) {
    if (this.nextInteraction === interaction) {
      this.invalidateNextInteraction();
    }
  }

  //#endregion

  /**
   * Chat destructor.
   * Called automatically after closing all subscribers.
   */
  privateClose() {
    this.implClose();
    this.store.chats.delete(this);
  }
}

export abstract class ChatInteraction extends EventEmitter<{ update: void }> {
  abstract cancel(): Promise<void>;

  abstract submit(): Promise<void>;

  // overridable
  // eslint-disable-next-line class-methods-use-this
  privateClose(): void {
    //
  }
}

function isAgentEq(a?: IGetChatOptions['agent'], b?: IGetChatOptions['agent']): boolean {
  return b?.id === a?.id && b?.version === a?.version;
}

function isDirectEq(a?: IGetChatOptions['direct'], b?: IGetChatOptions['direct']): boolean {
  return b?.systemMessage === a?.systemMessage;
}

/**
 * Uses a chat from the ChatStore.
 * @param Class chat subclass to use
 * @param options constructor options
 */
export function useChat<C extends ChatConstructor>(
  Class: C,
  options: IGetChatOptions
): InstanceType<C> {
  const chatStore = useChatStore();

  const chatRef = useRef<ChatSubscription<InstanceType<C>> | null>(null);
  const isRealHookCall = useRef(false);

  const currentBackendId = chatRef.current?.chat?.backendId;
  const { chatId } = options;

  const hasNoChat = !chatRef.current;
  const backendIdChanged = (currentBackendId || chatId) && currentBackendId !== chatId;
  const classChanged = chatRef.current ? !(chatRef.current.chat instanceof Class) : false;
  const createParamsChanged =
    !isAgentEq(options.agent, chatRef.current?.options?.agent) ||
    options.knowledgeBaseId !== chatRef.current?.options?.knowledgeBaseId;
  const directChanged = !isDirectEq(options.direct, chatRef.current?.options?.direct);

  if (hasNoChat || backendIdChanged || classChanged || createParamsChanged || directChanged) {
    const oldSubscription = chatRef.current;

    const subscription = chatStore.getChat(Class, options);
    chatRef.current = subscription;

    oldSubscription?.close();

    if (!isRealHookCall.current) {
      setTimeout(() => {
        if (!isRealHookCall.current) {
          // WORKAROUND:
          // Sometimes (for whatever reason), React calls this hook twice during component
          // instantiation, but only the second call is a "real" call with useEffect and
          // working useRef.
          // This means we have an extra subscription that would never be closed.
          // useEffect has *definitely* been called 100ms later, so here we'll close this
          // subscription if this call wasn't a real call.
          subscription.close();
        }
      }, 100);
    }
  }

  useEffect(() => {
    isRealHookCall.current = true;

    return () => {
      chatRef.current?.close();
    };
  }, [chatRef]);

  return chatRef.current.chat;
}
