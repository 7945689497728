import { IApiObjectArray, Uuid } from './base-types';
import {
  createApiObjectDataFromPlainObject,
  IApiObjectData,
  IApiObjectType,
  makeApiObjectVariantKey
} from '../object';
import { createFieldRefs, simpleIdObject } from './utils';
import { fetchJson } from '../../../services/api2';

export interface ITagItem extends IApiObjectData {
  id: Uuid | null;
  parentId: Uuid | null;
  text: string | null;
  isDeleted: boolean;
  fullText: string | null;
  children: ITagItem[];
}

export const TAG: IApiObjectType<string, ITagItem> = simpleIdObject<ITagItem>({
  id: 'Tag',
  url: (id) => `Tag/${id}`,
  dynFields: (tag) =>
    tag.children.map((tag, index) => ({
      path: `children/${index}`,
      type: TAG,
      params: () => tag.id
    })),
  insertFieldsOnlyIfNeeded: true,
  loadDedupIntervalSecs: 600
});

export interface ITagSearchParams {
  searchTerms?: string;
  onlyParents?: boolean;
  parentId?: Uuid;
  showDeleted?: boolean;
}

export const TAGS: IApiObjectType<ITagSearchParams, IApiObjectArray<ITagItem>> = {
  id: 'Tags',
  createRefs(cache, data) {
    createFieldRefs(
      cache,
      data,
      data.items.map((item, index) => ({
        path: `items/${index}`,
        type: TAG,
        params: () => item.id
      }))
    );
  },
  async load(cache, params, abort) {
    const urlParams = new URLSearchParams();
    for (const [k, v] of Object.entries(params)) urlParams.append(k, v.toString());

    const items = await fetchJson({
      url: `Tags?${urlParams}`,
      abort
    });

    return createApiObjectDataFromPlainObject(cache, makeApiObjectVariantKey(TAGS, params), {
      items
    }) as IApiObjectArray<ITagItem>;
  }
};
