import { usePages } from 'features/App/context/PagesContext';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { PageLocation, instancePropType } from 'types';
import breakpoints from 'utils/breakpoints';
import { checkScreenWidth, getPivotStyles2 } from 'utils/helpers';
import { CommandBar, Icon, Pivot, PivotItem } from '@fluentui/react';
import ContrastingThemeProvider from 'utils/ContrastingThemeProvider';

const InstancePanelHeaderStyled = styled(ContrastingThemeProvider)`
  background: none;
  color: rgb(${({ theme }) => theme.panelHeader.foreground});

  .instance-id-status {
    font-size: 10px;
    padding-left: 16px;

    @media (max-width: ${breakpoints.smallMax}px) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .task-id {
        margin-top: 23px;
      }
    }
  }

  .instance-title {
    font-size: 2em;
    max-height: 100px;
    padding-left: 16px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;

    .instance-title__name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }

    @media (max-width: ${breakpoints.smallMax}px) {
      padding-left: 0px;
      min-height: 0px;
      font-size: 20px;
      max-height: 75px;
      .instance-title__name {
        padding-left: 16px;
      }
    }
  }
`;

const InstanceStatusStyled = styled.div`
  padding-right: 1.75rem;
  margin-left: 0.5rem;

  .instance-status {
    font-size: 13px;
    margin-left: 12px;
  }

  .instance-panel-status {
    display: flex;
    flex-direction: row;
    margin: auto;
  }

  .checkbox-container {
    background-color: rgb(${(props) => props.theme.panelHeader.background});
    border-radius: 50%;
    border: 1px solid rgb(${({ theme }) => theme.statusDropdown.outline});
    height: 31px;
    width: 31px;
    margin-top: 4px;
  }

  .checkbox-icon {
    color: rgb(${(props) => props.theme.panelHeader.background});
    font-size: 15px;
    display: block;
    margin-left: 7px;
    padding-top: 7px;
  }

  .instance-panel-status-label {
    font-size: 10px;
    padding-left: 8px;
  }

  .status-block {
    padding-top: 4px;
  }
`;

function InstancePanel({ instance, tab, onTabChange }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const { pages } = usePages([PageLocation.processDetails]);

  const { filterType, templateId, instanceId } = useParams();

  const statusContainerStyle =
    instance?.status === 5 ? { backgroundColor: 'white' } : { display: 'inline' };

  const statusIconStyle = instance?.status === 5 ? { display: 'block' } : { display: 'none' };

  function onLinkClick(item) {
    if (onTabChange) {
      onTabChange(item.props.itemKey);
    } else {
      navigate(
        `/process-instances/${filterType}/${templateId}/detail/${instanceId}/${item.props.itemKey}`
      );
    }
  }

  function renderPagesPivotItems() {
    const pagesToRender = [];

    pages.forEach((page) => {
      if (instance?.id && instance.includedPages.includes(page.id)) {
        pagesToRender.push(
          <PivotItem
            itemIcon={checkScreenWidth(['extraSmall']) ? 'Page' : undefined}
            headerText={page.name}
            itemKey={page.id}
            key={page.id}
          />
        );
      }
    });

    return pagesToRender;
  }

  function renderCommandBar() {
    if (tab !== 'attachments') return null;

    return (
      <CommandBar
        items={[
          {
            key: 'newFile',
            text: t('attachmentsPivotItem.commandbar.newFile'),
            iconProps: { iconName: 'add' },
            onClick: () => {
              const fileInput = document.getElementById(`file-input-${instance.id}`);

              if (fileInput) {
                fileInput.click();
              }
            }
          }
        ]}
        styles={{
          root: {
            position: 'relative',
            zIndex: 300,
            boxShadow: '0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);'
          }
        }}
      />
    );
  }

  const getInstanceStatus = () => {
    let InstanceStatus = null;
    switch (instance?.status) {
      case 2:
        InstanceStatus = t('instancePanelDetail.status.2');
        break;
      case 3:
        InstanceStatus = t('instancePanelDetail.status.3');
        break;
      case 4:
        InstanceStatus = t('instancePanelDetail.status.4');
        break;
      default:
        InstanceStatus = t('instancePanelDetail.status.1');
        break;
    }
    return InstanceStatus;
  };

  const statusDropdown = (
    <InstanceStatusStyled>
      <div className="instance-panel-status">
        <div style={statusContainerStyle} className="checkbox-container">
          <Icon style={statusIconStyle} className="checkbox-icon" iconName="Accept" />
        </div>
        <div className="status-block">
          <div className="instance-panel-status-label">Status</div>
          <div className="instance-status">{getInstanceStatus()}</div>
        </div>
      </div>
    </InstanceStatusStyled>
  );

  return (
    <div>
      <InstancePanelHeaderStyled backgroundColor={(theme) => theme.panelHeader.background}>
        <div className="instance-id-status">
          <div className="instance-id">#{instance?.intId}</div>
          {checkScreenWidth(['extraSmall', 'small']) ? statusDropdown : null}
        </div>
        <div className="instance-title">
          <div className="instance-title__name">{instance?.name}</div>
          {!checkScreenWidth(['extraSmall', 'small']) ? statusDropdown : null}
        </div>
      </InstancePanelHeaderStyled>
      {instance?.permissions?.readSummary ? (
        <>
          <Pivot
            overflowBehavior="menu"
            linkSize="normal"
            selectedKey={`${tab}`}
            onLinkClick={onLinkClick}
            styles={getPivotStyles2(theme)}
          >
            <PivotItem
              itemIcon={checkScreenWidth(['extraSmall']) ? 'Org' : null}
              className="pivot-item-height"
              headerText={
                checkScreenWidth(['extraSmall'])
                  ? null
                  : t('instancePanelDetail.pivotItem.label.process')
              }
              itemKey="instance"
            />
            <PivotItem
              itemIcon={checkScreenWidth(['extraSmall']) ? 'Message' : null}
              className="pivot-item-height"
              itemKey="conversation"
              headerText={
                checkScreenWidth(['extraSmall'])
                  ? null
                  : t('instancePanelDetail.pivotItem.label.conversation')
              }
            />
            <PivotItem
              itemIcon={checkScreenWidth(['extraSmall']) ? 'Attach' : null}
              className="pivot-item-height"
              itemKey="attachments"
              headerText={
                checkScreenWidth(['extraSmall'])
                  ? null
                  : t('instancePanelDetail.pivotItem.label.attachments')
              }
            />
            {pages?.length > 0 && renderPagesPivotItems()}
          </Pivot>
          {renderCommandBar()}
        </>
      ) : (
        <div style={{ paddingTop: '2em' }} />
      )}
    </div>
  );
}

InstancePanel.propTypes = {
  tab: PropTypes.string,
  instance: instancePropType,
  onTabChange: PropTypes.func
};

InstancePanel.defaultProps = {
  tab: 'instance'
};

export default InstancePanel;
