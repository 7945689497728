/* eslint-disable no-bitwise */

import {
  Attachments,
  Conversation,
  Dialog,
  IFramePage,
  LoadingSpinner,
  Panel,
  PanelCloseButton,
  TeamPanel
} from 'components';
import CmdBarOverflowMenu from 'components/inputs/CmdBarOverflowMenu/CmdBarOverflowMenu';
import DialogCustom from 'components/surfaces/Dialog/DialogCustom';
import { usePages } from 'features/App/context/PagesContext';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import fetchRequest, { apiErrorHandler } from 'services/api';
import {
  createComment,
  deleteComment,
  fileContents,
  getComments,
  getFiles,
  onDeleteFile,
  tagsSearch,
  updateComment,
  updateFile,
  uploadFile2,
  userSearch
} from 'services/fetchRequests';
import styled from 'styled-components';
import { PageLocation, projectPropType, teamPropType, userPropType } from 'types';
import { checkScreenWidth, formatDate, isUuid, onOpenWopi } from 'utils/helpers';
import { CommandBar, PanelType, Text } from '@fluentui/react';
import { useFileUploadJobs } from 'components/lists/Attachments/Attachments';
import ProjectPanelHeader from './ProjectPanelHeader';
import ProjectPivotItem from './ProjectPivotItem';
import ProjectProcessesPivotItem from './ProjectProcessesPivotItem';
import ProjectSettingsPivotItem from './ProjectSettingsPivotItem';
import ProjectSprintsPivotItem from './ProjectSprintsPivotItem';

// styles
const ProjectPanelCommandBarStyled = styled(CommandBar)`
  .hideItem {
    display: none;
  }
`;

const DeleteDialogContainer = styled.div`
  padding: 10px;
  border: 1px solid red;
`;

function ProjectPanel({
  onClosePanel,
  updateProjects,
  updateSprints,
  templateId,
  onDeleteProject,
  defaultProject,
  onPivotLinkClick,
  tab,
  addNewSprint,
  currentUser
}) {
  const { t } = useTranslation();

  const [newProject, setNewProject] = useState(null);
  const [newTeam, setNewTeam] = useState(null);
  const [isTeamPanelOpen, setIsTeamPanelOpen] = useState(false);

  // saved project sprints
  const [sprints, setSprints] = useState(null);

  const [sprintToDelete, setSprintToDelete] = useState(null);

  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isCalloutVisible, setIsCalloutVisible] = useState(false);

  const [errorInField, setErrorInField] = useState(null);
  const [errorsInSprints, setErrorsInSprints] = useState(null);

  // track changes in the project
  const [projectChanges, setProjectChanges] = useState({});

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [showNotification, setShowNotification] = useState(false);

  // Notification dialog content
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationSubText, setNotificationSubText] = useState('');

  const navigate = useNavigate();
  const { filterType, projectFilterType, projectId } = useParams();

  const [newStatus, setNewStatus] = useState(null);
  const [completeDialogProps, setCompleteDialogProps] = useState({
    hidden: true,
    closePanel: true
  });

  const { pages } = usePages([PageLocation.projectDetails]);

  const isEdit = !!defaultProject?.id || !!projectId;

  let disabled = newProject?.permissions && !newProject?.permissions.update;

  if (!disabled) {
    disabled = (!newStatus && newProject?.status === 10) || isSaving;
  }

  const { fileUploadJobs, addFileUploadJob, removeFileUploadJob } = useFileUploadJobs(true);

  const setInitialProject = useCallback(async () => {
    if (isEdit && !defaultProject?.id && projectId) {
      fetchRequest({ url: `Project/${projectId}` })
        .then((fetchedProject) => {
          setIsLoading(false);
          setNewProject(fetchedProject);
          setNewTeam(fetchedProject?.team);
        })
        .catch(apiErrorHandler);
    } else {
      setNewTeam(defaultProject?.team);
      setNewProject(defaultProject || {});
      setIsLoading(false);
    }
  }, [isEdit, defaultProject, projectId]);

  useEffect(() => {
    if (!newProject) {
      setInitialProject();
    }
  }, [setInitialProject, newProject]);

  function sortSprintsByStartDateDesc(sprintA, sprintB) {
    const propA =
      typeof sprintA?.startDate === 'string'
        ? new Date(sprintA?.startDate?.substring(0, 10))
        : new Date(formatDate(sprintA?.startDate, 'yyyy-MM-DD'));
    const propB =
      typeof sprintB?.startDate === 'string'
        ? new Date(sprintB?.startDate?.substring(0, 10))
        : new Date(formatDate(sprintB?.startDate, 'yyyy-MM-DD'));

    return propA > propB ? 1 : -1;
  }

  function sortColumnsByName(columnA, columnB) {
    const propA = columnA?.name;
    const propB = columnB?.name;

    return propA > propB ? 1 : -1;
  }

  useEffect(() => {
    // 'tab' property is used to show panel and prevent calling api if panel is not open
    if (newProject?.id && !sprints && tab) {
      fetchRequest({ url: `Projects/Sprints?projectId=${newProject?.id}` })
        .then((response) => {
          const sortedResponse =
            newProject?.type === 2
              ? response.sort(sortSprintsByStartDateDesc)
              : response.sort(sortColumnsByName);

          const sprints = sortedResponse.map((sprint, index) => {
            return { ...sprint, index };
          });

          setSprints(sprints);
        })
        .catch(apiErrorHandler);
    }
  }, [newProject?.id, newProject?.type, sprints, tab]);

  function deleteProject() {
    onDeleteProject(newProject);
  }

  function closePanel(project) {
    setNewProject(null);
    setSprints(null);

    if (onClosePanel) {
      onClosePanel(project);
    }
  }

  function onDismiss(event) {
    if (event && event.type !== 'mousedown') {
      closePanel();
    }
  }

  function validateFields(project) {
    let formIsValid = true;
    let errorInField = null;

    if (!project.name) {
      formIsValid = false;

      errorInField = {
        fieldName: 'name',
        message: t('projectPanel.errorMessage.name'),
        tab: 'project'
      };
    } else if (!newTeam) {
      formIsValid = false;

      errorInField = {
        fieldName: 'team',
        message: t('projectPanel.errorMessage.team'),
        tab: 'project'
      };
    } else if (!project.type) {
      formIsValid = false;

      errorInField = {
        fieldName: 'type',
        message: t('projectPanel.errorMessage.type'),
        tab: 'project'
      };
    } else if (!project.startDate) {
      formIsValid = false;

      errorInField = {
        fieldName: 'startDate',
        message: t('projectPanel.errorMessage.startDate'),
        tab: 'project'
      };
    } else if (!templateId && project.type === 1 && !project.plannedEndDate) {
      formIsValid = false;

      errorInField = {
        fieldName: 'plannedEndDate',
        message: t('projectPanel.errorMessage.plannedEndDate'),
        tab: 'project'
      };
    } else if (project.type === 2 && !project.sprintDuration) {
      formIsValid = false;

      errorInField = {
        fieldName: 'sprintDuration',
        message: t('projectPanel.errorMessage.sprintDuration'),
        tab: 'project'
      };
    } else if (project.type === 2 && (!project.numberOfSprints || project.numberOfSprints < 0)) {
      formIsValid = false;

      errorInField = {
        fieldName: 'numberOfSprints',
        message: t('projectPanel.errorMessage.numberOfSprints'),
        tab: 'project'
      };
    }

    setErrorInField(errorInField);

    return formIsValid;
  }

  function handleFieldErrorState(passedProject) {
    if (errorInField) {
      if (passedProject[errorInField.fieldName]) {
        setErrorInField(null);
      }
    }
  }

  function handleProjectChanges(passedProject) {
    const startProject = newProject;

    if (startProject?.id) {
      const newChanges = { ...projectChanges };

      if (passedProject.name !== startProject.name) {
        newChanges.name = true;
      }

      if (passedProject.description !== startProject.description) {
        newChanges.description = true;
      }

      if (passedProject.type !== startProject.type) {
        newChanges.type = true;
      }

      if (passedProject.numberOfSprints !== startProject.numberOfSprints) {
        newChanges.numberOfSprints = true;
      }

      if (passedProject.sprintDuration !== startProject.sprintDuration) {
        newChanges.sprintDuration = true;
      }

      if (passedProject.plannedEndDate !== startProject.plannedEndDate) {
        newChanges.plannedEndDate = true;
      }

      if (passedProject.startDate !== startProject.startDate) {
        newChanges.startDate = true;
      }

      if (passedProject.endDate !== startProject.endDate) {
        newChanges.endDate = true;
      }

      if (passedProject.tags?.length !== startProject.tags?.length) {
        newChanges.tags = true;
      } else {
        let tagsChanged = false;

        passedProject.tags.map((passedTag) => {
          const foundTag = startProject.tags.find((startTag) => startTag === passedTag);

          if (foundTag) {
            tagsChanged = true;
          }

          return null;
        });

        newChanges.tags = tagsChanged;
      }

      setProjectChanges(newChanges);
    }
  }

  function handleProjectState(passedProject) {
    if (passedProject) {
      handleFieldErrorState(passedProject);

      handleProjectChanges(passedProject);

      setNewProject(passedProject);
    }
  }

  function handleTeamState(passedTeams) {
    if (!passedTeams || !passedTeams.length) return;

    const passedTeam = passedTeams[0];

    if (passedTeam.id === '00000000-0000-0000-0000-000000000000') {
      setNewTeam({
        ...passedTeam,
        typeId: 1,
        departmentId: 0,
        parent: null,
        members: [{ ...currentUser, roleId: 1 }],
        permissions: {
          delete: true,
          update: true
        }
      });

      setIsTeamPanelOpen(true);
    } else {
      setNewTeam(passedTeam);
    }

    if (errorInField?.fieldName === 'team') {
      setErrorInField(null);
    }

    setProjectChanges({ ...projectChanges, team: true });
    setNewProject((prevState) => ({ ...prevState, team: passedTeam }));
  }

  function handleSprintsState(sprintsToSave) {
    if (sprintsToSave) {
      // sort sprints by start date desc
      let sortedSprints = [];

      if (newProject.type === 2) {
        sortedSprints = sprintsToSave.sort(sortSprintsByStartDateDesc);
      } else if (newProject.type === 1) {
        sortedSprints = sprintsToSave.sort(sortColumnsByName);
      }

      // in case there is a change in sprint order, reindex the sprints
      const rearangedSprints = sortedSprints.map((sprint, index) => {
        const newSprint = { ...sprint, index };

        return newSprint;
      });

      setSprints(rearangedSprints);

      return rearangedSprints;
    }

    return null;
  }

  function onStatusChange(givenStatus) {
    setNewStatus(givenStatus);
  }

  async function saveSprint(sprintToSave) {
    if (sprintToSave) {
      // format date values as date only string, because API works only with date
      const newSprintToSave = {
        ...sprintToSave,
        projectId: newProject.id
      };

      if (newProject.type === 2) {
        newSprintToSave.startDate = formatDate(sprintToSave.startDate, 'yyyy-MM-DD');
        newSprintToSave.endDate = formatDate(sprintToSave.endDate, 'yyyy-MM-DD');
      }

      // remove list index from each sprint before saving
      delete newSprintToSave.index;

      const method = 'POST';

      const resultSprints = await fetchRequest({
        url: 'Project/Sprint',
        method,
        body: JSON.stringify(newSprintToSave)
      }).catch((err) => {
        setIsSaving(false);
        return apiErrorHandler(err);
      });

      const sortedAndIndexedSprints = handleSprintsState(resultSprints);

      if (updateSprints) {
        updateSprints(sortedAndIndexedSprints);
      }

      const lastSprint = sortedAndIndexedSprints[sortedAndIndexedSprints.length - 1];
      let updatedProject = null;

      if (
        sprintToSave.index === 0 &&
        formatDate(sprintToSave.startDate, 'yyyy-MM-DD') !==
          formatDate(newProject.startDate, 'yyyy-MM-DD')
      ) {
        updatedProject = {
          ...newProject,
          startDate: formatDate(sprintToSave.startDate, 'yyyy-MM-DD')
        };

        setNewProject(updatedProject);
        updateProjects(updatedProject, 'PUT');
      }

      if (
        formatDate(lastSprint.endDate, 'yyyy-MM-DD') !==
        formatDate(newProject.plannedEndDate, 'yyyy-MM-DD')
      ) {
        const currentProjectState = updatedProject || newProject;

        updatedProject = {
          ...currentProjectState,
          plannedEndDate: formatDate(lastSprint.endDate, 'yyyy-MM-DD')
        };
      }

      if (updatedProject) {
        setNewProject(updatedProject);
        updateProjects(updatedProject, 'PUT');
      }
    }
  }

  const onShowNotification = (title, message) => {
    const defaultTitle = t('projectPanel.notificationDialog.title.forbidden');

    setNotificationTitle(title || notificationTitle || defaultTitle);

    if (message && message !== '') {
      setNotificationSubText(message);
    }

    if (showDeleteDialog) {
      setShowDeleteDialog(false);
    }

    setShowNotification(true);
  };

  function onDeleteSprint(sprint) {
    if (sprint?.id) {
      setSprintToDelete(sprint);
      setShowDeleteDialog(true);
    }
  }

  function deleteSprint() {
    if (sprintToDelete) {
      const newSprints = [...sprints];
      newSprints.splice(sprintToDelete.index, 1);

      setShowDeleteDialog(false);

      fetchRequest({
        url: `Project/Sprint/${sprintToDelete.id}`,
        method: 'DELETE',
        ignoreAlert: true
      })
        .then(() => {
          setSprints(newSprints);

          if (updateSprints) {
            updateSprints(newSprints, sprintToDelete.id);
          }

          if (
            formatDate(newSprints[newSprints.length - 1].endDate, 'yyyy-MM-DD') !==
            formatDate(newProject.plannedEndDate, 'yyyy-MM-DD')
          ) {
            const updatedProject = {
              ...newProject,
              plannedEndDate: formatDate(newSprints[newSprints.length - 1].endDate, 'yyyy-MM-DD')
            };

            setNewProject(updatedProject);
            updateProjects(updatedProject, 'PUT');
          }
        })
        .catch((errResponse) => {
          if (errResponse && !(errResponse instanceof SyntaxError)) {
            errResponse.then((err) => {
              const error = JSON.parse(err);

              if (
                !(err instanceof SyntaxError) &&
                (error.status === 400 ||
                  error.status === 401 ||
                  error.status === 403 ||
                  (error.status >= 500 && error.status <= 600))
              ) {
                onShowNotification(null, error.message);
              } else if (!(err instanceof SyntaxError)) {
                onShowNotification(null, err);
              }
            });
          }
        });
    }
  }

  // if (project.status !== option.key) {
  //   const body = JSON.stringify({ ...project, status: option.key });
  //   let url = 'Project';

  //   if (completeTasks) {
  //     url = 'Project?completeTasks=true';
  //     setIsDialogHidden(true);
  //   }

  //   fetchRequest({ url, method: 'PUT', body, ignoreAlert: !completeTasks })
  //     .then(onProjectStatusUpdate)
  //     .catch((errorPromise) => {
  //       if (!completeTasks) apiErrorHandler(errorPromise);

  //       errorPromise.then((errorJSON) => {
  //         if (errorJSON.status === 400) {
  //           setIsDialogHidden(false);
  //         } else {
  //           apiErrorHandler(errorPromise);
  //         }
  //       });
  //     });
  // }

  async function saveProject(close, completeTasks = false) {
    let resultProject = null;

    const inGantt = window.location.href.includes('gantt');
    const inProject = window.location.href.includes('task-list');
    const inTemplatesList = window.location.href.includes('projects/templates');

    if (!errorsInSprints) {
      if (validateFields(newProject) && newTeam?.id) {
        const projectToUpdate = { ...newProject };
        if (!projectToUpdate.status) {
          projectToUpdate.status = 1;
        }

        if (newStatus) {
          projectToUpdate.status = newStatus;
        }

        projectToUpdate.team = { ...newTeam };

        const method = isEdit ? 'PUT' : 'POST';

        let baseUrl = 'Project';

        if (templateId && !isEdit) {
          baseUrl = `Project/FromTemplate/${templateId}`;
        }

        let url = `${baseUrl}`;

        if (completeTasks) {
          url = `${baseUrl}?completeTasks=true`;
        }

        resultProject = await fetchRequest({
          url,
          method,
          body: JSON.stringify(projectToUpdate),
          ignoreAlert: !completeTasks
        }).catch((errorPromise) => {
          // setIsSaving(false);

          errorPromise.then((errorJSON) => {
            if (errorJSON.status === 400) {
              setCompleteDialogProps({ hidden: false, closePanel: close });
            } else {
              apiErrorHandler(errorPromise);
            }
          });
        });

        if (resultProject) {
          setCompleteDialogProps({ hidden: true, closePanel: false });
          setNewProject(resultProject);
          setProjectChanges({});
          updateProjects?.(resultProject, method);

          if (close) {
            closePanel(resultProject);
          } else if (!newProject?.id && resultProject?.id) {
            let url;

            if (!inProject) {
              url = `/projects/${filterType}/project-details/${resultProject?.id}/${tab}`;
            }

            if (inGantt || inTemplatesList) {
              url = `/projects/${filterType || 'all'}/gantt/${resultProject?.id}/${tab}`;
            }

            if (url) {
              navigate(url);
            }
          }
        } else {
          setIsSaving(false);
        }
      }
    } else if (close) {
      closePanel(newProject);
    } else {
      let url;

      if (!inProject) {
        url = `/projects/${filterType || projectFilterType}/project-details/${
          newProject?.id
        }/${tab}`;
      }

      if (inGantt) {
        url = `/projects/${filterType}/gantt/${newProject?.id}/${tab}`;
      }

      if (url) {
        navigate(url);
      }
    }

    setIsSaving(false);
  }

  function getCmdBarCalloutMenuItems() {
    let cmdBarItems = [
      {
        index: 0,
        disabled,
        key: 'saveAndClose',
        text: t('projectPanel.commandBar.button.saveAndClose'),
        iconProps: { iconName: 'SaveAndClose' },
        onClick: () => {
          setIsSaving(true);
          saveProject(true);
        }
      },
      {
        index: 1,
        disabled,
        key: 'save',
        text: t('projectPanel.commandBar.button.save'),
        iconProps: { iconName: 'save' },
        onClick: () => {
          setIsSaving(true);
          saveProject(false);
        }
      }
    ];

    if (templateId) {
      cmdBarItems = [
        {
          index: 0,
          disabled,
          key: 'saveAndClose',
          text: t('projectPanel.createProject'),
          iconProps: { iconName: 'save' },
          onClick: () => {
            setIsSaving(true);
            saveProject(true);
          }
        }
      ];
    }

    return cmdBarItems;
  }

  function showCmdMenuForMobiles() {
    if (!isCalloutVisible) {
      setTimeout(() => {
        setIsCalloutVisible(true);
      }, 60);
    } else {
      setIsCalloutVisible(false);
    }
  }

  function onTagClick(tagId) {
    if (tagId) navigate(`/tags/${tagId}`);
  }

  function deleteFile(fileToDelete) {
    onDeleteFile({ id: fileToDelete.id });
  }

  function onUploadFile(file, onProgress) {
    return uploadFile2({ file, projectId: newProject.id, onUploadProgress: onProgress });
  }

  function findFile(fileToFind, currentFiles) {
    if (!currentFiles || currentFiles.length === 0) {
      return undefined;
    }

    const { name } = fileToFind;

    return currentFiles.find((stateFile) => {
      return stateFile.name === name;
    });
  }

  function getPersons(searchText) {
    return userSearch({ searchTerm: searchText }).then((searchResult) => searchResult.items);
  }

  function getTags(searchText, tagSuggestionParentId) {
    return tagsSearch({
      searchTerm: searchText,
      parentId: tagSuggestionParentId,
      onlyParents: false,
      showDeleted: false
    });
  }

  function getUniqueAndDuplicateFiles(filesToSeparate, currentFiles) {
    const uniqueFiles = [];
    const duplicateFiles = [];

    if (!currentFiles || currentFiles.length === 0) {
      // no duplicate files possible. return given files
      return { uniqueFiles: filesToSeparate, duplicateFiles };
    }

    filesToSeparate.forEach((file) => {
      const isDuplicateFile = !!findFile(file, currentFiles);

      if (isDuplicateFile) {
        duplicateFiles.push(file);
      } else {
        uniqueFiles.push(file);
      }
    });

    return { uniqueFiles, duplicateFiles };
  }

  function getCmdBarButtons() {
    let cmdBarItems = [];
    if (checkScreenWidth(['extraSmall'])) {
      cmdBarItems = [
        {
          key: 'more',
          ariaLabel: 'More',
          className: 'cmdMoreMenu',
          iconOnly: true,
          iconProps: { iconName: 'More' },
          onClick: (ev) => showCmdMenuForMobiles(ev)
        }
      ];
    } else if (tab !== 'files' && tab !== 'conversation') {
      cmdBarItems = [
        {
          disabled,
          key: 'save',
          id: 'project-panel-save-button',
          onClick: () => {
            setIsSaving(true);
            saveProject(false);
          },
          text: t('projectPanel.commandBar.button.save'),
          iconProps: { iconName: 'save' },
          className: 'command-bar-item',
          split: true,
          subMenuProps: {
            items: [
              {
                key: 'saveAndClose',
                className: 'command-bar-item',
                text: t('projectPanel.commandBar.button.saveAndClose'),
                iconProps: { iconName: 'SaveAndClose' },
                disabled,
                onClick: () => {
                  setIsSaving(true);
                  saveProject(true);
                }
              }
            ]
          }
        }
      ];

      if (templateId) {
        cmdBarItems = [
          {
            index: 0,
            disabled,
            key: 'saveAndClose',
            text: t('projectPanel.createProject'),
            iconProps: { iconName: 'save' },
            onClick: () => {
              setIsSaving(true);
              saveProject(false);
            }
          }
        ];
      }
    }

    if (tab === 'files') {
      cmdBarItems.push({
        key: 'newFile',
        text: t('attachmentsPivotItem.commandbar.newFile'),
        iconProps: { iconName: 'add' },
        onClick: () => {
          const fileInput = document.getElementById('file-input');

          if (fileInput) {
            fileInput.click();
          }
        }
      });
    }

    return cmdBarItems;
  }

  let commandBar = null;
  const cmdBarFarItems = [];
  const cmdBarItems = getCmdBarButtons();

  if (tab === 'project') {
    cmdBarFarItems.push({
      key: 'delete',
      className: 'command-bar-item',
      text: t('projectPanel.commandBar.button.delete'),
      iconProps: {
        iconName: 'delete'
      },
      disabled: !newProject?.permissions?.delete || newProject?.status === 10 || !isEdit,
      onClick: () => deleteProject()
    });
  }

  commandBar = (
    <ProjectPanelCommandBarStyled
      items={cmdBarItems}
      farItems={cmdBarFarItems}
      styles={{
        root: {
          position: 'relative',
          zIndex: 300,
          boxShadow: '0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);'
        }
      }}
    />
  );

  function getPanelContent() {
    let body = null;
    if (tab === 'sprints' && newProject?.type === 2) {
      body = (
        <ProjectSprintsPivotItem
          project={newProject}
          disabled={disabled}
          sprints={sprints}
          saveSprint={saveSprint}
          saveSprintChanges={handleSprintsState}
          addNewSprint={addNewSprint}
          updateSprintErrors={(errorsExist) => setErrorsInSprints(errorsExist)}
          deleteSprint={onDeleteSprint}
        />
      );
    }

    if (tab === 'conversation') {
      body = (
        <Conversation
          currentUser={currentUser}
          getComments={() => getComments({ projectId: newProject.id })}
          getPersons={getPersons}
          getTags={getTags}
          id={newProject.id}
          onCreateComment={(props) => createComment({ ...props, projectId: newProject.id })}
          onDeleteComment={deleteComment}
          onTagClick={onTagClick}
          onUpdateComment={updateComment}
        />
      );
    }

    if (tab === 'files') {
      body = (
        <Attachments
          disabled={false}
          updateFile={updateFile}
          hasUploadPermission
          onTagsSearch={tagsSearch}
          onTagClick={onTagClick}
          getUniqueAndDuplicateFiles={getUniqueAndDuplicateFiles}
          getFiles={() => getFiles({ projectId: newProject.id })}
          getFileContents={fileContents}
          onDeleteFile={deleteFile}
          uploadFile={onUploadFile}
          onOpenWopi={onOpenWopi}
          fileUploadJobs={fileUploadJobs}
          onAddFileUploadJob={addFileUploadJob}
          onRemoveFileUploadJob={removeFileUploadJob}
        />
      );
    }

    if (tab === 'processes') {
      body = (
        <ProjectProcessesPivotItem
          disabled={disabled}
          project={newProject}
          setProject={setNewProject}
        />
      );
    }

    if (tab === 'settings') {
      body = (
        <ProjectSettingsPivotItem
          disabled={disabled}
          project={newProject}
          setProject={setNewProject}
        />
      );
    }

    if (pages?.length > 0) {
      const page = pages.find(({ id }) => id === tab);

      if (page) {
        body = <IFramePage src={page.url} projectId={projectId} />;
      }
    }

    if (tab === 'project') {
      body = (
        <ProjectPivotItem
          project={newProject}
          isTemplate={templateId}
          team={newTeam}
          disabled={disabled}
          handleProjectState={handleProjectState}
          handleTeamState={handleTeamState}
          errorInField={errorInField}
        />
      );
    }

    return (
      <div style={{ height: '100%' }}>
        {isSaving ? <LoadingSpinner label={t('projectPanel.saving.text')} /> : body}
      </div>
    );
  }

  function onRenderHeader() {
    return (
      <div style={{ paddingTop: 40 }}>
        <PanelCloseButton onClick={onDismiss} />
        <ProjectPanelHeader
          isTemplate={templateId}
          project={newProject}
          onPivotLinkClick={onPivotLinkClick}
          tab={tab}
          newStatus={newStatus}
          disabled={disabled}
          onStatusChange={onStatusChange}
          pages={pages}
        />
        <div className={`${checkScreenWidth(['extraSmall']) ? null : 'sticky'}`}>
          {isUuid(tab) || tab === 'conversation' ? undefined : commandBar}
          {isCalloutVisible ? (
            <CmdBarOverflowMenu
              isCalloutVisible={isCalloutVisible}
              items={getCmdBarCalloutMenuItems()}
              onDismiss={() => setIsCalloutVisible(false)}
              disabled={disabled}
            />
          ) : null}
        </div>
      </div>
    );
  }

  function validTab() {
    return !!tab;
  }

  if (!newProject) {
    return null;
  }

  return isLoading ? (
    <LoadingSpinner text={t('loading.projectPanel.text')} />
  ) : (
    <>
      <Panel
        isLightDismiss={false}
        isOpen={validTab()}
        onDismiss={onDismiss}
        onRenderHeader={onRenderHeader}
        hideCloseButton
        onRenderBody={() => getPanelContent()}
        allowTouchBodyScroll
        type={PanelType.medium}
      />

      <Dialog
        defaultButtonProps={{
          onClick: () => setCompleteDialogProps({ ...completeDialogProps, hidden: true }),
          text: t('globals.cancel')
        }}
        primaryButtonProps={{
          onClick: () => saveProject(completeDialogProps.closePanel, true),
          text: t('globals.confirm')
        }}
        content={
          <Text as="div" block styles={{ root: { marginTop: '15px' } }}>
            {t('projectPanelHeader.dialog.content')}
          </Text>
        }
        styles={{
          root: {},
          main: { minWidth: '40vw !important', maxWidth: '40vw !important', borderRadius: '5px' }
        }}
        hidden={completeDialogProps.hidden}
        onDismiss={() => setCompleteDialogProps({ ...completeDialogProps, hidden: true })}
        title={t('projectPanelHeader.dialog.label')}
      />
      {showNotification ? (
        <DialogCustom
          hidden={!showNotification}
          defaultButtonFunc={() => setShowNotification(false)}
          defaultButtonLabel={t('projectPanel.notificationDialog.defaultButtonLabel')}
          subText={notificationSubText}
          title={notificationTitle}
          onDismiss={() => setShowNotification(false)}
        />
      ) : null}
      {showDeleteDialog ? (
        <DeleteDialogContainer>
          <DialogCustom
            hidden={!showDeleteDialog}
            title={
              newProject?.type === 2
                ? t('projectPanel.deleteDialog.titleSprints')
                : t('projectPanel.deleteDialog.titleColumns')
            }
            subText={
              newProject?.type === 2
                ? t('projectPanel.deleteDialog.subTextSprints', { item: sprintToDelete.name })
                : t('projectPanel.deleteDialog.subTextColumns', { item: sprintToDelete.name })
            }
            defaultButtonLabel={t('projectPanel.deleteDialog.defaultButtonLabel')}
            primaryButtonLabel={t('projectPanel.deleteDialog.primaryButtonLabel')}
            primaryButtonFunc={deleteSprint}
            defaultButtonFunc={() => setShowDeleteDialog(false)}
            onDismiss={() => setShowDeleteDialog(false)}
            modalProps={{ isBlocking: true }}
          />
        </DeleteDialogContainer>
      ) : null}
      <TeamPanel
        currentUser={currentUser}
        onUpdateTeams={(team) => {
          setNewTeam(team);
        }}
        onClosePanel={(team) => {
          setNewTeam(team || newProject?.team);
          setIsTeamPanelOpen(false);
        }}
        team={newTeam}
        hidden={!isTeamPanelOpen}
      />
    </>
  );
}

ProjectPanel.propTypes = {
  onClosePanel: PropTypes.func.isRequired,
  updateProjects: PropTypes.func,
  updateSprints: PropTypes.func,
  onDeleteProject: PropTypes.func.isRequired,
  defaultProject: projectPropType,
  team: teamPropType,
  onPivotLinkClick: PropTypes.func.isRequired,
  tab: PropTypes.string,
  templateId: PropTypes.string,
  addNewSprint: PropTypes.bool,
  currentUser: userPropType
};

ProjectPanel.defaultProps = {
  updateProjects: null,
  updateSprints: null,
  defaultProject: null,
  team: null,
  tab: null,
  addNewSprint: null,
  currentUser: null
};

export default ProjectPanel;
