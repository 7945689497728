import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';

const ChecklistContainerStyled = styled.div`
  background-color: rgb(${({ theme }) => theme.taskDetailsChecklist.background});
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 25px;

  @media (max-width: ${breakpoints.extraSmallMax}px) {
    padding: 15px;
  }

  .c-checklist_item-container {
    height: 100%;
    width: 100%;
    background-color: rgb(${({ theme }) => theme.taskDetailsChecklist.itemBackground});
    padding: 8px 8px 8px 5px;
    border-top: 1px solid rgb(${({ theme }) => theme.taskDetailsChecklist.itemDivider});

    @media (max-width: ${breakpoints.extraSmallMax}px) {
      padding-left: 10px;
    }

    &:hover {
      .c-checklist_item-overflowbutton {
        opacity: 1;
      }
    }

    @media (max-width: ${breakpoints.extraSmallMax}px) {
      .c-checklist_item-overflowbutton {
        opacity: 1;
      }
    }
  }

  .c-checklist_item-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .c-checklist_item-overflowbutton {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .c-checklist_item-icon {
    font-size: 20px;
    padding-right: 2px;
    color: rgb(${({ theme }) => theme.taskDetailsChecklist.itemIconColor});

    @media (max-width: ${breakpoints.extraSmallMax}px) {
      display: none;
    }
  }

  .c-checklist_items-container {
    border-radius: ${({ theme }) => theme.taskDetailsChecklist.listCornerRadius};
    overflow: hidden;
    box-shadow: ${({ theme }) => theme.taskDetailsChecklist.listShadow};
  }

  .c-checklist_item {
    text-decoration: none;

    &:hover {
      .c-checklist_item-title-task {
        text-decoration: underline;
      }
    }
  }

  .c-checklist_item-title-textfield {
    width: 100%;
    margin-right: 5px;
    border-radius: 4px;
    transition: background-color 0.5s ease-in-out;

    &:hover {
      background-color: rgb(
        ${({ theme }) => theme.taskDetailsChecklist.itemControlHoverBackground}
      );
    }

    .ms-TextField-fieldGroup {
      background-color: transparent;
    }

    .ms-TextField-field {
      padding-left: 2px;
    }
  }

  .c-checklist_item-checkbox {
    position: relative;
    display: flex;
    width: 100%;
    gap: 4px;
  }

  .c-checklist_item-create-task-form {
    margin: 0px 22px 0px 22px;
  }

  .c-checklist_item-create-task-form_date-time {
    border-bottom: 1px solid rgb(${({ theme }) => theme.taskDetailsChecklist.createTaskBorder});

    .ms-TextField-fieldGroup {
      border: none;
      background-color: transparent;

      &:after {
        border-top: none;
        border-right: none;
        border-left: none;
      }
    }
  }

  .c-checklist_item-create-task-form_rte-wrapper {
    width: 100%;

    .rte-toolbar-wrapper {
      background: rgb(${({ theme }) => theme.taskDetailsChecklist.listBackground});
    }
  }

  .c-checklist_item-title-task {
    color: rgb(${({ theme }) => theme.taskDetailsChecklist.itemLinkedTitleForeground});
    cursor: pointer;
    width: 100%;
    padding-top: 1px;

    &:hover {
      text-decoration: underline;
    }
  }

  .c-checklist_new-item-textfield {
    width: 100%;
    padding: 7px 10px 0px 10px;

    .ms-TextField-wrapper {
      padding-bottom: 7px;
    }

    .ms-TextField-prefix {
      background-color: transparent;
      margin-left: 7px;
      padding-right: 5px;
    }

    .c-checklist_new-item-textfield_checkbox {
      background: transparent;
      height: 20px;
      width: 20px;
      border: 1px solid #605e5c;
      border-radius: 50%;
    }

    .ms-TextField-fieldGroup {
      background-color: transparent;
    }

    .ms-TextField-field {
      padding-left: 2px;
    }
  }

  .c-checklist_add-item-button {
    box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
    margin-top: 10px;
    border-radius: 3px;
    width: fit-content;
    overflow: hidden;
  }

  .c-checklist_item-create-task-form_button {
    width: 25%;
    border-radius: 3px;
    transition: background-color 0.3s ease, border 0.3s ease;
  }

  .c-checklist_progress-indicator {
    width: 100%;
    margin: 4px 8px 0px 10px;

    .ms-ProgressIndicator-progressTrack,
    .ms-ProgressIndicator-progressBar {
      border-radius: 5px;
    }

    .ms-ProgressIndicator-progressBar {
      background-image: linear-gradient(
        to right,
        rgb(${({ theme }) => theme.taskDetailsChecklist.progressBarColorStart}),
        rgb(${({ theme }) => theme.taskDetailsChecklist.progressBarColorEnd})
      );
    }
  }

  .c-checklist_new-task-form {
    margin-top: 10px;
    padding-bottom: 15px;
    background-color: rgb(${({ theme }) => theme.taskDetailsChecklist.listBackground});
    border-radius: 5px;
    box-shadow: ${({ theme }) => theme.taskDetailsChecklist.listShadow};
  }

  .c-header {
    display: flex;
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 10px;
    margin-right: 5px;
  }
`;

export const ChecklistInstanceGroupStyled = styled.div`
  margin-bottom: 20px;

  .c-checklist_instance-group-wrapper {
    height: 100%;
    width: 100%;
    background-color: rgb(${({ theme }) => theme.taskDetailsChecklist.instanceGroupBackground});
    border-radius: 5px;
    overflow: hidden;
    box-shadow: ${({ theme }) => theme.taskDetailsChecklist.instanceGroupShadow};
  }

  .c-checklist_instance-group-header {
    font-size: 16px;
    padding: 5px 10px 0px 5px;
    display: flex;
    border-bottom: 1px solid rgb(${({ theme }) => theme.taskDetailsChecklist.instanceGroupDivider});
    justify-content: space-between;
  }

  .c-checklist_item-row-instance {
    padding-left: 8px;
  }

  .c-checklist_item-title-instance {
    color: rgb(${({ theme }) => theme.taskDetailsChecklist.itemLinkedTitleForeground});
    cursor: pointer;
    width: 100%;
    text-decoration: none;
    font-size: 17px;

    &:hover {
      text-decoration: underline;
    }
  }

  .c-checklist_item-secondary-text-instance {
    color: rgb(${({ theme }) => theme.taskDetailsChecklist.itemLinkedTitleForeground});
    cursor: pointer;
    padding-right: 3px;
    width: 100%;
    text-decoration: none;
    font-size: 14px;

    &:hover {
      text-decoration: underline;
    }
  }

  .c-checklist_instance-container {
    height: 100%;
    width: 100%;
    background-color: rgb(${({ theme }) => theme.taskDetailsChecklist.instanceGroupBackground});
    padding: 10px;

    & + .c-checklist_instance-container {
      border-top: 1px solid rgb(${({ theme }) => theme.taskDetailsChecklist.instanceGroupDivider});
    }

    @media (max-width: ${breakpoints.extraSmallMax}px) {
      padding-left: 10px;
    }

    &:hover {
      .c-checklist_item-overflowbutton {
        opacity: 1;
      }
    }

    @media (max-width: ${breakpoints.extraSmallMax}px) {
      .c-checklist_item-overflowbutton {
        opacity: 1;
      }
    }
  }

  .c-checklist_progress-indicator-instance {
    display: grid;

    .c-progress-bar {
      grid-area: 1 / 1;

      .ms-ProgressIndicator-progressTrack,
      .ms-ProgressIndicator-progressBar {
        border-radius: 5px;
      }

      .ms-ProgressIndicator-progressBar {
        background-image: linear-gradient(
          to right,
          rgb(${({ theme }) => theme.taskDetailsChecklist.progressBarColorStart}),
          rgb(${({ theme }) => theme.taskDetailsChecklist.progressBarColorEnd})
        );
      }
    }

    .c-aborted-icon {
      grid-area: 1 / 1;
      margin-top: 1px;
      place-self: center;
      z-index: 100;
      font-size: 18px;
      font-weight: bold;
      color: rgb(${({ theme }) => theme.taskDetailsChecklist.progressBarAbortedIconColor});
    }
  }

  .c-checklist_item-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    > .c-checklist_item {
      width: 0;
      flex: 1;
    }
  }

  .c-checklist_item-overflowbutton {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .c-checklist_item-icon {
    font-size: 20px;
    padding-right: 2px;
    color: #b4b4b4;

    @media (max-width: ${breakpoints.extraSmallMax}px) {
      display: none;
    }
  }
`;

export const InstancesContainerStyled = styled.div`
  width: 100%;
  min-height: 100%;
  padding-top: 15px;

  .c-checklist_instance-group-wrapper {
    box-shadow: none;
    border: 1px solid rgb(${({ theme }) => theme.taskDetailsChecklist.instanceGroupOutline});
  }
`;

export default ChecklistContainerStyled;
