/* eslint-disable jsx-a11y/click-events-have-key-events */

import { TextNode } from 'lexical';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as ReactDOM from 'react-dom';
import { userSearch } from 'services/fetchRequests';
import styled from 'styled-components';
import { IUserProps } from 'types';
import {
  Persona,
  PersonaSize,
  Shimmer,
  ShimmerElementType,
  ShimmerElementsGroup
} from '@fluentui/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  MenuTextMatch,
  useBasicTypeaheadTriggerMatch
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { PersonSuggestion } from '../RichTextEditor.styles';
import { $createMentionNode } from '../components/MentionNode';

const ItemsWrapper = styled.div<{ $bottom?: number }>`
  min-width: fit-content;
  z-index: 9999999;
  position: absolute;
  bottom: ${(props) => props.$bottom}px;

  .dropdown .item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 12px;
    line-height: 16px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .mentions-menu {
    width: 250px;
  }

  .mention:focus {
    box-shadow: rgb(180 213 255) 0px 0px 0px 2px;
    outline: none;
  }

  .typeahead-popover {
    background: rgb(${({ theme }) => theme.richTextEditor.typeaheadPopoverBackground});
    box-shadow: ${({ theme }) => theme.richTextEditor.typeaheadPopoverShadow};
    border-radius: 8px;
    width: auto;
  }

  .typeahead-popover ul {
    padding: 0;
    list-style: none;
    margin: 0;
    border-radius: 8px;
    max-height: 200px;
    overflow-y: scroll;
  }

  .typeahead-popover ul::-webkit-scrollbar {
    display: none;
  }

  .typeahead-popover ul {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .typeahead-popover ul li {
    margin: 0;
    min-width: 180px;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    border-radius: 8px;
  }

  .typeahead-popover ul li.selected {
    background: rgb(${({ theme }) => theme.richTextEditor.typeaheadPopoverItemSelectedBackground});
  }

  .typeahead-popover li {
    margin: 0 8px 0 8px;
    color: rgb(${({ theme }) => theme.richTextEditor.typeaheadPopoverForeground});
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    background: rgb(${({ theme }) => theme.richTextEditor.typeaheadPopoverBackground});
    border-radius: 8px;
    border: 0;
  }

  .typeahead-popover li.active {
    display: flex;
    width: 20px;
    height: 20px;
    background-size: contain;
  }

  .typeahead-popover li:first-child {
    border-radius: 8px 8px 0px 0px;
  }

  .typeahead-popover li:last-child {
    border-radius: 0px 0px 8px 8px;
  }

  .typeahead-popover li:hover {
    background-color: rgb(
      ${({ theme }) => theme.richTextEditor.typeaheadPopoverItemSelectedBackground}
    );
  }

  .typeahead-popover li .text {
    display: flex;
    line-height: 20px;
    flex-grow: 1;
    min-width: 150px;
  }
`;

const PUNCTUATION = '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';
const NAME = `\\b[A-Z][^\\s${PUNCTUATION}]`;

const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION
};

const PUNC = DocumentMentionsRegex.PUNCTUATION;

const TRIGGERS = ['@'].join('');

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = `[^${TRIGGERS}${PUNC}\\s]`;

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  `(?:` +
  `\\.[ |$]|` + // E.g. "r. " in "Mr. Smith"
  ` |` + // E.g. " " in "Josh Duck"
  `[${PUNC}]|` + // E.g. "-' in "Salier-Hellendag"
  `)`;

const LENGTH_LIMIT = 75;

const AtSignMentionsRegex = new RegExp(
  `(^|\\s|\\()([${TRIGGERS}]((?:${VALID_CHARS}${VALID_JOINS}){0,${LENGTH_LIMIT}}))$`
);

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
  `(^|\\s|\\()([${TRIGGERS}]((?:${VALID_CHARS}){0,${ALIAS_LENGTH_LIMIT}}))$`
);

// At most, 5 suggestions are shown in the popup.
const SUGGESTION_LIST_LENGTH_LIMIT = 5;

function getPersons(searchText: string, signal?: AbortSignal) {
  return userSearch({ searchTerm: searchText, type: 6, signal }).then(
    (searchResult) => searchResult.items
  );
}

function useMentionLookupService(mentionString: string | null) {
  const [results, setResults] = useState<Array<IUserProps>>([]);
  const [loading, setLoading] = useState(false);

  const abortController = useRef(new AbortController());
  const debouncedGetPersons = useRef(
    debounce((searchTerm, signal) => {
      setLoading(true);
      getPersons(searchTerm, signal).then((result) => {
        setResults(result || []);
        setLoading(false);
      });
    }, 300)
  ).current;

  useEffect(() => {
    // Abort laufende Requests, wenn neue erfolgt
    abortController.current.abort();
    abortController.current = new AbortController();

    if (mentionString == null) {
      setResults([]);
      return;
    }

    if (mentionString && mentionString?.length) {
      setResults([]);
      debouncedGetPersons(mentionString, abortController.current.signal);
    }
  }, [debouncedGetPersons, mentionString]);

  return { results, loading };
}

function checkForAtSignMentions(text: string, minMatchLength: number): MenuTextMatch | null {
  let match = AtSignMentionsRegex.exec(text);

  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text);
  }

  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2]
      };
    }
  }

  return null;
}

function getPossibleQueryMatch(text: string): MenuTextMatch | null {
  return checkForAtSignMentions(text, 1);
}

class MentionTypeaheadOption extends MenuOption {
  name: string;

  pictureUrl?: string;

  login?: string;

  userId?: string;

  constructor({
    name = '',
    pictureUrl,
    login,
    userId
  }: {
    name?: string;
    pictureUrl?: string;
    login?: string;
    userId?: string;
  }) {
    super(name);
    this.name = name;
    this.pictureUrl = pictureUrl;
    this.login = login;
    this.userId = userId;
  }
}

function MentionsTypeaheadMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: MentionTypeaheadOption;
}) {
  let className = 'item';

  if (isSelected) {
    className += ' selected';
  }

  return (
    <li
      style={{ minWidth: 200 }}
      key={option.key}
      tabIndex={-1}
      className={className}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={`typeahead-item-${index}`}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      {/* {option.picture}
      <span className="text">{option.name}</span> */}
      <PersonSuggestion key={option.userId} isSelected={isSelected}>
        <Persona
          text={option.name || undefined}
          size={PersonaSize.size32}
          imageUrl={option.pictureUrl || undefined}
          secondaryText={option.login}
          showSecondaryText
        />
      </PersonSuggestion>
    </li>
  );
}

export default function NewMentionsPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  const [queryString, setQueryString] = useState<string | null>(null);

  const { results, loading } = useMentionLookupService(queryString);

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0
  });

  const options = useMemo(
    () =>
      results
        ?.map((result) => new MentionTypeaheadOption(result))
        .slice(0, SUGGESTION_LIST_LENGTH_LIMIT) || [],
    [results]
  );

  const onSelectOption = useCallback(
    (
      selectedOption: MentionTypeaheadOption,
      nodeToReplace: TextNode | null,
      closeMenu: () => void
    ) => {
      editor.update(() => {
        const mentionNode = $createMentionNode({
          mentionName: selectedOption.name,
          login: selectedOption.login || '',
          userId: selectedOption.userId || ''
        });

        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }

        mentionNode.select();

        closeMenu();
      });
    },
    [editor]
  );

  const checkForMentionMatch = useCallback(
    (text: string) => {
      const slashMatch = checkForSlashTriggerMatch(text, editor);
      if (slashMatch !== null) {
        return null;
      }
      return getPossibleQueryMatch(text);
    },
    [checkForSlashTriggerMatch, editor]
  );

  function getShimmerPersona() {
    return (
      <div style={{ padding: '3px 10px' }}>
        <Shimmer
          customElementsGroup={
            <div style={{ display: 'flex', width: 200 }}>
              <ShimmerElementsGroup
                shimmerElements={[
                  { type: ShimmerElementType.circle, height: 40 },
                  { type: ShimmerElementType.gap, width: 16, height: 40 }
                ]}
              />
              <ShimmerElementsGroup
                flexWrap
                width="100%"
                shimmerElements={[
                  {
                    type: ShimmerElementType.line,
                    width: '100%',
                    height: 10,
                    verticalAlign: 'bottom'
                  },
                  { type: ShimmerElementType.line, width: '90%', height: 8 },
                  { type: ShimmerElementType.gap, width: '10%', height: 20 }
                ]}
              />
            </div>
          }
        />
      </div>
    );
  }

  function renderMenu(
    anchorElementRef: React.RefObject<HTMLElement>,
    {
      selectedIndex,
      selectOptionAndCleanUp,
      setHighlightedIndex
    }: {
      selectedIndex: number | null;
      selectOptionAndCleanUp: (option: MentionTypeaheadOption) => void;
      setHighlightedIndex: (index: number) => void;
    }
  ) {
    if (!anchorElementRef.current) {
      return null;
    }

    // diff is the distance from the bottom of the menu to the bottom of the window
    const diff = window.innerHeight - anchorElementRef.current.getBoundingClientRect().bottom;
    const bottom = diff < 70 ? 35 : undefined;

    if (loading) {
      return ReactDOM.createPortal(
        <ItemsWrapper $bottom={bottom}>
          <div className="typeahead-popover mentions-menu">
            <div>{getShimmerPersona()}</div>
            <div>{getShimmerPersona()}</div>
          </div>
        </ItemsWrapper>,
        anchorElementRef.current
      );
    }

    if (results?.length) {
      return ReactDOM.createPortal(
        <ItemsWrapper $bottom={bottom}>
          <div className="typeahead-popover mentions-menu">
            <ul>
              {options?.map((option, i: number) => (
                <MentionsTypeaheadMenuItem
                  index={i}
                  isSelected={selectedIndex === i}
                  onClick={() => {
                    setHighlightedIndex(i);
                    selectOptionAndCleanUp(option);
                  }}
                  onMouseEnter={() => {
                    setHighlightedIndex(i);
                  }}
                  key={option.key}
                  option={option}
                />
              ))}
            </ul>
          </div>
        </ItemsWrapper>,
        anchorElementRef.current
      );
    }

    return null;
  }

  return (
    <LexicalTypeaheadMenuPlugin<MentionTypeaheadOption>
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForMentionMatch}
      options={options}
      menuRenderFn={renderMenu}
    />
  );
}
