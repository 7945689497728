import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { ITagProps } from 'types';
import {
  ITagPickerProps as IFluentTagPickerProps,
  ITag,
  Icon,
  IconButton,
  Label,
  Stack,
  TagPicker,
  TooltipHost,
  IBasePicker
} from '@fluentui/react';

const SuggestionItemStyled = styled.div<{ $disabled?: boolean }>`
  padding: 7px;
  width: 100%;
  height: 100%;
  text-align: start;
  color: ${({ $disabled, theme }) =>
    $disabled ? `rgb(${theme.tagPicker.suggestionDisabledForeground})` : null};

  &:hover {
    cursor: ${(props) => (props.$disabled ? 'default' : null)};
  }

  .create-tag {
    padding-right: 5px;
    padding-left: 2px;
    color: rgb(${({ theme }) => theme.tagPicker.suggestionCreateForeground});
  }

  .tag-fullText {
    color: rgb(${({ theme }) => theme.tagPicker.suggestionTagForeground});
    font-weight: 400;
    font-size: 12px;
    text-align: left;
  }
`;

const DisabledTagStyled = styled.div`
  background-color: rgb(${({ theme }) => theme.tagPicker.tagBackground});
  box-shadow: inset 0 0 0 1px rgb(${({ theme }) => theme.tagPicker.tagOutline});
  margin: 3px;
  padding: 4px 9px;
  border-radius: 3px;

  :hover {
    background-color: rgb(${({ theme }) => theme.tagPicker.tagHoverBackground});
    color: rgb(${({ theme }) => theme.tagPicker.tagHoverForeground});
    cursor: pointer;
  }
`;

const TagStyled = styled.div`
  background-color: rgb(${({ theme }) => theme.tagPicker.tagBackground});
  box-shadow: inset 0 0 0 1px rgb(${({ theme }) => theme.tagPicker.tagOutline});

  align-items: center;
  display: flex;
  margin: 3px;
  padding: 2px 3px 2px 7px;
  border-radius: 3px;

  :hover {
    .c-delete-tag-icon {
      color: inherit;
    }

    background-color: rgb(${({ theme }) => theme.tagPicker.tagHoverBackground});
    color: rgb(${({ theme }) => theme.tagPicker.tagHoverForeground});
    cursor: pointer;
  }
`;

export interface ITagSearchProps {
  searchTerm: string;
  onlyParents: boolean;
  showDeleted: boolean;
  parentId?: string;
}

export interface ITagPickerProps {
  selectedTags?: ITagProps[];
  disabled: boolean;
  onTagClick?: (id: string) => void;
  onInputChange?: (text: string) => void;
  itemLimit?: number;
  onChange: (tags: ITagProps[]) => void;
  styles?: IFluentTagPickerProps['styles'];
  label?: string;
  onTagsSearch: ({
    searchTerm,
    onlyParents,
    parentId,
    showDeleted
  }: ITagSearchProps) => Promise<ITagProps[]>;
  defaultValue?: ITagProps[];
  placeHolder?: string;
  allowNewTags?: boolean;
  iconName?: string;
}

export interface ICombinedTagProps extends Omit<ITagProps, 'key' | 'name'> {
  name: string;
  key: number | string;
}

function TagPickerCustom({
  selectedTags = [],
  disabled,
  onChange,
  label,
  styles,
  onTagsSearch,
  itemLimit = 20,
  onTagClick,
  iconName,
  onInputChange: handleInputChange,
  allowNewTags = true,
  defaultValue,
  placeHolder
}: ITagPickerProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const defaultEmptyParentTag: ITagProps = { id: undefined, text: undefined };
  const [parentTag, setParentTag] = useState(defaultEmptyParentTag);

  const defaultNoResultsFoundText = t('tagPicker.suggestions.noResult');
  const [noResultsFoundText, setNoResultsFoundText] = useState(defaultNoResultsFoundText);

  const pickerComponentRef = useRef<IBasePicker<ITag>>(null);

  const resultsMaximumNumber = 8;

  function compareStrings(a: string, b: string): boolean {
    if (a && b) {
      let string1 = a.trim().toLowerCase();
      let string2 = b.trim().toLowerCase();

      // remove hashtag sign
      string1 = getTagText(string1, true);
      string2 = getTagText(string2, true);

      return string1 === string2;
    }

    return false;
  }

  function removeSelectedTags(tagsArray: ICombinedTagProps[]): ICombinedTagProps[] {
    if (tagsArray?.length && selectedTags?.length) {
      return tagsArray.filter(
        (tag) =>
          selectedTags.findIndex((sTag) => {
            if (sTag.id && tag.id) {
              // check for ids
              return sTag.id === tag.id;
            }

            // else compare text for new tags
            if (sTag.text && tag.text) {
              return compareStrings(sTag.text, tag.text);
            }

            return false;
          }) === -1
      );
    }

    return tagsArray;
  }

  function findTagByText(tagsArray: ICombinedTagProps[], tagText: string) {
    if (tagsArray && tagText) {
      return tagsArray.find((tag) => compareStrings(tag.text || '', tagText));
    }

    return null;
  }

  function getFilterTextParts(filterText: string) {
    const filterTextParts = {
      parentFilterText: '',
      childFilterText: '',
      hasDotSeperator: false
    };

    if (filterText && filterText !== '#') {
      const splittedFilterText = filterText.split('.');

      // eslint-disable-next-line prefer-destructuring
      filterTextParts.parentFilterText = splittedFilterText[0];
      // eslint-disable-next-line prefer-destructuring
      filterTextParts.childFilterText = splittedFilterText[1];
      filterTextParts.hasDotSeperator = filterText.includes('.');

      return filterTextParts;
    }

    return filterTextParts;
  }

  function removeParents(tags: ICombinedTagProps[]) {
    return tags.filter((tag) => !tag.children?.length);
  }

  async function onResolveSuggestions(filterText: string): Promise<ICombinedTagProps[]> {
    const { parentFilterText, childFilterText, hasDotSeperator } = getFilterTextParts(filterText);

    if (parentFilterText) {
      // get results
      let tagTextToSearch = parentTag.id && childFilterText ? childFilterText : parentFilterText;
      tagTextToSearch = getTagText(tagTextToSearch);

      let results: ICombinedTagProps[] = await onTagsSearch({
        searchTerm: tagTextToSearch,
        onlyParents: false,
        showDeleted: true
      }).then((tags) => {
        return tags.map(convertITagPropsToFluentTagProps);
      });

      const foundExistingTag = findTagByText(results, parentFilterText);

      if (hasDotSeperator && foundExistingTag && !childFilterText) {
        if (!parentTag.id) {
          setParentTag({ id: foundExistingTag.id, text: foundExistingTag.text });
        }
      } else if (!hasDotSeperator) {
        setParentTag(defaultEmptyParentTag);
      }

      // add name attribute for fabric tag component
      results = results.map((result) => ({ ...result, name: result.fullText || '' }));

      // remove already selected items
      results = removeSelectedTags(results);

      const doesTagAlreadyExist = !!findTagByText(results, filterText);
      const isTagAlreadySelected = !!findTagByText(
        selectedTags?.map((t) => ({ ...t, key: t.id || t.text || '', name: t.text || '' })) || [],
        filterText
      );
      const isTagDisabled = foundExistingTag?.isDeleted;

      const showCreateNewOption =
        !isTagDisabled && !doesTagAlreadyExist && !isTagAlreadySelected && !hasDotSeperator;

      results = removeParents(results);
      // results = removeDisabledTags(results);

      if (showCreateNewOption && allowNewTags) {
        // add "create tag" option
        return [
          ...results.slice(0, resultsMaximumNumber - 1),
          {
            createTag: true,
            fullText: filterText,
            text: filterText,
            name: getTagText(filterText),
            key: filterText
          }
        ];
      }

      if (isTagDisabled) {
        setNoResultsFoundText(`${filterText} ${t('tagPicker.disabled.tag')}`);
      } else if (noResultsFoundText !== defaultNoResultsFoundText) {
        setNoResultsFoundText(defaultNoResultsFoundText);
      }

      return results.map((tag) => ({
        ...tag,
        name: getTagText(tag.fullText || ''),
        key: tag.fullText || ''
      }));
    }

    return [];
  }

  function getTextFromItem(tag: ITag): string {
    return getTagText(tag.name || '');
  }

  function onTagPickerChange(tags: ICombinedTagProps[] | undefined) {
    if (tags) {
      setParentTag(defaultEmptyParentTag);

      const convertedTags: ITagProps[] = [];

      if (tags?.length) {
        tags.map((tag) => {
          if (!tag?.isDeleted) {
            convertedTags.push({
              fullText: tag?.fullText,
              id: tag?.id,
              parentId: tag?.parentId,
              text: getTagText(tag?.text || '', true),
              disabled: tag?.disabled
            });
          }

          return null;
        });
      }

      onChange(convertedTags);

      const addedTags = convertedTags.some((tag) => !selectedTags.find((t) => t.id === tag.id));
      if (addedTags) {
        setTimeout(() => {
          // focus input after adding a tag
          pickerComponentRef.current?.focusInput();
        }, 10);
      }
    }
  }

  function onRenderSuggestionsItem(suggestionsProps: ICombinedTagProps): JSX.Element {
    if (suggestionsProps.createTag) {
      return (
        <SuggestionItemStyled>
          <div className="flex">
            <div className="create-tag">{t('tagPicker.create.tag')}</div>
            <div>{getTagText(suggestionsProps.text || '')}</div>
          </div>
        </SuggestionItemStyled>
      );
    }

    if (suggestionsProps.isDeleted) {
      return (
        <TooltipHost
          delay={0}
          tooltipProps={{ onRenderContent: () => t('tagPicker.tooltip.suggestionTag') }}
          id={`${suggestionsProps.id}-tooltip`}
          calloutProps={{ gapSpace: 5 }}
          key={suggestionsProps.text}
          styles={{ root: { width: '100%' } }}
        >
          <SuggestionItemStyled $disabled={suggestionsProps.isDeleted}>
            {getTagText(suggestionsProps.fullText || '')}
          </SuggestionItemStyled>
        </TooltipHost>
      );
    }

    return (
      <SuggestionItemStyled $disabled={suggestionsProps.isDeleted}>
        {getTagText(suggestionsProps.fullText || '')}
      </SuggestionItemStyled>
    );
  }

  function handleTagClick({
    event,
    selectedTag
  }: {
    event?: React.MouseEvent<HTMLDivElement, MouseEvent>;
    selectedTag: ICombinedTagProps;
  }) {
    let tagId = selectedTag.id;

    if (!tagId && selectedTags?.length) {
      // in case tag is new, find the tag by text
      const tag = selectedTags.find((t) => t.text === selectedTag.text);

      if (tag?.id) {
        tagId = tag.id;
      }
    }

    if (onTagClick && tagId) {
      event?.stopPropagation();
      onTagClick(tagId);
    } else if (tagId) {
      navigate(`/tags/${tagId}`);
    }
  }

  const onRenderItem = (itemProps: {
    id?: string;
    item: ICombinedTagProps;
    onRemoveItem?: () => void;
  }) => {
    if (itemProps?.item?.text && !itemProps?.item?.isDeleted) {
      if (itemProps.item.disabled) {
        return (
          <TooltipHost
            delay={0}
            tooltipProps={{ onRenderContent: () => t('tagPicker.tooltip.instanceTag') }}
            id={`${itemProps.id || ''}-tooltip`}
            calloutProps={{ gapSpace: 5 }}
            key={itemProps.item.text}
          >
            <DisabledTagStyled
              onClick={(event) => {
                handleTagClick({ event, selectedTag: itemProps.item });
              }}
              style={{ margin: '3px', padding: '3px 9px' }}
            >
              {getTagText(itemProps.item.fullText || itemProps.item.text)}
            </DisabledTagStyled>
          </TooltipHost>
        );
      }

      return (
        <TagStyled
          key={`tag-${itemProps.item.text}`}
          onClick={(event) => {
            handleTagClick({ event, selectedTag: itemProps.item });
          }}
        >
          <div style={{ marginRight: 3, paddingBottom: 1 }}>
            {getTagText(itemProps.item.fullText || itemProps.item.text)}
          </div>
          <IconButton
            iconProps={{
              className: 'c-delete-tag-icon',
              iconName: 'Cancel',
              styles: {
                root: { color: `rgb(${theme.tagPicker.tagRemoveButtonForeground})`, fontSize: 12 }
              }
            }}
            styles={{
              root: { height: 22 },
              rootHovered: { height: 22 }
            }}
            onClick={(ev) => {
              ev.stopPropagation();
              if (itemProps.onRemoveItem) {
                itemProps.onRemoveItem();
              }
            }}
          />
        </TagStyled>
      );
    }

    return <div />;
  };

  function getDefaultSelectedItems(): ITag[] {
    if (defaultValue && defaultValue.length) {
      const selectedItems: ITagProps[] = [];

      defaultValue.map((tag) => {
        if (!tag.isDeleted) {
          selectedItems.push({
            ...tag,
            name: getTagText(tag.fullText || ''),
            key: tag.text,
            disabled: tag.disabled
          });
        }

        return null;
      });

      return selectedItems.map(convertITagPropsToFluentTagProps);
    }

    return [];
  }

  function getTagText(tagText: string, remove = false) {
    // remove or add the hashTag sign (#)
    if (tagText) {
      const hasHashTagSign = tagText.substring(0, 1) === '#';

      if (!remove && !hasHashTagSign) {
        // add hashTag sign
        return `#${tagText}`;
      }

      if (remove && hasHashTagSign && tagText.length > 1) {
        // remove hashTag sign
        return tagText.replace('#', '');
      }

      return tagText;
    }

    return tagText;
  }

  function convertITagPropsToFluentTagProps(givenTagProps: ITagProps): ICombinedTagProps {
    return { ...givenTagProps, name: givenTagProps.name || '', key: givenTagProps.key || '' };
  }

  function onInputChange(text: string) {
    if (handleInputChange) {
      handleInputChange(text);
    }

    // allow only alphanumeric & child-tag seperator (one dot in between)
    const regex = /(#)?([0-9a-zA-ZZäöüÄÖÜß]*(\.([0-9a-zA-ZZäöüÄÖÜß]*))?)/gm;

    // find regex matches in text
    const matchedRegex = text.match(regex);

    if (matchedRegex) {
      const matchedRegexText = matchedRegex[0];

      return matchedRegexText;
    }

    return '';
  }

  function renderStackTagIcon() {
    return (
      <Stack.Item>
        <Icon
          title={tagLabelText}
          iconName={iconName || 'Tag'}
          styles={{
            root: { marginTop: 6, fontSize: 21, color: `rgb(${theme.tagPicker.tagIconColor})` }
          }}
        />
      </Stack.Item>
    );
  }

  const tagLabelText = t('tagPicker.label.tags');

  if (disabled && defaultValue && defaultValue.length) {
    return (
      <>
        {label && (
          <div
            style={{ paddingBottom: '2px' }}
            className="ms-Label ms-font-s ms-fontColor-neutralSecondary"
          >
            {label}
          </div>
        )}
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          {renderStackTagIcon()}
          <Stack.Item grow>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {defaultValue.map((tag) => (
                <DisabledTagStyled
                  onClick={() => {
                    if (onTagClick && tag.id) {
                      onTagClick(tag.id);
                    } else if (tag.id) {
                      navigate(`/tags/${tag.id}`);
                    }
                  }}
                  key={tag.text}
                >
                  {getTagText(tag.fullText || '')}
                </DisabledTagStyled>
              ))}
            </div>
          </Stack.Item>
        </Stack>
      </>
    );
  }

  if (!disabled) {
    return (
      <div>
        {label && (
          <Label
            styles={{
              root: {
                padding: 0,
                fontSize: '13px',
                fontWeight: 400,
                color: `rgb(${theme.tagPicker.labelForeground})`
              }
            }}
          >
            {label}
          </Label>
        )}
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          {renderStackTagIcon()}
          <Stack.Item grow>
            <TagPicker
              componentRef={pickerComponentRef}
              key={`{tagPicker-${defaultValue?.length || 0}}`}
              defaultSelectedItems={getDefaultSelectedItems()}
              onRenderSuggestionsItem={onRenderSuggestionsItem}
              onResolveSuggestions={onResolveSuggestions}
              getTextFromItem={getTextFromItem}
              pickerSuggestionsProps={{
                suggestionsHeaderText: t('tagPicker.suggestions.header'),
                noResultsFoundText
              }}
              onRenderItem={onRenderItem}
              styles={
                styles || {
                  root: {
                    borderRadius: 3,
                    transition: 'background-color 500ms',
                    cursor: 'pointer',
                    ':hover': { backgroundColor: `rgb(${theme.tagPicker.hoverBackground})` },
                    border: 'none'
                  },
                  input: {
                    paddingLeft: 5,
                    cursor: 'pointer',
                    ':focus': { cursor: 'text' },
                    '::placeholder': { color: `rgb(${theme.tagPicker.placeholderForeground})` }
                  },
                  text: {
                    border: 'none',
                    borderRadius: '3px',
                    selectors: { ':after': { border: 'none' } }
                  }
                }
              }
              inputProps={{
                placeholder: placeHolder || t('taskDetailsBody.tagPicker.placeholder')
              }}
              itemLimit={itemLimit}
              disabled={false}
              resolveDelay={100}
              onChange={onTagPickerChange}
              onInputChange={onInputChange}
            />
          </Stack.Item>
        </Stack>
      </div>
    );
  }

  return null;
}

export default TagPickerCustom;
