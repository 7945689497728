import { LoadingSpinner } from 'components';
import { AppContext } from 'features/App';
import debounce from 'lodash/debounce';
import { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import { ITagProps } from 'types';
import breakpoints from 'utils/breakpoints';
import { checkScreenWidth } from 'utils/helpers';
import {
  Callout,
  DirectionalHint,
  SearchBox as FluentSearchBox,
  ISearchBox,
  IconButton
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';

const SearchBoxContainerStyled = styled.div`
  max-width: 300px;
  width: 100%;
  margin: 0px 10px;

  .searchBox {
    border-radius: 5px;
    border: none;
    overflow: hidden;

    width: 100%;
    background: rgb(${({ theme }) => theme.header.searchBackground} / 0.6);
    color: rgb(${({ theme }) => theme.header.searchForeground});

    &:after {
      display: none;
    }

    input {
      color: inherit;

      &::placeholder {
        color: rgb(${({ theme }) => theme.header.searchForeground} / 0.6);
      }
    }

    .ms-SearchBox-iconContainer {
      color: rgb(${({ theme }) => theme.header.background});
    }

    &:hover .ms-SearchBox-iconContainer {
      color: color-mix(
        in lch,
        rgb(${({ theme }) => theme.header.background}),
        rgb(${({ theme }) => theme.header.foreground}) 30%
      );
    }
  }

  &.is-teams-style .searchBox {
    border: 1px solid rgb(${({ theme }) => theme.headerTeams.searchOutline});
    background: rgb(${({ theme }) => theme.headerTeams.searchBackground});
    color: rgb(${({ theme }) => theme.headerTeams.searchForeground});

    input::placeholder {
      color: rgb(${({ theme }) => theme.headerTeams.searchForeground} / 0.55);
    }
  }

  @media (max-width: ${breakpoints.extraSmallMax}px) {
    margin: 0 50px;
    max-width: calc(100% - 105px);
    position: absolute;
    z-index: 10;

    .searchBox {
      background: rgb(${({ theme }) => theme.header.searchBackground});
      height: 36px;
    }

    &.is-teams-style .searchBox {
      background: rgb(${({ theme }) => theme.headerTeams.searchBackground});
    }
  }

  .ms-Button .ms-Button-icon {
    color: rgb(${({ theme }) => theme.header.searchForeground});
  }
  .ms-Button:hover {
    background: rgb(${({ theme }) => theme.header.searchBackground} / 0.6);
  }

  &.is-teams-style {
    .ms-Button .ms-Button-icon {
      color: rgb(${({ theme }) => theme.headerTeams.searchForeground});
    }
    .ms-Button:hover {
      background: rgb(${({ theme }) => theme.headerTeams.searchForeground} / 0.1);
    }
  }
`;

const CalloutContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  .suggestions-title {
    padding: 0 10px;
    line-height: 35px;
    font-size: 12px;
    border-bottom: 1px solid rgb(${({ theme }) => theme.header.searchSuggestionItemDivider});
    box-shadow: 0 0 3.6px rgb(0 0 0 / 13%), 0 0 0.9px rgb(0 0 0 / 10%);
  }

  .suggestion-item {
    padding: 0 10px;
    line-height: 35px;
    font-size: 13px;
    border-bottom: 1px solid rgb(${({ theme }) => theme.header.searchSuggestionItemDivider});

    &:hover {
      cursor: pointer;
      background: rgb(${({ theme }) => theme.header.searchSuggestionItemHoverBackground});
      color: rgb(${({ theme }) => theme.header.searchSuggestionItemHoverForeground});
    }
  }

  .suggestions-loading {
    padding: 0 10px;
    height: 45px;
    display: flex;
    align-items: start;
  }

  .suggestions-no-results {
    font-size: 12px;
    text-align: center;
    line-height: 30px;
  }

  .suggestions-wrapper {
    max-height: 300px;
    overflow-y: auto;
  }
`;

const CalloutStyled = styled(Callout)`
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

const IconButtonStyled = styled(IconButton)<{ searchActive: boolean }>`
  min-width: 50px;
  min-height: 45px;

  .ms-Button-icon {
    color: rgb(${({ theme }) => theme.header.foreground});
  }

  background: ${({ theme, searchActive }) =>
    searchActive ? `rgb(${theme.header.foreground} / 0.6)` : 'transparent'};

  .ms-Button-icon {
    color: rgb(
      ${({ theme, searchActive }) =>
        searchActive ? theme.header.background : theme.header.foreground}
    );
  }

  &:hover {
    background: rgb(${({ theme }) => theme.header.foreground} / 0.25);
    color: inherit;
  }
`;

function SearchBox({ teamsStyle }: { teamsStyle?: boolean }) {
  const { t } = useTranslation();
  const searchBoxId = useId('searchBox-');

  const navigate = useNavigate();

  const [searchTerm, setsearchTerm] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const searchBoxRef = useRef<HTMLDivElement>(null);
  const searchBoxComponentRef = useRef<ISearchBox>(null);

  const [displayBox, setDisplayBox] = useState(!checkScreenWidth(['extraSmall']));

  const [isCalloutVisible, setIsCalloutVisible] = useState(false);

  const [searchSuggestions, setSearchSuggestions] = useState<ITagProps[] | null>(null);

  const { globalAppState, dispatch } = useContext(AppContext);
  const globalAppStateSearchTerm = globalAppState.searchTerm;

  const [searchBoxKey, setSearchBoxKey] = useState(globalAppStateSearchTerm || 1);

  useEffect(() => {
    if (!globalAppStateSearchTerm) {
      setsearchTerm(null);
    }

    setSearchBoxKey(globalAppStateSearchTerm || 1);
  }, [globalAppStateSearchTerm]);

  const onSearch = useCallback(
    (seachTerm: string | null) => {
      dispatch({ type: 'searchTerm', data: seachTerm });
    },
    [dispatch]
  );

  useEffect(() => {
    if (searchTerm?.startsWith('#')) {
      setIsCalloutVisible(true);
    }
  }, [searchTerm]);

  const onSearchBoxChange = debounce((value) => {
    setsearchTerm(() => {
      if (!value || value === '') {
        return null;
      }

      return value;
    });
  }, 500);

  const getTagSearchResults = useCallback(async () => {
    const url = `tags?showDeleted=true&searchTerms=${encodeURIComponent(searchTerm as string)}`;

    let tags = (await fetchRequest({
      url,
      method: undefined,
      origin: undefined,
      body: undefined,
      ignoreContentType: undefined,
      signal: undefined,
      ignoreAlert: undefined,
      ignoreConsent: undefined,
      retries: undefined,
      anonymousCall: undefined
    }).catch(apiErrorHandler)) as ITagProps[];

    if (tags?.length) {
      tags = tags.filter((tag) => !tag.children?.length);
      tags = tags.filter((tag) => !tag.isDeleted);
    }

    return tags;
  }, [searchTerm]);

  useEffect(() => {
    if (searchTerm) {
      if (searchTerm.startsWith('#')) {
        setIsLoading(true);

        getTagSearchResults().then((tags) => {
          setSearchSuggestions(tags);
          setIsCalloutVisible(true);
          setIsLoading(false);
        });
      } else {
        const debugCommand = getDebugCommand(searchTerm);
        if (debugCommand) {
          setIsCalloutVisible(true);
          setIsLoading(false);
        }
      }
    }
  }, [getTagSearchResults, onSearch, searchTerm]);

  function onSuggestionClick(tag: ITagProps) {
    setIsCalloutVisible(false);
    setSearchSuggestions(null);
    setsearchTerm(null);

    setSearchBoxKey(Math.random());

    if (checkScreenWidth(['small', 'extraSmall'])) {
      setDisplayBox(false);
    }

    navigate(`/tags/${tag.id}`);
  }

  function resetSearch() {
    setIsCalloutVisible(false);
    setSearchSuggestions(null);
    onSearch(null);
  }

  function onSearchCommit(value: string | null) {
    const debugCommand = value ? getDebugCommand(value) : null;
    if (debugCommand) {
      debugCommand.run();
      resetSearch();
      return;
    }

    if (value && !value.startsWith('#')) {
      setIsCalloutVisible(false);
      setSearchSuggestions(null);
      onSearch(value);

      if (checkScreenWidth(['small', 'extraSmall'])) {
        setDisplayBox(false);
      }
    }

    if (!value) {
      resetSearch();
    }
  }

  function onIconButtonClick() {
    setDisplayBox(true);

    if (!checkScreenWidth(['extraSmall'])) {
      // if not mobile, focus search box after 100ms
      setTimeout(() => {
        searchBoxComponentRef.current?.focus();
      }, 100);
    }
  }

  function onSearchBoxBlur() {
    if (!searchTerm && checkScreenWidth(['small', 'extraSmall'])) {
      setDisplayBox(false);
    }
  }

  function getCalloutContent() {
    const width = searchBoxRef?.current?.offsetWidth;

    const debugCommand = getDebugCommand(searchTerm);
    if (debugCommand) {
      return (
        <CalloutContentContainer style={{ width }}>{debugCommand.label}</CalloutContentContainer>
      );
    }

    if (!searchSuggestions?.length && isLoading) {
      return (
        <CalloutContentContainer style={{ width }}>
          <div className="suggestions-loading">
            <LoadingSpinner
              size={2}
              label={t('header.searchBox.suggestions.loading')}
              labelPosition="right"
            />
          </div>
        </CalloutContentContainer>
      );
    }

    if (!searchSuggestions?.length) {
      return (
        <CalloutContentContainer style={{ width }}>
          <div className="suggestions-no-results">
            {t('header.searchBox.suggestions.noResults')}
          </div>
        </CalloutContentContainer>
      );
    }

    return (
      <CalloutContentContainer style={{ width }}>
        <div className="suggestions-title">{t('header.searchBox.suggestions.title')}</div>
        <div className="suggestions-wrapper">
          {searchSuggestions?.map((suggestion) => {
            return (
              <div
                className="suggestion-item"
                role="presentation"
                key={`suggestion-${suggestion.id}`}
                onClick={() => onSuggestionClick(suggestion)}
              >
                {`#${suggestion.fullText}`}
              </div>
            );
          })}
        </div>
      </CalloutContentContainer>
    );
  }

  if (!displayBox) {
    return (
      <IconButtonStyled
        searchActive={!!globalAppStateSearchTerm}
        className={`searchBox-iconButton ${globalAppStateSearchTerm ? 'search-active' : ''}`}
        iconProps={{ iconName: 'Search' }}
        onClick={onIconButtonClick}
      />
    );
  }

  return (
    <SearchBoxContainerStyled className={teamsStyle ? 'is-teams-style' : ''}>
      <div id={searchBoxId}>
        <FluentSearchBox
          id={`${searchBoxId}-input`}
          key={searchBoxKey}
          ref={searchBoxRef}
          autoFocus={checkScreenWidth(['extraSmall'])}
          componentRef={searchBoxComponentRef}
          onBlur={onSearchBoxBlur}
          className="searchBox"
          defaultValue={globalAppStateSearchTerm || undefined}
          placeholder={t('searchBar.placeholder')}
          autoComplete="off"
          onClear={() => setsearchTerm(null)}
          onChange={(_, value) => {
            onSearchBoxChange(value);

            if (!value || value === '') {
              onSearchCommit(null);
            }
          }}
          onSearch={onSearchCommit}
        />
      </div>
      {isCalloutVisible && (
        <CalloutStyled
          gapSpace={3}
          target={`#${searchBoxId}`}
          onDismiss={() => setIsCalloutVisible(false)}
          setInitialFocus
          isBeakVisible={false}
          directionalHint={DirectionalHint.bottomLeftEdge}
        >
          {getCalloutContent()}
        </CalloutStyled>
      )}
    </SearchBoxContainerStyled>
  );
}

export default SearchBox;

/**
 * Debug commands that can be executed by typing special strings into the global search box
 */
function getDebugCommand(value: string): { label: ReactNode; run: () => void } | null {
  if (!value?.startsWith('/')) return null;
  const argv = [];

  let inString = false;
  let escape = false;
  let buffer = '';
  for (const ch of value) {
    if (escape) {
      escape = false;
      buffer += ch;
      continue;
    }

    if (inString) {
      if (ch === '"') {
        inString = false;
      } else {
        buffer += ch;
      }
    } else if (ch.match(/\s/)) {
      if (buffer) argv.push(buffer);
      buffer = '';
    } else if (ch === '"') {
      inString = true;
    } else if (ch === '\\') {
      escape = true;
    } else {
      buffer += ch;
    }
  }
  if (buffer) argv.push(buffer);

  const [command, ...args] = argv;
  switch (command) {
    case '/evocomDebug:flags': {
      const flags: ReactNode[] = [];
      for (let i = 0; i < localStorage.length; i += 1) {
        const key = localStorage.key(i);
        if (!key.startsWith('DEBUG_')) continue;
        const value = localStorage.getItem(key);
        flags.push(
          <li key={key}>
            <code>{key}</code> = <code>{JSON.stringify(value)}</code>
          </li>
        );
      }
      if (!flags.length) flags.push(<li key="0">empty</li>);

      return {
        label: <ul>{flags}</ul>,
        run: () => null
      };
    }
    case '/evocomDebug:setFlag': {
      const [flag, value] = args;
      if (!flag?.match(/^\w+$/)) return null;
      return {
        label: (
          <div>
            set flag: <code>DEBUG_{flag}</code> ={' '}
            <code>{value ? JSON.stringify(value) : 'null'}</code>
          </div>
        ),
        run: () => {
          if (value) {
            localStorage.setItem(`DEBUG_${flag}`, value);
          } else {
            localStorage.removeItem(`DEBUG_${flag}`);
          }
        }
      };
    }
    default:
      return null;
  }
}
