import {
  ApiObjectVariant,
  createApiObjectDataFromPlainObject,
  IApiObjectCache,
  IApiObjectData,
  IApiObjectType,
  makeApiObjectVariantKey
} from '../object';
import { DateTime, IApiObjectArray, Ref, Uuid, VoidParams } from './base-types';
import { ITeam, IUser, TEAM, USER } from './users';
import { simpleIdObject } from './utils';
import { ApiError, ApiErrorType, fetchJson } from '../../../services/api2';

export interface IPageItem extends IApiObjectData {
  readonly id: Uuid;
  name: string | null;
  description: string | null;
  readonly creationDate: DateTime;
  creator: Ref<IUser>;
  readonly editDate: DateTime;
  editor: Ref<IUser>;
  url: string | null;
  assignedTeams: Ref<ITeam>[];
  locations: number[] | null;
}

export const PAGE: IApiObjectType<Uuid, IPageItem> = simpleIdObject({
  id: 'Page',
  useTenant: true,
  url: (id) => `Pages/${id}`,
  fields: [
    { path: 'creator', type: USER, params: (data) => data.creator?.userId },
    { path: 'editor', type: USER, params: (data) => data.editor?.userId }
  ],
  dynFields: (data) =>
    data.assignedTeams?.map((team, index) => ({
      path: `assignedTeams/${index}`,
      type: TEAM,
      variant: ApiObjectVariant.Small,
      params: () => team.id
    })) ?? [],
  loadDedupIntervalSecs: 600
});

// Pages have different data in administration, so we should treat them as a different kind of object
export const ADMIN_PAGE: IApiObjectType<Uuid, IPageItem> = {
  id: 'administration/Page',
  createRefs(_: IApiObjectCache, data: IPageItem) {
    return [
      { path: 'creator', type: USER, params: () => data.creator?.userId },
      { path: 'editor', type: USER, params: () => data.editor?.userId },
      ...(data.assignedTeams?.map((team, index) => ({
        path: `assignedTeams/${index}`,
        type: TEAM,
        variant: ApiObjectVariant.Small,
        params: () => team.id
      })) ?? [])
    ];
  },
  async load(cache: IApiObjectCache, params: Uuid, abort: AbortSignal): Promise<IPageItem> {
    const adminPages = await ADMINISTRATION_PAGES.load(cache, {}, abort);
    const page = adminPages.items.find((item) => item.id === params);
    if (!page) {
      throw new ApiError(ApiErrorType.NotFound, 404, 'Not found', {
        type: 'about:blank',
        title: 'Not found',
        status: 404
      });
    }
    return page;
  },
  loadDedupIntervalSecs: 600
};

export const PAGES: IApiObjectType<VoidParams, IApiObjectArray<IPageItem>> = {
  id: 'Pages',
  createRefs(_: IApiObjectCache, data: IApiObjectArray<IPageItem>) {
    return data.items.map((item, index) => ({
      path: `items/${index}`,
      type: PAGE,
      params: () => item.id
    }));
  },
  async load(
    cache: IApiObjectCache,
    params: VoidParams,
    abort: AbortSignal
  ): Promise<IApiObjectArray<IPageItem>> {
    const result = await fetchJson({ url: `tenants/${cache.tenantId}/Pages`, abort });
    return createApiObjectDataFromPlainObject(cache, makeApiObjectVariantKey(PAGES, params), {
      items: result
    }) as IApiObjectArray<IPageItem>;
  }
};

export const ADMINISTRATION_PAGES: IApiObjectType<VoidParams, IApiObjectArray<IPageItem>> = {
  id: 'administration/Pages',
  createRefs(_: IApiObjectCache, data: IApiObjectArray<IPageItem>) {
    return data.items.map((item, index) => ({
      path: `items/${index}`,
      type: ADMIN_PAGE,
      params: () => item.id
    }));
  },
  async load(
    cache: IApiObjectCache,
    params: VoidParams,
    abort: AbortSignal
  ): Promise<IApiObjectArray<IPageItem>> {
    const result = await fetchJson({
      url: `tenants/${cache.tenantId}/administration/Pages`,
      abort
    });
    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(ADMINISTRATION_PAGES, params),
      { items: result }
    ) as IApiObjectArray<IPageItem>;
  }
};
