import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

function PivotDetailBlock({ label, content }) {
  const theme = useTheme();

  return (
    <>
      <div
        className="ms-Label ms-font-s"
        style={{
          color: `rgb(${theme.taskDetails.fieldLabelForeground})`
        }}
      >
        {label}
      </div>
      <div className="ms-font-m">{content}</div>
    </>
  );
}

PivotDetailBlock.propTypes = {
  label: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.any])
};

PivotDetailBlock.defaultProps = {
  label: null,
  content: null
};

export default PivotDetailBlock;
