import React, {
  createContext,
  PureComponent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import styled from 'styled-components';
import { Icon, IconButton, Text } from '@fluentui/react';
import { registerErrorAlertHandler } from 'services/api';
import { ErrorLike, IShowErrorProps, ShowError } from '../../../components/ShowError';

interface INotificationContext {
  showNotification: (message: string, duration?: number) => void;
  showError: (message: ErrorLike, options?: IErrorNotifOptions) => void;
}

export interface IErrorNotifOptions {
  duration?: number;
  domain?: IShowErrorProps['domain'];
  domains?: IShowErrorProps['domains'];
  action?: IShowErrorProps['action'];
}

const NotificationContext = createContext<INotificationContext>({
  showNotification: () => undefined,
  showError: () => undefined
});

export const useNotificationContext = () => {
  return useContext(NotificationContext);
};

const DEFAULT_NOTIFICATION_DURATION_MS = 10000;
const NOTIFICATION_FADE_OUT_TIME_MS = 500;

const NotificationContainerStyled = styled.div`
  position: fixed;
  left: 250px;
  bottom: 0;
  right: 0;
  z-index: calc(infinity);
  pointer-events: none;

  @media (max-width: 768px) {
    left: 0;
  }

  .notification-message {
    position: relative;
    margin: 12px;
    padding: 12px;
    display: flex;
    gap: 12px;
    border-radius: 4px;
    max-width: 500px;
    pointer-events: all;
    background-color: #444;
    color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    opacity: 0.8;
    align-items: center;
    animation: notification-appear 0.5s;
    transition: opacity 0.2s;

    &.is-error {
      background: #f43;
    }
    &.is-fading-out {
      transition: opacity ${NOTIFICATION_FADE_OUT_TIME_MS}ms;
      opacity: 0;
      pointer-events: none;
    }
    &.is-paused {
      opacity: 1;
    }
    &.is-paused-forever {
      animation: notification-lock-in-place 0.4s;
    }

    .inner-icon {
      align-self: center;
      font-size: 20px;
    }
    .inner-message {
      flex: 1;
      color: inherit;
      min-width: 0;

      .inner-error-title {
        font-weight: bold;
      }
      .inner-error-trace-id {
        font-size: smaller;
        opacity: 0.7;
      }
    }
    .close-button {
      flex-shrink: 0;
      opacity: 0.5;
      transition: opacity 0.2s;
    }
    &.is-paused .close-button {
      opacity: 1;
    }

    .expiry-progress-indicator {
      position: absolute;
      height: 2px;
      left: 3px;
      right: 3px;
      border-radius: 1px;
      bottom: 0;
      background: currentColor;
      opacity: 0.5;
      transform-origin: 100% 0;
    }
  }

  @keyframes notification-appear {
    from {
      opacity: 0;
      transform: translateY(24px);
    }
  }
  @keyframes notification-lock-in-place {
    0% {
      animation-timing-function: cubic-bezier(0.2, 0.3, 0.5, 1);
    }
    25% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 1);
      transform: translateY(-8px);
    }
    50% {
      animation-timing-function: cubic-bezier(0, 0, 0.5, 1);
      transform: none;
    }
    75% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 1);
      transform: translateY(-2px);
    }
  }
`;

interface INotificationItem {
  key: string;
  message: string | ErrorLike;
  /** Present only on errors. */
  errOptions: Partial<IShowErrorProps> | null;
  duration: number;
}

export function NotificationProvider({ children }: React.PropsWithChildren) {
  const [notifications, setNotifications] = useState<INotificationItem[]>([]);

  const notificationsStateRef = useRef(setNotifications);
  notificationsStateRef.current = setNotifications;
  useEffect(() => {
    return registerErrorAlertHandler((error) => {
      const setNotifications = notificationsStateRef.current;
      setNotifications((notifications) =>
        notifications.concat([
          {
            key: Math.random().toString(),
            message: error,
            errOptions: {},
            duration: DEFAULT_NOTIFICATION_DURATION_MS
          }
        ])
      );
    });
  }, [notificationsStateRef]);

  const showNotification = useCallback(
    (
      message: string | ErrorLike,
      duration = DEFAULT_NOTIFICATION_DURATION_MS / 1000,
      errOptions = null
    ) => {
      const setNotifications = notificationsStateRef.current;
      setNotifications((notifications) =>
        notifications.concat([
          {
            key: Math.random().toString(),
            message,
            errOptions,
            duration: duration * 1000
          }
        ])
      );
    },
    [notificationsStateRef]
  );
  const showError = useCallback(
    (error: ErrorLike, options?: IErrorNotifOptions) => {
      const errOptions = options ?? {};
      delete errOptions.duration;
      showNotification(error, options?.duration, errOptions);
    },
    [showNotification]
  );

  const providerValue = useMemo(
    () => ({ showNotification, showError }),
    [showNotification, showError]
  );

  const notificationContainer = (
    <NotificationContainerStyled>
      <AnimatedChildYPositions>
        {notifications.map(({ key, message, errOptions, duration }) => ({
          key,
          node: (
            <Notification
              message={message}
              errOptions={errOptions}
              duration={duration}
              onDismiss={() => {
                const index = notifications.findIndex((item) => item.key === key);
                if (index < 0) return;
                const newNotifications = notifications.slice();
                newNotifications.splice(index, 1);
                setNotifications(newNotifications);
              }}
            />
          )
        }))}
      </AnimatedChildYPositions>
    </NotificationContainerStyled>
  );

  return (
    <NotificationContext.Provider value={providerValue}>
      {children}
      {notificationContainer}
    </NotificationContext.Provider>
  );
}

// getSnapshotBeforeUpdate is not supported by hooks yet, so this is a traditional component
class AnimatedChildYPositions extends PureComponent<{
  children: { key: string; node: ReactNode }[];
}> {
  childRefs = new Map<string, { current: HTMLDivElement | null }>();

  getSnapshotBeforeUpdate() {
    const positions = new Map<string, number>();
    for (const [key, ref] of this.childRefs) {
      const node = ref.current;
      if (node) {
        positions.set(key, node.getBoundingClientRect().top);
      }
    }
    return positions;
  }

  componentDidUpdate(_1: unknown, _2: unknown, snapshot?: Map<string, number>) {
    if (snapshot) {
      for (const [key, ref] of this.childRefs) {
        const node = ref.current;
        const oldPos = snapshot.get(key);
        if (node && oldPos !== undefined) {
          const newPos = node.getBoundingClientRect().top;
          node.animate(
            [{ offset: 0, composite: 'add', transform: `translateY(${oldPos - newPos}px)` }],
            { duration: 400, easing: 'cubic-bezier(0.2, 0.4, 0.2, 1)' }
          );
        }
      }
    }
  }

  getChildRef(key: string) {
    if (!this.childRefs.has(key)) this.childRefs.set(key, { current: null });
    return this.childRefs.get(key);
  }

  render() {
    // clean up unused refs
    const childKeys = new Set(this.props.children.map(({ key }) => key));
    for (const k of this.childRefs.keys()) if (!childKeys.has(k)) this.childRefs.delete(k);

    return (
      <>
        {this.props.children.map(({ key, node }) => (
          <div key={key} ref={this.getChildRef(key)}>
            {node}
          </div>
        ))}
      </>
    );
  }
}

function Notification({
  message,
  errOptions,
  duration,
  onDismiss
}: {
  message: ErrorLike;
  errOptions: INotificationItem['errOptions'];
  duration: number;
  onDismiss: () => void;
}) {
  const [isPaused, setPaused] = useState(false);
  const [isPausedForever, setPausedForever] = useState(false);
  const [timeLeft, setTimeLeft] = useState<{ snapshotTime: number; value: number }>({
    snapshotTime: Date.now(),
    value: duration
  });
  const [isFadingOut, setFadingOut] = useState(false);

  const getTimeLeftNow = () => {
    if (isPaused) return timeLeft.value;
    return timeLeft.value - (Date.now() - timeLeft.snapshotTime);
  };
  const timeLeftAtRender = getTimeLeftNow();

  const pause = () => {
    if (isFadingOut) return;
    setPaused(true);
    // store timeLeft for below
    setTimeLeft({ snapshotTime: Date.now(), value: getTimeLeftNow() });
  };
  const unpause = () => {
    if (isPausedForever) return;

    setPaused(false);
    // use the timeLeft value we stored when pausing
    setTimeLeft({ snapshotTime: Date.now(), value: timeLeft.value });
  };
  const pauseForever = () => {
    if (isFadingOut) return;
    pause();
    setPausedForever(true);
  };

  const onDismissRef = useRef(onDismiss);
  onDismissRef.current = onDismiss;
  useEffect(() => {
    if (isPaused) return undefined;

    const timeout = setTimeout(() => {
      onDismissRef.current();
    }, timeLeftAtRender);

    return () => clearTimeout(timeout);
  }, [timeLeftAtRender, isPaused]);

  const setFadingOutRef = useRef(setFadingOut);
  setFadingOutRef.current = setFadingOut;

  useEffect(() => {
    if (isPaused) return undefined;

    const timeout = setTimeout(() => {
      setFadingOutRef.current(true);
    }, timeLeftAtRender - NOTIFICATION_FADE_OUT_TIME_MS);

    return () => clearTimeout(timeout);
  }, [timeLeftAtRender, isPaused]);

  const expiryProgress =
    1 -
    (timeLeftAtRender - NOTIFICATION_FADE_OUT_TIME_MS) / (duration - NOTIFICATION_FADE_OUT_TIME_MS);
  const expiryRemainingTime = Math.max(0, timeLeftAtRender - NOTIFICATION_FADE_OUT_TIME_MS);

  const progressIndicator = useRef<HTMLDivElement>();
  useEffect(() => {
    const indicator = progressIndicator.current;
    for (const animation of indicator.getAnimations()) animation.cancel();

    if (isPausedForever) {
      indicator.animate([{ transform: 'scaleX(0)' }], { duration: 0, fill: 'forwards' });
    } else if (isPaused) {
      indicator.animate([{ transform: `scaleX(${1 - expiryProgress})` }], {
        duration: 0,
        fill: 'forwards'
      });
    } else {
      indicator.animate(
        [{ transform: `scaleX(${1 - expiryProgress})` }, { transform: 'scaleX(0)' }],
        { duration: expiryRemainingTime, fill: 'forwards' }
      );
    }
  }, [expiryProgress, expiryRemainingTime, isPaused, isPausedForever]);

  return (
    <div
      className={`notification-message ${errOptions ? 'is-error' : ''} ${
        isFadingOut ? 'is-fading-out' : ''
      } ${isPaused ? 'is-paused' : ''} ${isPausedForever ? 'is-paused-forever' : ''}`}
      onPointerOver={pause}
      onPointerOut={unpause}
      onPointerDown={pauseForever}
    >
      <Icon className="inner-icon" iconName={errOptions ? 'Error' : 'Info'} />
      <Text className="inner-message" as="div" block>
        {errOptions ? (
          <ShowError error={message} {...errOptions} isEmbedded />
        ) : (
          (message as string)
        )}
      </Text>
      <IconButton
        className="close-button"
        styles={{
          rootHovered: { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
          rootPressed: { backgroundColor: 'rgba(255, 255, 255, 0.3)' }
        }}
        iconProps={{
          styles: { root: { fontSize: '18px', color: 'white' } },
          iconName: 'Cancel'
        }}
        onPointerDown={(e) => e.stopPropagation()}
        onClick={onDismiss}
      />
      <div className="expiry-progress-indicator" ref={progressIndicator} />
    </div>
  );
}
