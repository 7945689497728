import { DeclaredTheme } from 'styled-components';
import { isInMsTeamsEnvironment } from './helpers';
import type { ThemeConfig } from './theme';
import { colorContrast, globalRgb, Rgb, rgb, synthesizeContrastingVariant } from './themeUtils';

export const KEEP_ALL_EXISTING_COLORS_FOR_NOW = (() => {
  if (import.meta.env.VITE_EVOCOM_PRODUCT === 'ai') {
    return false;
  }
  try {
    return window.localStorage.getItem('DEBUG_themingEnabled') !== 'true';
  } catch {
    return true;
  }
})();

interface Palette {
  themeColor: Rgb;
  themeColor3: Rgb;

  neutralBg: Rgb;
  neutralBg1: Rgb;
  neutralBg2: Rgb;
  neutralBg3: Rgb;
  neutralBg4: Rgb;
  neutralBg5: Rgb;

  neutralFg: Rgb;
  neutralFg1: Rgb;
  neutralFg2: Rgb;
  neutralFg3: Rgb;
  neutralFg4: Rgb;
  neutralFg5: Rgb;

  neutralBorder: Rgb;

  fgOnThemeColor: Rgb;
  themeOnBackground: Rgb;
  themeOnBackgroundLowContrast1: Rgb;
  themeOnBackgroundLowContrast15: Rgb;
  themeOnBackgroundLowContrast2: Rgb;
  fgOnThemeOnBackground: Rgb;
}

interface LightPalette extends Palette {
  themeDarkOnBackground: Rgb;
  themeDarkAltOnBackground: Rgb;
}

interface DarkPalette extends Palette {
  themeLightOnBackground: Rgb;
  themeLightAltOnBackground: Rgb;
}

function lightPalette({ fluentPalette }: ThemeConfig): LightPalette {
  const themeColor = globalRgb(fluentPalette.themePrimary, 'themeColor');
  const themeColor3 = globalRgb(fluentPalette.themeTertiary, 'themeColor3');

  /** white */
  const neutralBg = globalRgb(fluentPalette.white, 'neutralBg');
  /** neutralLighterAlt */
  const neutralBg1 = globalRgb(fluentPalette.neutralLighterAlt, 'neutralBg1');
  /** neutralLighter */
  const neutralBg2 = globalRgb(fluentPalette.neutralLighter, 'neutralBg2');
  /** neutralLight */
  const neutralBg3 = globalRgb(fluentPalette.neutralLight, 'neutralBg3');
  /** neutralQuaternaryAlt */
  const neutralBg4 = globalRgb(fluentPalette.neutralQuaternaryAlt, 'neutralBg4');
  /** neutralTertiaryAlt */
  const neutralBg5 = globalRgb(fluentPalette.neutralTertiaryAlt, 'neutralBg5');

  const neutralBorder = neutralBg4;

  /** black */
  const neutralFg = globalRgb(fluentPalette.black, 'neutralFg');
  /** neutralPrimary (#323130) */
  const neutralFg1 = globalRgb(fluentPalette.neutralPrimary, 'neutralFg1');
  /** neutralPrimaryAlt (#3b3a39) */
  const neutralFg2 = globalRgb(fluentPalette.neutralPrimaryAlt, 'neutralFg2');
  /** neutralSecondary (#605e5c) */
  const neutralFg3 = globalRgb(fluentPalette.neutralSecondary, 'neutralFg3');
  /** neutralTertiary (#a19f9d) */
  const neutralFg4 = globalRgb(fluentPalette.neutralTertiary, 'neutralFg4');
  /** neutralSecondaryAlt (#8a8886) */
  const neutralFg5 = globalRgb('#8a8886', 'neutralFg5');

  const fgOnThemeColor = KEEP_ALL_EXISTING_COLORS_FOR_NOW
    ? globalRgb(fluentPalette.white, 'fgOnThemeColor')
    : globalRgb(colorContrast(themeColor, [rgb('#fff'), rgb('#000')]), 'fgOnThemeColor');

  const themeOnBackground = KEEP_ALL_EXISTING_COLORS_FOR_NOW
    ? globalRgb(fluentPalette.themePrimary, 'themeOnBackground')
    : globalRgb(synthesizeContrastingVariant(rgb('#fff'), themeColor), 'themeOnBackground');

  const themeOnBackgroundLowContrast1 = KEEP_ALL_EXISTING_COLORS_FOR_NOW
    ? globalRgb(fluentPalette.themePrimary, 'themeOnBackgroundLowContrast1')
    : globalRgb(
        synthesizeContrastingVariant(rgb('#fff'), themeColor, 1),
        'themeOnBackgroundLowContrast1'
      );

  const themeOnBackgroundLowContrast15 = KEEP_ALL_EXISTING_COLORS_FOR_NOW
    ? globalRgb(fluentPalette.themePrimary, 'themeOnBackgroundLowContrast15')
    : globalRgb(
        synthesizeContrastingVariant(rgb('#fff'), themeColor, 1.5),
        'themeOnBackgroundLowContrast15'
      );

  const themeOnBackgroundLowContrast2 = KEEP_ALL_EXISTING_COLORS_FOR_NOW
    ? globalRgb(fluentPalette.themePrimary, 'themeOnBackgroundLowContrast2')
    : globalRgb(
        synthesizeContrastingVariant(rgb('#fff'), themeColor, 2),
        'themeOnBackgroundLowContrast2'
      );

  const fgOnThemeOnBackground = KEEP_ALL_EXISTING_COLORS_FOR_NOW
    ? globalRgb(fluentPalette.white, 'fgOnThemeOnBackground')
    : globalRgb(
        colorContrast(themeOnBackground, [rgb('#fff'), rgb('#000')]),
        'fgOnThemeOnBackground'
      );

  const themeDarkOnBackground = KEEP_ALL_EXISTING_COLORS_FOR_NOW
    ? globalRgb(fluentPalette.themeDark, 'themeDarkOnBackground')
    : globalRgb(
        synthesizeContrastingVariant(rgb('#fff'), rgb(fluentPalette.themeDark)),
        'themeDarkOnBackground'
      );

  const themeDarkAltOnBackground = KEEP_ALL_EXISTING_COLORS_FOR_NOW
    ? globalRgb(rgb(fluentPalette.themeDarkAlt), 'themeDarkAltOnBackground')
    : globalRgb(
        synthesizeContrastingVariant(rgb('#fff'), rgb(fluentPalette.themeDarkAlt)),
        'themeDarkAltOnBackground'
      );

  return {
    themeColor,
    themeColor3,
    neutralBg,
    neutralBg1,
    neutralBg2,
    neutralBg3,
    neutralBg4,
    neutralBg5,
    neutralBorder,
    neutralFg,
    neutralFg1,
    neutralFg2,
    neutralFg3,
    neutralFg4,
    neutralFg5,
    fgOnThemeColor,
    themeOnBackground,
    themeOnBackgroundLowContrast1,
    themeOnBackgroundLowContrast15,
    themeOnBackgroundLowContrast2,
    fgOnThemeOnBackground,
    themeDarkOnBackground,
    themeDarkAltOnBackground
  };
}

function darkPalette({ fluentPalette }: ThemeConfig): DarkPalette {
  const themeColor = globalRgb(fluentPalette.themePrimary, 'themeColor');
  const themeColor3 = globalRgb(fluentPalette.themeTertiary, 'themeColor3');
  const themeLightAlt = globalRgb(fluentPalette.themeLight, 'themeLightAlt');
  const themeLight = globalRgb(fluentPalette.themeLighter, 'themeLight');
  const fgOnThemeColor = globalRgb(
    colorContrast(themeColor, [rgb('#fff'), rgb('#000')]),
    'fgOnThemeColor'
  );

  /** extra background shade */
  const neutralBg = globalRgb('#262524', 'neutralBg');
  /** ""white"" */
  const neutralBg1 = globalRgb('#323130', 'neutralBg1');
  /** neutralLighterAlt */
  const neutralBg2 = globalRgb('#3c3b39', 'neutralBg2');
  /** neutralLighter */
  const neutralBg3 = globalRgb('#444241', 'neutralBg3');
  /** neutralQuaternaryAlt */
  const neutralBg4 = globalRgb('#595756', 'neutralBg4');
  /** neutralTertiaryAlt */
  const neutralBg5 = globalRgb('#7a7977', 'neutralBg5');

  const neutralBorder = globalRgb('#514f4e', 'neutralBorder');

  /** neutralPrimary */
  const neutralFg = globalRgb('#ffffff', 'neutralFg');
  /** neutralPrimaryAlt */
  const neutralFg1 = globalRgb('#dadada', 'neutralFg1');
  /** neutralSecondary */
  const neutralFg2 = globalRgb('#d0d0d0', 'neutralFg2');
  /** neutralTertiary */
  const neutralFg3 = globalRgb('#c8c8c8', 'neutralFg3');
  const neutralFg4 = globalRgb('#b9b9b9', 'neutralFg4');
  const neutralFg5 = globalRgb('#aaaaaa', 'neutralFg5');

  const themeOnBackground = globalRgb(
    synthesizeContrastingVariant(neutralBg, themeColor),
    'themeOnBackground'
  );
  const themeOnBackgroundLowContrast1 = globalRgb(
    synthesizeContrastingVariant(neutralBg, themeColor, 1),
    'themeOnBackgroundLowContrast1'
  );
  const themeOnBackgroundLowContrast15 = globalRgb(
    synthesizeContrastingVariant(neutralBg, themeColor, 1.5),
    'themeOnBackgroundLowContrast15'
  );
  const themeOnBackgroundLowContrast2 = globalRgb(
    synthesizeContrastingVariant(neutralBg, themeColor, 2),
    'themeOnBackgroundLowContrast2'
  );
  const themeLightOnBackground = globalRgb(
    synthesizeContrastingVariant(neutralBg, themeLight),
    'themeLightOnBackground'
  );
  const themeLightAltOnBackground = globalRgb(
    synthesizeContrastingVariant(neutralBg, themeLightAlt),
    'themeLightAltOnBackground'
  );

  const fgOnThemeOnBackground = globalRgb(
    colorContrast(themeOnBackground, [rgb('#fff'), rgb('#000')]),
    'fgOnThemeOnBackground'
  );

  return {
    themeColor,
    themeColor3,
    neutralBg,
    neutralBg1,
    neutralBg2,
    neutralBg3,
    neutralBg4,
    neutralBg5,
    neutralFg,
    neutralFg1,
    neutralFg2,
    neutralFg3,
    neutralFg4,
    neutralFg5,
    neutralBorder,
    fgOnThemeColor,
    themeOnBackground,
    themeOnBackgroundLowContrast1,
    themeOnBackgroundLowContrast15,
    themeOnBackgroundLowContrast2,
    fgOnThemeOnBackground,
    themeLightOnBackground,
    themeLightAltOnBackground
  };
}

function createLightTheme(
  palette: LightPalette,
  fluentPalette: ThemeConfig['fluentPalette']
): DeclaredTheme {
  const {
    themeColor,
    neutralBg,
    neutralBg1,
    neutralBg2,
    neutralBg3,
    neutralBg5,
    neutralBorder,
    neutralFg,
    neutralFg1,
    neutralFg3,
    neutralFg4,
    neutralFg5,
    fgOnThemeColor,
    themeOnBackground,
    themeOnBackgroundLowContrast1,
    themeOnBackgroundLowContrast15,
    themeOnBackgroundLowContrast2,
    fgOnThemeOnBackground,
    themeDarkOnBackground,
    themeDarkAltOnBackground
  } = palette;

  return {
    ...fluentPalette,

    extraFluentComponentStyles: {
      actionButtonForeground: neutralFg1,
      actionButtonHoveredForeground: themeOnBackground,
      actionButtonPressedForeground: neutralFg,
      actionButtonIconColor: themeOnBackground.withLumContrastAdj(10),
      actionButtonIconPressedColor: themeOnBackground.withLumContrastAdj(20),

      fabricForeground: neutralFg1,
      calloutBackground: rgb(fluentPalette.white),
      calloutShadow:
        'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px',

      menuHeaderColor: themeOnBackground,
      menuIconColor: themeOnBackground,

      buttonIconColor: themeOnBackground,
      buttonIconHoveredColor: themeDarkAltOnBackground,
      buttonIconPressedColor: themeDarkOnBackground,

      primaryButtonBackground: themeOnBackgroundLowContrast15,
      primaryButtonBackgroundHovered: themeOnBackgroundLowContrast15.withLumContrastAdj(20),
      primaryButtonBackgroundPressed: themeOnBackgroundLowContrast2.withLumContrastAdj(35),
      primaryButtonText: fgOnThemeColor,

      linkForeground: themeOnBackground,
      linkHoverForeground: themeOnBackground.withLumContrastAdj(20),

      dialogHeaderBackground: themeColor,
      dialogHeaderForeground: fgOnThemeColor,

      progressTrack: neutralBg3,
      progressBar: themeOnBackground,

      spinnerCircleA: themeOnBackground,
      spinnerCircleB: themeOnBackground.withCompressedLchLumAdj(50),
      spinnerLabel: themeOnBackground,

      suggestionsTitleForeground: themeOnBackground,

      inputIcon: themeOnBackground.mix(neutralFg, 0.5),
      inputBorder: neutralFg3,
      inputBorderHovered: neutralFg1,
      inputFocusBorderAlt: themeOnBackground,

      inputBackgroundChecked: themeOnBackgroundLowContrast2,
      inputBackgroundCheckedHovered: themeOnBackground.withCompressedLchLumAdj(10),
      inputForegroundChecked: colorContrast(themeOnBackgroundLowContrast2, [neutralFg, neutralBg])
    },
    app: {
      background: neutralBg,
      foreground: neutralFg,
      anchorForeground: themeOnBackground,

      scrollbarThumb: neutralBg5,
      scrollbarThumbHover: neutralFg4
    },
    header: {
      background: themeColor,
      foreground: fgOnThemeColor,
      searchForeground: colorContrast(fgOnThemeColor, [rgb('#fff'), rgb('#000')]),
      searchBackground: fgOnThemeColor,
      tenantDividerLine: rgb('#eaeaea'),

      searchSuggestionItemDivider: rgb('#edebe9'),
      searchSuggestionItemHoverBackground: rgb('#edebe9'),
      searchSuggestionItemHoverForeground: themeOnBackground
    },
    headerTeams: {
      searchOutline: rgb('#b1b1b1'),
      searchBackground: neutralBg,
      searchForeground: neutralFg,
      badgeBackground: neutralBg,
      badgeForeground: themeOnBackground,
      buttonForeground: neutralFg1,
      buttonDivider: neutralBorder
    },
    sidebar: {
      background: neutralBg,
      foreground: neutralFg,
      foregroundHighlighted: themeOnBackground,
      border: neutralBg3
    },
    breadcrumbs: {
      background: neutralBg
    },
    pageCommandBar: {
      divider: neutralBg3,
      shadow: '0 1px 2px rgba(21, 27, 38, 0.15)'
    },
    showError: {
      blockBackground: rgb('#db5741'),
      blockForeground: rgb('#fff'),
      inlineBackground: rgb('#f43'),
      inlineForeground: rgb(fluentPalette.white),
      iconColor: rgb('#f43'),
      cornerRadius: '8px'
    },
    combinedPicker: {
      background: neutralBg,
      basePickerDisabledBackground: rgb('#f4f4f4'),
      personaForeground: rgb('#333'),
      personaDisabledForeground: rgb('#c2c2c2'),
      contentWarningIconColor: rgb('#a80000')
    },
    datePicker: {
      outline: rgb('#a19f9d'),
      changeHighlight: rgb('#6c4')
    },
    tagPicker: {
      labelForeground: neutralFg3,
      hoverBackground: rgb('#f2f2f2'),
      placeholderForeground: rgb('#b1b1b1'),
      tagBackground: rgb('#e5e5e5'),
      tagOutline: rgb('#e5e5e5'),
      tagHoverBackground: themeOnBackgroundLowContrast1,
      tagHoverForeground: fgOnThemeColor,
      tagIconColor: neutralFg3,
      tagRemoveButtonForeground: neutralFg,
      suggestionDisabledForeground: rgb('#c2c2c2'),
      suggestionCreateForeground: themeOnBackground,
      suggestionTagForeground: rgb('#858585')
    },
    panelHeader: {
      background: themeColor,
      backgroundInactive: synthesizeContrastingVariant(
        fgOnThemeColor,
        themeColor.withLchChromaAdj((c) => c * 0.25)
      ),
      foreground: fgOnThemeColor,
      noticeBackground: neutralBg3,
      noticeForeground: neutralFg
    },
    dialog: {
      headerBackground: themeColor,
      headerForeground: fgOnThemeColor
    },
    statusDropdown: {
      outline: KEEP_ALL_EXISTING_COLORS_FOR_NOW ? rgb('#e5e5e5') : rgb('#222')
    },
    appLoading: {
      background: rgb('#f7f7f7'),
      cardBackground: rgb('#fcfcfc'),
      cardBorder: rgb('#d3d3d3'),
      foreground: neutralFg,
      loadingLabelText: rgb('#6d6e6f')
    },
    administration: {
      settingsBackground: rgb('#fafafa'),
      groupHeaderBackground: themeColor,
      groupHeaderForeground: fgOnThemeColor,
      itemLinkText: rgb('#0078d4'),
      tagGroupHoverBackground: rgb('#f3f2f1')
    },
    settingsContainer: {
      background: neutralBg,
      icon: themeOnBackground,
      labelText: rgb('#424242'),
      textFieldText: neutralFg1,
      descriptionText: rgb('#808080'),
      shadow: 'rgb(0 0 0 / 10%) 0px 0.3px 0.9px, rgb(0 0 0 / 13%) 0px 1.6px 3.6px',
      cornerRadius: '8px',
      textFieldOutline: rgb('#a19f9d')
    },
    detailsList: {
      background: neutralBg,
      rowBackground: neutralBg,
      rowBackgroundHover: rgb('#f3f2f1'),
      headerBackground: neutralBg,
      headerBackgroundOpacity: isInMsTeamsEnvironment() ? '0' : '1',
      fieldForeground: isInMsTeamsEnvironment() ? neutralFg : neutralFg3,
      dividerLine: rgb(isInMsTeamsEnvironment() ? '#dad9d8' : '#f3f2f1'),
      headerDividerLine: isInMsTeamsEnvironment() ? rgb('#dad9d8') : neutralBg,
      headerDividerLine2: rgb('#edebe9'),
      moreButtonHoverBackground: rgb('#e1e1e1'),
      linkNameForeground: rgb('#0078d4'),
      linkNameHoverForeground: rgb('#004578'),
      linkNameDisabledForeground: rgb('#828485'),

      contextMenuDivider: rgb('#eaeaea'),
      contextMenuSearchBackground: neutralBg,
      contextMenuIconColor: themeOnBackground
    },
    infoIconTooltip: {
      iconColor: rgb('#a6a6a6')
    },
    processTemplatesList: {
      versionMenuItemHoverBackground: rgb('#e1e1e1')
    },
    dynamicField: {
      fieldForeground: neutralFg1,
      labelDescriptionForeground: neutralFg3,
      outline: neutralFg4,
      strongHoverOutline: neutralFg,
      globalFieldChangeBannerBackground: rgb('#fee7bb'),
      fieldHistoryItemDivider: neutralBg3,
      cornerRadius: '3px',
      errorForeground: rgb('#a80000')
    },
    choiceGroupField: {
      background: neutralBg,
      disabledBackground: neutralBg2,
      foreground: neutralFg1,
      placeholderForeground: neutralFg4,
      outline: neutralFg4,
      hoverOutline: neutralFg,
      controlCheckedFill: themeColor,
      controlCheckedOutline: themeOnBackground,
      cornerRadius: '3px'
    },
    longTextField: {
      outline: rgb('#acabaa'),
      hoverOutline: neutralFg,
      focusOutline: themeOnBackground,
      disabledBackground: neutralBg2,
      disabledForeground: neutralFg1,
      disabledForegroundSecondary: neutralFg4,
      disabledOutline: neutralFg4,
      cornerRadius: '3px'
    },
    richTextEditor: {
      background: neutralBg,
      foreground: neutralFg,
      h1Foreground: rgb('#050505'),
      h2Foreground: rgb('#65676b'),
      otherH1Foreground: rgb('#333'),
      otherH2Foreground: rgb('#444'),
      otherAnchorForeground: rgb('#777'),
      linkForeground: rgb('#216fdb'),
      linkTooltipBackground: rgb(fluentPalette.white),
      linkTooltipForeground: rgb(fluentPalette.neutralDark),
      caretColor: rgb('#444'),
      placeholderForeground: rgb('#999'),
      codeBackground: rgb('#f0f2f5'),

      typeaheadPopoverBackground: neutralBg,
      typeaheadPopoverForeground: neutralFg,
      typeaheadPopoverItemSelectedBackground: neutralBg3,
      typeaheadPopoverShadow: '0px 5px 10px rgba(0, 0, 0, 0.3)',

      suggestionBackground: neutralBg,
      suggestionSelectedBackground: rgb('#edebe9'),
      suggestionHoverBackground: rgb('#edebe9'),
      suggestionSelectedHoverBackground: rgb('#bab8b6'),
      suggestionForeground: rgb('#343d46'),
      suggestionTitleForeground: themeOnBackground,
      suggestionDivider: rgb('#edebe9')
    },
    backgroundJobs: {
      transientPreviewBackground: neutralBg,
      transientPreviewForeground: neutralFg,
      transientPreviewPathColor: synthesizeContrastingVariant(
        rgb(fluentPalette.white),
        themeColor.withLchLuminanceAdjustment(-30)
      ),
      transientPreviewFailedPathColor: synthesizeContrastingVariant(
        rgb(fluentPalette.white),
        rgb('#f43')
      ),
      transientPreviewCompletedIconColor: themeOnBackground,
      transientPreviewFailedIconColor: rgb('#f43'),
      transientPreviewShadow: '0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 12px rgba(0, 0, 0, 0.1)',

      // hacks: legacy theme uses dark mode header, MS Teams uses a light mode header
      menuButtonBackground:
        KEEP_ALL_EXISTING_COLORS_FOR_NOW && !isInMsTeamsEnvironment() ? rgb('#fff') : neutralFg,
      menuButtonBackgroundOpacity: '0',
      menuButtonForeground:
        KEEP_ALL_EXISTING_COLORS_FOR_NOW && !isInMsTeamsEnvironment() ? rgb('#fff') : neutralFg,
      menuButtonHoverBackground:
        KEEP_ALL_EXISTING_COLORS_FOR_NOW && !isInMsTeamsEnvironment() ? rgb('#fff') : neutralFg,
      menuButtonHoverBackgroundOpacity: '0.1',
      menuButtonActiveBackground:
        KEEP_ALL_EXISTING_COLORS_FOR_NOW && !isInMsTeamsEnvironment() ? rgb('#fff') : neutralFg,
      menuButtonActiveBackgroundOpacity: '0.3',

      menuDivider: neutralBg3,
      menuCompletedIconColor: themeOnBackground,
      menuFailedIconColor: rgb('#f43'),

      closeWarningBackground: neutralBg,
      closeWarningForeground: neutralFg,
      closeWarningOutline: neutralBg3,
      closeWarningShadow: '0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 12px rgba(0, 0, 0, 0.1)'
    },
    tasks: {
      pageBackground: neutralBg1,
      loadingBackground: neutralBg,
      listBackground: rgb('#fafafa'),
      sideBySideSprintsBackground: neutralBg,
      kanbanHeaderBackground: themeColor,
      kanbanHeaderForeground: fgOnThemeColor,
      kanbanGroupDragOverBackground: rgb('#e7e9ea'),
      kanbanNewColumnForeground: themeOnBackground,
      depictionSettingsCornerRadius: '8px',
      depictionSettingsBackground: rgb('#f4f4f4'),
      depictionSettingSelectedBackground: rgb('#e3e3e3'),
      depictionSettingSelectedIconColor: themeOnBackground,
      viewMenuDivider: neutralBg3,
      viewMenuHoverBackground: neutralBg2,
      viewMenuActiveBackground: neutralBg3
    },
    tasksSprintColumn: {
      outline: rgb('#edebe9'),
      draggingOverBackground: rgb('#e7e9ea'),
      itemDivider: rgb('#f3f2f1'),
      itemBackground: neutralBg,
      itemSelectedBackground: rgb('#edebe9'),
      itemGhostingBackground: rgb('#edebe9'),
      itemGhostingBackgroundOpacity: '0.3',
      itemFadedForeground: rgb('#828485')
    },
    iconButton: {
      dangerForeground: rgb('#f42')
    },
    taskCard: {
      background: neutralBg,
      completedBackground: rgb(fluentPalette.neutralQuaternaryAlt),
      foreground: neutralFg,
      foregroundSecondary: rgb(fluentPalette.neutralTertiary),
      completedForegroundSecondary: rgb(fluentPalette.neutralTertiary),
      iconColor: neutralFg3,
      dividerLine: rgb(fluentPalette.neutralQuaternaryAlt),
      outline: neutralBg3,
      menuButtonSelectedBackground: themeColor,
      menuButtonSelectedIconColor: fgOnThemeColor,
      progressColorGreen: rgb('#6bb700'),
      progressColorOrange: rgb('#ff8c00'),
      progressColorRed: rgb('#e81123'),
      notificationHighlightBackground: rgb('#ea4300'),
      notificationHighlightHoverBackground: rgb('#e04000'),
      notificationHighlightForeground: rgb('#fff'),
      cornerRadius: '8px',
      shadow: 'rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px, rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px',
      shadowHover: 'rgb(0 0 0 / 13%) 0px 6.4px 14.4px 0px, rgb(0 0 0 / 11%) 0px 1.2px 3.6px 0px'
    },
    statusIcon: {
      iconColor: themeOnBackground,
      filledIconBackground: themeOnBackground,
      filledIconForeground: fgOnThemeOnBackground
    },
    priorityButton: {
      hoverBackground: neutralBg3,
      prio1Fill: rgb('#e44a4a'),
      prio1Outline: rgb('#cf1a1a'),
      prio1Fg: rgb('#fff'),
      prio2Fill: rgb('#ff4d4d'),
      prio2Outline: rgb('#ff4d4d'),
      prio2Fg: rgb('#fff'),
      prio3Fill: rgb('#ff7f7f'),
      prio3Outline: rgb('#ff7f7f'),
      prio3Fg: rgb('#000'),
      prio4Fill: rgb('#ffb3b3'),
      prio4Outline: rgb('#ffb3b3'),
      prio4Fg: rgb('#333'),
      prio5Fill: rgb('#f3f2f1'),
      prio5Outline: rgb('#f3f2f1'),
      prio5Fg: rgb('#666'),
      prio6Fill: rgb('#f6f5f4'),
      prio6Outline: rgb('#f6f5f4'),
      prio6Fg: rgb('#666'),
      prio7Fill: rgb('#f8f7f6'),
      prio7Outline: rgb('#f8f7f6'),
      prio7Fg: rgb('#666')
    },
    taskTemplateSchedule: {
      summaryDetailBackground: neutralBg2,
      summaryDetailOutline: neutralBorder,

      typePickerBackground: neutralBg3,
      typePickerForeground: neutralFg3,
      typePickerOutline: neutralBorder,
      typePickerItemSelectedBackground: neutralBg1,
      typePickerItemSelectedOutline: neutralBorder,
      typePickerItemSelectedForeground: neutralFg,

      warningBoxOutline: rgb('#c9b547'),
      warningBoxBackground: rgb('#fea'),
      warningBoxForeground: neutralFg
    },
    facePile: {
      overflowBackground: themeOnBackground,
      overflowForeground: fgOnThemeOnBackground,
      chevronColor: neutralFg1
    },
    ruleBlock: {
      background: rgb('#f4f9fd'),
      nestedBackground: neutralFg,
      nestedBackgroundOpacity: '0.03',
      outline: rgb('#e5e5e5'),
      inputBackground: neutralBg,
      cornerRadius: '0'
    },
    activitiesList: {
      divider: rgb('#ddd'),
      unreadNotificationBackground: themeOnBackgroundLowContrast1,
      unreadNotificationBackgroundOpacity: '0.1',
      unreadNotificationHoverBackgroundOpacity: '0.2',
      notificationIndicatorColor: themeOnBackground
    },
    taskDetails: {
      divider: rgb('#edebe9'),
      fieldLabelForeground: neutralFg3,
      fieldForeground: neutralFg1,
      fieldPlaceholderForeground: rgb('#b1b1b1'),
      fieldHoverBackground: rgb('#f2f2f2'),
      fieldFocusOutline: themeOnBackground,
      fieldExtraIconColor: neutralFg3,

      personaItemBackground: rgb('#eaeaea'),
      approvalRteOutline: neutralFg3,
      boxBackground: neutralBg,
      boxOutline: rgb('#e6e6e6'),
      boxTabInactiveForeground: rgb('#a1a9ac')
    },
    taskDetailsRelatedTaskItem: {
      outline: rgb(fluentPalette.neutralQuaternary),
      descriptionText: neutralFg3,
      cornerRadius: '8px'
    },
    taskDetailsChecklist: {
      background: rgb('#f5f5f5'),
      listCornerRadius: '5px',
      listShadow: '0 3.2px 7.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%)',
      listBackground: rgb('#faf9f8'),
      listDraggingOverBackground: rgb('#e9e9e9'),
      progressBarColorStart: themeOnBackground,
      progressBarColorEnd: synthesizeContrastingVariant(
        rgb(fluentPalette.white),
        rgb(fluentPalette.themeTertiary)
      ),
      progressBarAbortedIconColor: rgb('#a80000'),
      itemBackground: rgb('#faf9f8'),
      itemDivider: rgb('#e9e9e9'),
      itemControlHoverBackground: rgb('#eee'),
      itemControlHover2Background: rgb('#e2e2e2'),
      itemIconColor: rgb('#b4b4b4'),
      itemLinkedTitleForeground: themeOnBackground,
      itemSecondaryForeground: neutralFg3,
      itemDraggingOutline: themeOnBackgroundLowContrast1,
      instanceGroupBackground: rgb('#faf9f8'),
      instanceGroupShadow: '0 3.2px 7.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%)',
      instanceGroupOutline: rgb('#e5e5e5'),
      instanceGroupDivider: rgb('#e9e9e9'),
      createTaskPlaceholderForeground: rgb('#b1b1b1'),
      createTaskBorder: neutralFg1
    },
    panelCommandBar: {
      background: rgb('#f4f9fd'),
      itemHoverBackground: rgb('#e5eff8')
    },
    teamPanel: {
      addMemberInputAccent: themeOnBackground,
      memberListDivider: rgb('#f2f2f2')
    },
    trainerPanel: {
      background: rgb('#f9f9f9'),
      itemBackground: neutralBg,
      itemOutline: neutralBg3,
      checkboxOutline: rgb('#ccc'),
      checkboxHoverOutline: rgb('#b4b4b4')
    },
    projectGantt: {
      pageBackground: rgb('#faf9f8'),
      actionButtonForeground: themeOnBackground,
      timelineHeaderBackground: neutralBg,
      dividerLine: neutralBg3,
      actionsCellButtonHovered: rgb('#edebe9'),
      predecessorsOutline: rgb('#c8c8c8'),
      predecessorsItemHoverBackground: rgb('#f4f4f4'),
      predecessorsItemPersonaBackground: themeColor,
      predecessorsItemPersonaForeground: fgOnThemeColor,
      predecessorsTextFieldBackground: rgb('#f4f4f4'),
      predecessorsTextFieldBorder: themeOnBackground
    },
    attachments: {
      background: neutralBg1,
      separatorLine: rgb('#c3c3c3'),
      placeholderForeground: rgb('#737373'),
      dropzoneBackground: rgb('#e9e9e9'),
      documentCardDivider: neutralBg3,
      documentTitleForeground: neutralFg1,
      documentPreviewIconBackground: neutralBg,
      documentPreviewIconForeground: themeOnBackground,
      documentPreviewIconColorMSExcel: rgb('#217346'),
      documentPreviewIconColorMSPowerPoint: rgb('#b7472a'),
      documentPreviewIconColorMSWord: rgb('#2b579a'),
      editorWrapperBackground: neutralBg,
      editorBackground: neutralBg1,
      editorBorder: neutralBg3,
      editorBorder2: neutralBg,
      editorFocusedBorder2: themeOnBackground,
      readMoreForeground: themeOnBackground,
      readMoreShadow: 'rgb(0 0 0 / 10%) 0px -8px 8px -8px',
      readMoreBorder: rgb('#252423'),
      readMoreBorderOpacity: '0.05'
    },
    conversation: {
      background: rgb('#f5f5f5'),
      separatorLine: rgb('#c3c3c3'),
      discussionOutline: neutralBg3,
      editorBackground: neutralBg,
      editorActiveLine: themeOnBackground,
      actionButtonBackground: rgb('#faf9f8'),
      actionButtonHoverBackground: rgb('#f5f4f3'),
      actionButtonForeground: themeOnBackground,
      commentBackground: neutralBg,
      commentReplyBackground: rgb('#faf9f8'),
      commentDivider: rgb('#edebe9'),
      commentCreatorForeground: rgb('#252423'),
      commentDateEditForeground: rgb('#484644'),
      commentMenuButtonOutline: rgb('#edebe9'),
      commentIndicatorMentioned: rgb('#e97548'),
      commentIndicatorSelf: themeOnBackground,
      readMoreShadow: 'rgb(0 0 0 / 10%) 0px -8px 8px -8px',
      readMoreBorder: rgb('#252423'),
      readMoreBorderOpacity: '0.05'
    },
    pagesList: {
      background: rgb('#fafafa')
    },
    tagSearch: {
      background: rgb('#faf9f8')
    },
    folderView: (() => {
      const primary = synthesizeContrastingVariant(rgb('#f6f8f9'), themeColor, 1.5);
      const foreground = colorContrast(primary, [neutralBg, neutralFg]);

      return {
        background: rgb('#f6f8f9'),
        folderIconPrimary: primary,
        folderIconPaper: neutralBg,
        folderIconPaperOutline: neutralBg,
        folderIconPaperOutlineOpacity: '0',
        folderIconBackground: colorContrast(foreground, [neutralBg, neutralFg]),
        folderIconForeground: foreground
      };
    })(),
    processLandscape: {
      headerForeground: neutralFg,
      itemBackground: themeOnBackgroundLowContrast2,
      itemForeground: colorContrast(themeOnBackgroundLowContrast2, [neutralBg, neutralFg]),
      itemDisabledBackground: neutralBg3,
      itemDisabledForeground: neutralFg3
    },
    processCanvas: {
      viewportControlsBackground: neutralBg,
      viewportControlsShadow: '0 1px 2px #0002, 0 4px 8px #0002',

      minimapBackground: neutralBg,
      minimapMaskColor: neutralFg,
      minimapMaskOpacity: '0.1',
      minimapNodeColor: rgb('#ddd'),

      validatingSpinnerBackground: neutralBg
    },
    processInstanceStep: {
      background: rgb('#f8f8f8'),
      foreground: neutralFg,
      foregroundSecondary: rgb('#9f9f9f'),
      foregroundSecondaryOpacity: '1',

      outlineNotStarted: rgb('#e5e5e5'),
      outlineCurrent: rgb('#bad80a'),
      outlineAsyncIncomplete: themeOnBackground,
      outlineDone: synthesizeContrastingVariant(neutralBg, themeColor, 2),

      doneBackground: themeColor,
      doneForeground: fgOnThemeColor,
      doneForegroundSecondary: fgOnThemeColor,
      doneForegroundSecondaryOpacity: '0.8',

      bypassedBackground: rgb('#484644'),
      bypassedForeground: rgb('#fff'),
      bypassedForegroundSecondary: rgb('#fff'),
      bypassedForegroundSecondaryOpacity: '0.8',

      abortedStatusIconColor: rgb('#d20000'),

      skippedBackground: themeColor.withLchChromaAdj((c) => c * 0.7),
      skippedForeground: fgOnThemeColor,
      skippedForegroundSecondary: fgOnThemeColor,
      skippedForegroundSecondaryOpacity: '0.8',

      focusRing: rgb('#777'),
      focusRingOpacity: '0.7',

      integrationHoverBackground: rgb('#f8f8f8').withLumContrastAdj(12),
      integrationDoneHoverBackground: themeColor.withLumContrastAdj(15),
      integrationBypassedHoverBackground: rgb('#484644').withLumContrastAdj(15),
      integrationSkippedHoverBackground: themeColor
        .withLchChromaAdj((c) => c * 0.7)
        .withLumContrastAdj(15),

      integrationOutlinePending: rgb('#bad80a'),
      integrationOutlineDone: themeOnBackground,
      integrationOutlineSkipped: themeOnBackground
    },
    processInstanceFailedSubProcess: {
      descriptionForeground: neutralFg3
    },
    processInstanceTestSubProcess: {
      settingBackground: neutralBg2,
      settingOutline: rgb(fluentPalette.neutralQuaternary),
      settingIcon: themeOnBackground
    },
    processDesignerComponents: {
      designerBackground: rgb('#f6f8f9'),
      addStepButtonHoverIconColor: rgb('#004578'),

      addButtonHoverBackground: rgb('#f4f9fd'),
      addButtonHoverBackgroundOpacity: '1',
      addButtonForeground: rgb('#5c5c5c'),
      addButtonDisabledForeground: rgb('#a19f9d'),
      addButtonIconColor: rgb('#106ebe'),
      addButtonDisabledIconColor: rgb('#a19f9d'),

      sectionHeaderForeground: rgb('#5c5c5c'),
      sectionHeaderDivider: rgb('#eaeaea'),

      stepPanelCommandBarBackground: rgb('#f4f9fd'),
      stepPanelCommandBarButtonBackground: rgb('#f4f9fd'),
      stepPanelCommandBarButtonHoverBackground: rgb('#e5eff8'),

      stepPanelPickerBackground: neutralBg,
      stepConfigDialogDescriptionForeground: neutralFg3,
      stepConfigIconColor: themeOnBackground,

      autoTagEditorDivider: rgb('#797775'),
      autoTagPickerHoverBackground: rgb('#f2f2f2'),
      autoTagPickerPlaceholderForeground: rgb('#b1b1b1'),

      tooltipForeground: neutralFg1,

      itemDeleteIconColor: rgb('#f00'),
      itemDeleteDisabledIconColor: rgb('#c8c8c8'),
      itemRearrangeIconColor: rgb('#5c5c5c'),
      itemRearrangeDisabledIconColor: rgb('#c2c2c2'),
      itemRearrangeDisabledIconOpacity: '1',

      pickerCreateSuggestionForeground: themeOnBackground,
      pickerSecondaryForeground: rgb('#858585'),
      pickerTooltipSecondaryForeground: neutralFg3,
      pickerTooltipActionForeground: themeOnBackground,
      pickerOutline: neutralFg3,
      pickerFocusOutline: themeOnBackground,
      pickerTagItemBackground: neutralBg2,
      pickerTagItemForeground: neutralFg,
      pickerWarningIconColor: rgb('#a80000'),

      reminderFormSectionBackground: neutralBg,
      reminderFormSectionOutline: neutralFg4,
      reminderFormSectionDisabledBackground: neutralBg2,
      reminderFormSectionDisabledOutline: neutralBg5,
      reminderFormSectionActiveBackground: rgb(fluentPalette.themeLighterAlt),
      reminderFormSectionActiveOutline: themeOnBackground,

      reminderFormSpinButtonDisabledOutline: rgb('#d8d8d8'),
      reminderFormSpinButtonArrowsBackground: neutralBg,
      reminderFormCheckboxOutline: rgb('#eaeaea')
    },
    processDesignerIntegrations: {
      labelTitleForeground: themeOnBackground,
      labelSubTextForeground: rgb('#808080'),
      filterPanelDivider: rgb('#c8c8c8'),
      filterPanelShadow:
        '0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108)',

      catalogGridBackground: rgb('#fcfcfc'),
      catalogGridItemBackground: neutralBg,

      serviceImageBackground: neutralBg,
      serviceImageForeground: neutralFg,
      serviceImageShadow: '0 2px 10px 0 rgba(0,0,0,0.10)',

      serviceItemDivider: rgb('#eaeaea'),
      serviceItemBackground: neutralBg,
      serviceItemBackgroundAlt: rgb('#f4f4f4'),
      serviceItemConfigIconColor: themeOnBackground,

      mappingListBackground: rgb('#fafafa'),
      mappingListOutline: rgb('#e6eaed'),
      mappingWarningForeground: rgb('#a80000'),
      mappingRowDivider: rgb('#e6eaed'),
      mappingRowFocusBackground: rgb('#f4f9fd'),
      mappingRowFieldPickerErrorBorder: rgb('#a80000'),
      mappingRowFieldPickerBackground: neutralBg,
      mappingFieldSecondaryForeground: rgb('#808080'),

      rulesLabelDivider: rgb('#eaeaea')
    },
    processDesignerStep: {
      background: neutralBg,
      foreground: neutralFg,
      outline: rgb('#e5e5e5'),
      shadow: '0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)',
      divider: rgb('#e5e5e5'),

      titleForeground: neutralFg1,
      inputFocusAccent: themeOnBackground,
      inputHoverBackground: rgb('#f4f4f4'),
      iconColor: neutralFg1,
      personaOverflowBackground: themeOnBackground,
      personaOverflowForeground: fgOnThemeOnBackground,

      menuOverflowExpandedBackground: themeColor,
      menuOverflowExpandedForeground: fgOnThemeColor
    },
    processDesignerTemplatePanel: {
      columnsBackground: rgb('#faf9f8'),
      columnsDraggingOverBackground: rgb('#e9e9e9'),
      columnsDivider: rgb('#e9e9e9'),
      columnsShadow: '0 3.2px 7.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%)',
      columnRearrangeIconColor: rgb('#b4b4b4'),
      columnTitleBackground: neutralBg,
      columnTitleOutline: rgb('#d3d3d3'),
      columnTitleDisabledForeground: neutralFg1,
      columnDraggingOutline: themeOnBackground,
      columnDraggingShadow: '0px 0px 5px rgba(0,0,0,0.2)',

      overflowSettingsButtonBackground: rgb('#f5f5f5'),
      overflowSettingsButtonHoverBackground: rgb('#e2e2e2'),

      commandBarBackground: rgb('#f7f7f7'),
      commandBarItemHoverBackground: rgb('#ececec')
    },
    welcomePage: {
      background: neutralBg,
      foreground: neutralFg,
      accentForeground: rgb('#004e9e'),
      secondaryLinkForeground: neutralFg1,
      textFieldOutline: neutralFg4,
      logoColor: rgb('#145aa9'),
      textFieldForeground: neutralFg1,
      submitButtonBackground: rgb('#004e9e'),
      submitButtonForeground: rgb('#fff'),
      submitButtonHoverBackground: rgb('#004e9e').withLumContrastAdj(10),
      submitButtonPressedBackground: rgb('#004e9e').withLumContrastAdj(16)
    },
    aiPage: {
      background: neutralBg1,
      foreground: neutralFg,
      outline: neutralBg3,
      shadow: '0 1px 1px rgba(0, 0, 0, 0.1)',

      subheaderBackground: neutralBg1,
      subheaderDivider: neutralBorder
    },
    aiChat: {
      messageForeground: neutralFg1,
      userMessageBackground: neutralBg3,
      userMessageForeground: neutralFg1,
      userMessageCornerRadius: '1.5rem',
      typingIndicator: themeOnBackground,

      agentMessageBackground: neutralBg2,
      agentMessageForeground: neutralFg1,
      agentMessageCornerRadius: '1.5rem',

      messageFailedBackground: rgb('#a80000'),
      messageFailedForeground: rgb('#fff'),

      inputContainerBackground: neutralBg2,
      inputBackground: neutralBg,
      inputDisabledBackground: neutralBg1,
      inputOutline: neutralBorder,
      inputCornerRadius: '12px',

      collectionChatNameForeground: neutralFg3,

      startFormBackground: neutralBg,
      startFormOutline: neutralBorder,
      startFormForeground: neutralFg,

      fileBackground: neutralBg3,
      fileOutline: neutralBorder,

      agentSubBackground: neutralBg2,
      agentSubOutline: neutralBorder,
      agentSubForeground: neutralFg1
    },
    aiChatTextReference: {
      background: neutralBg3,
      foreground: neutralFg3,
      unexpectedForeground: rgb('#f83')
    },
    aiChatWebSearch: {
      inlineBackground: neutralBg2,
      inlineForeground: neutralFg,
      inlineOutline: neutralBorder,
      inlineQueryBackground: neutralBg1,
      inlineQueryActivityEffectA: themeOnBackground,
      inlineQueryActivityEffectB: themeOnBackground.withLumContrastAdj(50),
      inlineResultsBackground: neutralBg1,
      inlineResultsOutline: neutralBorder,
      inlineResultsActivityEffect: themeOnBackground,

      resultItemBackground: neutralBg2,
      resultItemForeground: neutralFg,

      auxQueryBackground: neutralBg2,
      auxQueryForeground: neutralFg,
      auxQueryOutline: neutralBorder
    },
    aiChatAgentInfo: {
      disabledWarningBackground: rgb('#fea'),
      disabledWarningForeground: rgb('#000')
    },
    aiDashboard: {
      tileBackground: neutralBg,
      tileBackgroundShade1: themeColor.withLchChromaAdj((c) => Math.min(c, 50)).mix(neutralBg, 0.5),
      tileBackgroundShade2: themeColor
        .withLchChromaAdj((c) => Math.min(c * 2, 50))
        .triadHarmony()[1]
        .mix(neutralBg, 0.5),
      tileBackgroundShade3: themeColor
        .withLchChromaAdj((c) => Math.min(c * 2, 50))
        .triadHarmony()[2]
        .mix(neutralBg, 0.5),
      tileForeground: neutralFg,
      tileOutline: neutralBorder
    },
    aiNavigation: {
      foreground: neutralFg,
      modalShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
      itemActiveBackground: neutralBg3,
      chatItemForeground: neutralFg3,
      spineColor: neutralFg5,
      calloutHeaderForeground: themeOnBackground,
      settingsIconColor: neutralFg3,
      logoColor: themeOnBackground
    },
    agentCatalog: {
      background: neutralBg,
      tabsBackground: neutralBg2,
      tabsForeground: neutralFg3,
      tabsCornerRadius: '10px',
      tabsActiveBackground: neutralBg1,
      tabsActiveForeground: neutralFg,
      tabsActiveShadow: '0 1px 4px rgba(0, 0, 0, 0.1)'
    },
    agentSettings: {
      containerBackground: neutralBg1,
      containerForeground: neutralFg,
      containerOutline: neutralBg2,
      containerDivider: neutralBorder,
      containerShadow: '0 1px 4px rgba(0, 0, 0, 0.3)',
      fieldBackground: neutralBg2,
      fieldForeground: neutralFg,
      fieldOutline: neutralBorder,
      fieldOpenBackground: neutralBg1,
      fieldOpenShadow: '0 1px 4px rgba(0, 0, 0, 0.3), 0 2px 6px rgba(0, 0, 0, 0.2)',
      fieldChoiceBackground: neutralBg3,
      deleteButtonColor: rgb('#a80000'),
      listItemBackground: neutralBg2,
      listItemForeground: neutralFg,
      editedIconColor: themeOnBackground
    },
    agentCard: {
      background: neutralBg,
      foreground: neutralFg1,
      defaultAccentColor: themeOnBackgroundLowContrast1,
      defaultAccentColor2: themeOnBackgroundLowContrast1.triadHarmony()[1],
      foregroundSecondary: neutralFg4,
      favoritedForeground: themeOnBackground,
      startHighlightBackground: rgb('#fff'),
      startHighlightBackgroundOpacity: '0.75',
      cornerRadius: '1rem',
      outline: neutralBorder,
      shadow: '0 1px 4px rgba(0, 0, 0, 0.1)'
    },
    chatCard: {
      background: neutralBg,
      foreground: neutralFg1,
      cornerRadius: '1rem',
      outline: neutralBorder,
      shadow: '0 1px 4px rgba(0, 0, 0, 0.1)'
    },
    knowledgeBaseCard: {
      background: neutralBg,
      foreground: neutralFg1,
      defaultAccentColor: synthesizeContrastingVariant(neutralFg1, themeOnBackground, 9).mix(
        neutralBg,
        0.5
      ),
      defaultAccentColor2: synthesizeContrastingVariant(neutralFg1, themeOnBackground, 9)
        .triadHarmony()[2]
        .mix(neutralBg, 0.5),
      cornerRadius: '1rem',
      outline: neutralBorder,
      shadow: '0 1px 4px rgba(0, 0, 0, 0.1)',
      shadowHover: '0 4px 6px rgba(0, 0, 0, 0.2)'
    },
    aiChatList: {
      background: neutralBg,
      itemDivider: neutralBorder,
      itemHighlightForeground: themeOnBackground,
      viewTypeSwitchBackground: neutralBg2,
      viewTypeSwitchActiveBackground: neutralBg,
      viewTypeSwitchForeground: neutralFg1
    },
    aiSettings: {
      blockBackground: neutralBg2,
      blockDivider: neutralBorder,
      fieldBackground: neutralBg1,
      fieldForeground: neutralFg,
      deleteIconColor: rgb('#f43')
    },
    aiCustomPluginConfig: {
      tableBackground: neutralBg,
      tableSelectedBackground: neutralBg2,
      tableOverlayCellBackground: rgb('#fea'),
      tableForeground: neutralFg,
      tableDivider: neutralBorder,

      operationProgressBackground: neutralBg3,
      operationProgressForeground: themeOnBackground,
      detailsFieldOverlayColor: rgb('#fb1'),

      detailsDivider: neutralBorder,
      detailsBackground: neutralBg1,
      detailsForeground: neutralFg,

      selectButtonOutline: neutralBg3,
      selectButtonBackground: neutralBg1,
      selectButtonHoverBackground: neutralBg2,
      selectButtonPressedBackground: neutralBg3,
      selectButtonSelectedBackground: themeOnBackground,
      selectButtonSelectedPressedBackground: themeOnBackground.withLumContrastAdj(10),
      selectButtonSelectedForeground: fgOnThemeOnBackground,
      selectButtonDragRingColor: themeOnBackground.withLumContrastAdj(20),

      toggleButtonOutline: neutralBorder,
      toggleButtonDisabledBackground: neutralBg1,
      toggleButtonDisabledForeground: neutralFg,
      toggleButtonEnabledBackground: themeOnBackground,
      toggleButtonEnabledForeground: fgOnThemeOnBackground,
      toggleButtonThumbColor: fgOnThemeOnBackground,
      toggleButtonThumbOutline: fgOnThemeOnBackground.withLumContrastAdj(6),

      unmappedBannerBackground: rgb('#fea')
    },
    messageEditor: {
      canvasBackground: neutralBg1,
      documentBackground: neutralBg,
      documentOutline: neutralBorder,
      elementSelectedOutline: themeOnBackground,

      toolbarDivider: neutralBorder,
      toolbarButtonSelectionColor: neutralFg
    },
    httpMethod: {
      delete: rgb('#e24'),
      get: rgb('#45b'),
      head: rgb('#b08'),
      options: rgb('#658'),
      patch: rgb('#49a'),
      post: rgb('#0a6'),
      put: rgb('#d60'),
      trace: rgb('#333')
    }
  } as DeclaredTheme;
}

export const DEFAULT = (config: ThemeConfig) => {
  const palette = lightPalette(config);
  return createLightTheme(palette, config.fluentPalette);
};

export const DARK = (config: ThemeConfig) => {
  const {
    themeColor,
    themeColor3,
    neutralBg,
    neutralBg1,
    neutralBg2,
    neutralBg3,
    neutralBg4,
    neutralBg5,
    neutralFg,
    neutralFg1,
    neutralFg2,
    neutralFg3,
    neutralFg4,
    neutralBorder,
    fgOnThemeColor,
    themeOnBackground,
    themeOnBackgroundLowContrast1,
    fgOnThemeOnBackground,
    themeLightOnBackground,
    themeLightAltOnBackground
  } = darkPalette(config);

  const asLightPalette: LightPalette = {
    ...darkPalette(config),
    themeDarkOnBackground: themeLightOnBackground,
    themeDarkAltOnBackground: themeLightAltOnBackground
  };

  const defaultConfig = createLightTheme(asLightPalette, config.fluentPalette);

  return {
    ...defaultConfig,

    extraFluentComponentStyles: {
      ...defaultConfig.extraFluentComponentStyles,

      fabricForeground: neutralFg,
      // add better background contrast and a border
      calloutBackground: rgb('#383635'),
      calloutShadow: '0 0 0 0.5px #fff3, 0px 3px 8px #0007, 0px 0.6px 1.8px #0003',

      buttonIconHoveredColor: themeLightAltOnBackground,
      buttonIconPressedColor: themeLightOnBackground,

      progressTrack: neutralBorder,

      spinnerCircleB: themeOnBackground.withCompressedLchLumAdj(-30),

      inputBorder: neutralFg4,
      inputBorderHovered: neutralFg3
    },
    app: {
      background: neutralBg1,
      foreground: neutralFg,
      anchorForeground: themeOnBackground,

      scrollbarThumb: neutralBg5,
      scrollbarThumbHover: neutralFg4
    },
    header: {
      background: themeColor,
      foreground: fgOnThemeColor,
      searchForeground: colorContrast(fgOnThemeColor, [rgb('#fff'), rgb('#000')]),
      searchBackground: fgOnThemeColor,
      tenantDividerLine: neutralBorder,

      searchSuggestionItemDivider: neutralBorder,
      searchSuggestionItemHoverBackground: neutralBg3,
      searchSuggestionItemHoverForeground: themeOnBackground
    },
    headerTeams: {
      ...defaultConfig.headerTeams,
      searchOutline: neutralBg5,
      searchBackground: neutralBg1,
      badgeBackground: neutralBg1
    },
    sidebar: {
      background: neutralBg1,
      foreground: neutralFg,
      foregroundHighlighted: themeOnBackground,
      border: neutralBorder
    },
    statusDropdown: {
      outline: rgb('#e5e5e5')
    },
    appLoading: {
      background: neutralBg1,
      cardBackground: neutralBg2,
      cardBorder: neutralBorder,
      foreground: neutralFg,
      loadingLabelText: neutralFg3
    },

    administration: {
      settingsBackground: neutralBg,
      groupHeaderBackground: themeColor,
      groupHeaderForeground: fgOnThemeColor,
      itemLinkText: synthesizeContrastingVariant(neutralBg, rgb('#0078d4')),
      tagGroupHoverBackground: neutralBg2
    },
    settingsContainer: {
      background: neutralBg1,
      icon: themeOnBackground,
      labelText: rgb('#bdbdbd'),
      textFieldText: neutralFg,
      descriptionText: rgb('#808080'),
      shadow: 'rgb(0 0 0 / 20%) 0px 0.3px 0.9px, rgb(0 0 0 / 26%) 0px 1.6px 3.6px',
      cornerRadius: '8px',
      textFieldOutline: rgb('#5e5d5c')
    },

    detailsList: {
      background: neutralBg1,
      rowBackground: neutralBg1,
      rowBackgroundHover: neutralBg2,
      headerBackground: neutralBg1,
      headerBackgroundOpacity: isInMsTeamsEnvironment() ? '0' : '1',
      fieldForeground: isInMsTeamsEnvironment() ? neutralFg : neutralFg3,
      dividerLine: isInMsTeamsEnvironment() ? rgb('#262625') : neutralBorder,
      headerDividerLine: isInMsTeamsEnvironment() ? rgb('#262625') : neutralBg1,
      headerDividerLine2: neutralBorder,
      moreButtonHoverBackground: neutralBg4,
      linkNameForeground: rgb('#509eff'),
      linkNameHoverForeground: rgb('#87bbff'),
      linkNameDisabledForeground: rgb('#989aa0'),

      contextMenuDivider: neutralBorder,
      contextMenuSearchBackground: rgb('#383635'),
      contextMenuIconColor: themeOnBackground
    },

    processTemplatesList: {
      versionMenuItemHoverBackground: neutralBg4
    },

    dynamicField: {
      fieldForeground: neutralFg1,
      labelDescriptionForeground: neutralFg3,
      outline: neutralBg5,
      strongHoverOutline: neutralFg1,
      globalFieldChangeBannerBackground: rgb('#513e10'),
      fieldHistoryItemDivider: neutralBorder,
      cornerRadius: '3px',
      errorForeground: rgb('#fe5341')
    },

    choiceGroupField: {
      background: neutralBg2,
      disabledBackground: neutralBg1,
      foreground: neutralFg1,
      placeholderForeground: neutralFg4,
      outline: neutralBg5,
      hoverOutline: neutralFg,
      controlCheckedFill: themeColor,
      controlCheckedOutline: themeOnBackground,
      cornerRadius: '3px'
    },

    longTextField: {
      outline: neutralBg5,
      hoverOutline: neutralFg,
      focusOutline: themeOnBackground,
      disabledBackground: neutralBg2,
      disabledForeground: neutralFg1,
      disabledForegroundSecondary: neutralFg4,
      disabledOutline: neutralBg5,
      cornerRadius: '3px'
    },

    richTextEditor: {
      background: neutralBg1,
      foreground: rgb('#fff'),
      h1Foreground: rgb('#ddd'),
      h2Foreground: rgb('#eee'),
      otherH1Foreground: rgb('#ccc'),
      otherH2Foreground: rgb('#ddd'),
      otherAnchorForeground: rgb('#999'),
      linkForeground: rgb('#4c96ff'),
      linkTooltipBackground: neutralBg,
      linkTooltipForeground: neutralFg,
      caretColor: rgb('#ccc'),
      placeholderForeground: rgb('#aaa'),
      codeBackground: rgb('#090a0a'),

      typeaheadPopoverBackground: neutralBg1,
      typeaheadPopoverForeground: neutralFg,
      typeaheadPopoverItemSelectedBackground: neutralBg3,
      typeaheadPopoverShadow: '0px 5px 10px rgba(0, 0, 0, 0.5)',

      suggestionBackground: neutralBg1,
      suggestionSelectedBackground: neutralBg3,
      suggestionHoverBackground: neutralBg3,
      suggestionSelectedHoverBackground: neutralBg4,
      suggestionForeground: neutralFg2,
      suggestionTitleForeground: themeOnBackground,
      suggestionDivider: neutralBorder
    },

    backgroundJobs: {
      transientPreviewBackground: neutralBg3,
      transientPreviewForeground: neutralFg,
      transientPreviewPathColor: neutralFg,
      transientPreviewFailedPathColor: rgb('#f43'),
      transientPreviewCompletedIconColor: themeOnBackground,
      transientPreviewFailedIconColor: rgb('#f43'),
      transientPreviewShadow: '0 0 0 0.5px rgba(255, 255, 255, 0.5), 0 4px 8px rgba(0, 0, 0, 0.3)',

      menuButtonBackground: neutralFg,
      menuButtonBackgroundOpacity: '0',
      menuButtonForeground: neutralFg,
      menuButtonHoverBackground: neutralFg,
      menuButtonHoverBackgroundOpacity: '0.1',
      menuButtonActiveBackground: neutralFg,
      menuButtonActiveBackgroundOpacity: '0.3',

      menuDivider: neutralBorder,
      menuCompletedIconColor: themeOnBackground,
      menuFailedIconColor: rgb('#f43'),

      closeWarningBackground: neutralBg2,
      closeWarningOutline: neutralBorder,
      closeWarningForeground: neutralFg,
      closeWarningShadow: '0 4px 8px rgba(0, 0, 0, 0.3)'
    },

    breadcrumbs: {
      background: neutralBg1
    },
    pageCommandBar: {
      divider: neutralBorder,
      shadow: '0 1px 2px rgba(0, 0, 0, 0.3)'
    },
    showError: {
      blockBackground: rgb('#a63425'),
      blockForeground: rgb('#fff'),
      inlineBackground: rgb('#f43'),
      inlineForeground: rgb('#fff'),
      iconColor: rgb('#f43'),
      cornerRadius: '8px'
    },
    combinedPicker: {
      background: neutralBg1,
      basePickerDisabledBackground: neutralBg2,
      personaForeground: neutralFg2,
      personaDisabledForeground: neutralFg4,
      contentWarningIconColor: rgb('#f43')
    },
    datePicker: {
      outline: rgb('#5e5d5c'),
      changeHighlight: rgb('#6c4')
    },
    tagPicker: {
      labelForeground: neutralFg3,
      hoverBackground: neutralBg2,
      placeholderForeground: neutralFg4,
      tagBackground: neutralBg3,
      tagOutline: neutralBorder,
      tagHoverBackground: themeOnBackgroundLowContrast1,
      tagHoverForeground: fgOnThemeColor,
      tagIconColor: neutralFg3,
      tagRemoveButtonForeground: neutralFg,
      suggestionDisabledForeground: neutralFg4,
      suggestionCreateForeground: themeOnBackground,
      suggestionTagForeground: neutralFg3
    },

    tasks: {
      ...defaultConfig.tasks,
      pageBackground: neutralBg,
      loadingBackground: neutralBg,
      listBackground: neutralBg2,
      kanbanGroupDragOverBackground: neutralBg4,
      sideBySideSprintsBackground: neutralBg,
      depictionSettingsBackground: neutralBg,
      depictionSettingSelectedBackground: neutralBg2,
      depictionSettingSelectedIconColor: themeOnBackground
    },
    tasksSprintColumn: {
      outline: neutralBorder,
      draggingOverBackground: neutralBg3,
      itemDivider: neutralBorder,
      itemBackground: neutralBg1,
      itemSelectedBackground: neutralBg2,
      itemGhostingBackground: neutralBg2,
      itemGhostingBackgroundOpacity: '0.3',
      itemFadedForeground: neutralFg3
    },

    taskCard: {
      ...defaultConfig.taskCard,
      background: neutralBg1,
      completedBackground: neutralBg4,
      foreground: neutralFg,
      foregroundSecondary: neutralFg3,
      completedForegroundSecondary: neutralFg3,
      iconColor: neutralFg2,
      dividerLine: neutralBorder,
      outline: neutralBorder
    },
    statusIcon: {
      iconColor: themeOnBackground,
      filledIconBackground: themeOnBackground,
      filledIconForeground: fgOnThemeOnBackground
    },
    priorityButton: {
      ...defaultConfig.priorityButton,
      hoverBackground: neutralBg3
    },
    taskTemplateSchedule: {
      ...defaultConfig.taskTemplateSchedule,
      warningBoxBackground: rgb('#751'),
      warningBoxOutline: rgb('#8e6a27'),
      warningBoxForeground: neutralFg
    },
    facePile: {
      overflowBackground: themeColor,
      overflowForeground: fgOnThemeColor,
      chevronColor: neutralFg3
    },

    ruleBlock: {
      background: neutralBg2,
      nestedBackground: rgb('#fff'),
      nestedBackgroundOpacity: '0.03',
      outline: neutralBorder,
      inputBackground: neutralBg,
      cornerRadius: '0'
    },

    activitiesList: {
      divider: neutralBorder,
      unreadNotificationBackground: themeOnBackgroundLowContrast1,
      unreadNotificationBackgroundOpacity: '0.1',
      unreadNotificationHoverBackgroundOpacity: '0.2',
      notificationIndicatorColor: themeOnBackground
    },

    taskDetails: {
      divider: neutralBorder,
      fieldLabelForeground: neutralFg3,
      fieldForeground: neutralFg1,
      fieldPlaceholderForeground: neutralFg4,
      fieldHoverBackground: neutralBg3,
      fieldFocusOutline: themeOnBackground,
      fieldExtraIconColor: neutralFg4,

      personaItemBackground: neutralBg3,
      approvalRteOutline: neutralFg3,
      boxBackground: neutralBg1,
      boxOutline: neutralBorder,
      boxTabInactiveForeground: neutralFg4
    },
    taskDetailsRelatedTaskItem: {
      outline: neutralBorder,
      descriptionText: neutralFg2,
      cornerRadius: '8px'
    },
    taskDetailsChecklist: {
      background: neutralBg,
      listCornerRadius: '5px',
      listShadow: '0 3.2px 7.2px 0 rgb(0 0 0 / 26%), 0 0.6px 1.8px 0 rgb(0 0 0 / 22%)',
      listBackground: neutralBg2,
      listDraggingOverBackground: neutralBg3,
      progressBarColorStart: themeOnBackground,
      progressBarColorEnd: synthesizeContrastingVariant(neutralBg, themeColor3),
      progressBarAbortedIconColor: rgb('#fe5341'),
      itemBackground: neutralBg2,
      itemDivider: neutralBorder,
      itemControlHoverBackground: neutralBg4,
      itemControlHover2Background: neutralBg5,
      itemIconColor: neutralFg3,
      itemLinkedTitleForeground: themeOnBackground,
      itemSecondaryForeground: neutralFg3,
      itemDraggingOutline: themeOnBackgroundLowContrast1,
      instanceGroupBackground: neutralBg2,
      instanceGroupShadow: '0 3.2px 7.2px 0 rgb(0 0 0 / 26%), 0 0.6px 1.8px 0 rgb(0 0 0 / 22%)',
      instanceGroupOutline: neutralBorder,
      instanceGroupDivider: neutralBorder,
      createTaskPlaceholderForeground: neutralFg4,
      createTaskBorder: neutralFg1
    },

    panelCommandBar: {
      background: neutralBg,
      itemHoverBackground: neutralBg2
    },
    teamPanel: {
      addMemberInputAccent: themeOnBackground,
      memberListDivider: neutralBorder
    },

    attachments: {
      background: neutralBg1,
      separatorLine: neutralBorder,
      placeholderForeground: neutralFg4,
      dropzoneBackground: neutralBg3,
      documentCardDivider: neutralBg3,
      documentTitleForeground: neutralFg1,
      documentPreviewIconBackground: neutralBg,
      documentPreviewIconForeground: themeOnBackground,
      documentPreviewIconColorMSExcel: rgb('#499868'),
      documentPreviewIconColorMSPowerPoint: rgb('#d05c3d'),
      documentPreviewIconColorMSWord: rgb('#7699e3'),
      editorWrapperBackground: neutralBg1,
      editorBackground: neutralBg2,
      editorBorder: neutralBg3,
      editorBorder2: neutralBg,
      editorFocusedBorder2: themeOnBackground,
      readMoreForeground: themeOnBackground,
      readMoreShadow: 'rgb(0 0 0 / 20%) 0px -8px 8px -8px',
      readMoreBorder: neutralFg2,
      readMoreBorderOpacity: '0.05'
    },
    conversation: {
      background: neutralBg1,
      separatorLine: neutralBorder,
      discussionOutline: neutralBorder,
      editorBackground: neutralBg,
      editorActiveLine: themeOnBackground,
      actionButtonBackground: neutralBg2,
      actionButtonHoverBackground: neutralBg3,
      actionButtonForeground: themeOnBackground,
      commentBackground: neutralBg,
      commentReplyBackground: neutralBg1,
      commentDivider: neutralBorder,
      commentCreatorForeground: neutralFg2,
      commentDateEditForeground: neutralFg3,
      commentMenuButtonOutline: neutralBorder,
      commentIndicatorMentioned: rgb('#ab4018'),
      commentIndicatorSelf: themeOnBackground,
      readMoreShadow: 'rgb(0 0 0 / 20%) 0px -8px 8px -8px',
      readMoreBorder: neutralFg2,
      readMoreBorderOpacity: '0.05'
    },
    pagesList: {
      background: neutralBg2
    },
    tagSearch: {
      background: neutralBg2
    },
    folderView: (() => {
      const primary = synthesizeContrastingVariant(neutralBg, themeColor, 2);
      const foreground = colorContrast(primary, [neutralFg, neutralBg]);

      return {
        background: neutralBg,
        folderIconPrimary: primary,
        folderIconPaper: neutralBg4,
        folderIconPaperOutline: neutralBg5,
        folderIconPaperOutlineOpacity: '1',
        folderIconBackground: colorContrast(foreground, [neutralBg, neutralFg]),
        folderIconForeground: foreground
      };
    })(),
    trainerPanel: {
      background: neutralBg1,
      itemBackground: neutralBg2,
      itemOutline: neutralBorder,
      checkboxOutline: neutralBorder,
      checkboxHoverOutline: neutralFg4
    },
    projectGantt: {
      pageBackground: neutralBg,
      actionButtonForeground: themeOnBackground,
      timelineHeaderBackground: neutralBg1,
      dividerLine: neutralBorder,
      actionsCellButtonHovered: neutralBg3,
      predecessorsOutline: neutralBorder,
      predecessorsItemHoverBackground: neutralBg2,
      predecessorsItemPersonaBackground: themeColor,
      predecessorsItemPersonaForeground: fgOnThemeColor,
      predecessorsTextFieldBackground: neutralBg2,
      predecessorsTextFieldBorder: themeOnBackground
    },
    processCanvas: {
      viewportControlsBackground: neutralBg1,
      viewportControlsShadow: '0 1px 2px #0004, 0 4px 8px #0004',

      minimapBackground: neutralBg1,
      minimapMaskColor: neutralBg4,
      minimapMaskOpacity: '0.6',
      minimapNodeColor: neutralBg4,

      validatingSpinnerBackground: neutralBg2
    },
    processInstanceStep: {
      background: neutralBg3,
      foreground: neutralFg,
      foregroundSecondary: neutralFg2,
      foregroundSecondaryOpacity: '1',

      outlineNotStarted: neutralBorder,
      outlineCurrent: rgb('#bad80a'),
      outlineAsyncIncomplete: themeOnBackground,
      outlineDone: synthesizeContrastingVariant(neutralBg, themeColor, 2),

      doneBackground: themeColor,
      doneForeground: fgOnThemeColor,
      doneForegroundSecondary: fgOnThemeColor,
      doneForegroundSecondaryOpacity: '0.8',

      bypassedBackground: neutralBg,
      bypassedForeground: neutralFg4,
      bypassedForegroundSecondary: neutralFg4,
      bypassedForegroundSecondaryOpacity: '0.5',

      abortedStatusIconColor: rgb('#f43'),

      skippedBackground: themeColor.withLchChromaAdj((c) => c * 0.7),
      skippedForeground: fgOnThemeColor,
      skippedForegroundSecondary: fgOnThemeColor,
      skippedForegroundSecondaryOpacity: '0.8',

      focusRing: neutralFg,
      focusRingOpacity: '0.5',

      integrationHoverBackground: neutralBg3.withLumContrastAdj(12),
      integrationDoneHoverBackground: themeColor.withLumContrastAdj(15),
      integrationBypassedHoverBackground: neutralBg.withLumContrastAdj(15),
      integrationSkippedHoverBackground: themeColor
        .withLchChromaAdj((c) => c * 0.7)
        .withLumContrastAdj(15),

      integrationOutlinePending: rgb('#bad80a'),
      integrationOutlineDone: themeOnBackground,
      integrationOutlineSkipped: themeOnBackground
    },
    processInstanceFailedSubProcess: {
      descriptionForeground: neutralFg2
    },
    processInstanceTestSubProcess: {
      settingBackground: neutralBg3,
      settingOutline: neutralBorder,
      settingIcon: themeOnBackground
    },
    processDesignerComponents: {
      designerBackground: neutralBg,
      addStepButtonHoverIconColor: rgb('#719cd7'),

      addButtonHoverBackground: rgb('#5498ed'),
      addButtonHoverBackgroundOpacity: '0.15',
      addButtonForeground: neutralFg2,
      addButtonDisabledForeground: neutralFg4,
      addButtonIconColor: rgb('#5498ed'),
      addButtonDisabledIconColor: neutralFg4,

      sectionHeaderForeground: neutralFg3,
      sectionHeaderDivider: neutralBorder,

      stepPanelCommandBarBackground: rgb('#2f3438'),
      stepPanelCommandBarButtonBackground: rgb('#2f3438'),
      stepPanelCommandBarButtonHoverBackground: rgb('#3c4246'),

      stepPanelPickerBackground: neutralBg1,
      stepConfigDialogDescriptionForeground: neutralFg2,
      stepConfigIconColor: themeOnBackground,

      autoTagEditorDivider: neutralBorder,
      autoTagPickerHoverBackground: neutralBg3,
      autoTagPickerPlaceholderForeground: neutralFg4,

      tooltipForeground: neutralFg1,

      itemDeleteIconColor: rgb('#f43'),
      itemDeleteDisabledIconColor: rgb('#766'),
      itemRearrangeIconColor: neutralFg2,
      itemRearrangeDisabledIconColor: neutralFg2,
      itemRearrangeDisabledIconOpacity: '0.5',

      pickerCreateSuggestionForeground: themeOnBackground,
      pickerSecondaryForeground: neutralFg3,
      pickerTooltipSecondaryForeground: neutralFg2,
      pickerTooltipActionForeground: themeOnBackground,
      pickerOutline: neutralFg2,
      pickerFocusOutline: themeOnBackground,
      pickerTagItemBackground: neutralBg3,
      pickerTagItemForeground: neutralFg,
      pickerWarningIconColor: rgb('#f43'),

      reminderFormSectionBackground: neutralBg2,
      reminderFormSectionOutline: neutralBorder,
      reminderFormSectionDisabledBackground: neutralBg1,
      reminderFormSectionDisabledOutline: neutralBorder,
      reminderFormSectionActiveBackground: neutralBg.mix(themeColor, 0.2),
      reminderFormSectionActiveOutline: themeOnBackground,

      reminderFormSpinButtonDisabledOutline: neutralBorder,
      reminderFormSpinButtonArrowsBackground: neutralBg2,
      reminderFormCheckboxOutline: neutralBorder
    },
    processDesignerIntegrations: {
      labelTitleForeground: themeOnBackground,
      labelSubTextForeground: neutralFg3,
      filterPanelDivider: neutralBorder,
      filterPanelShadow: '0 3.2px 7.2px 0 rgba(0, 0, 0, 0.26), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.2)',

      catalogGridBackground: neutralBg2,
      catalogGridItemBackground: neutralBg1,

      serviceImageBackground: rgb('#fff'),
      serviceImageForeground: rgb('#000'),
      serviceImageShadow: '0 2px 10px 0 rgba(0,0,0,0.2)',

      serviceItemDivider: neutralBorder,
      serviceItemBackground: neutralBg1,
      serviceItemBackgroundAlt: neutralBg2,
      serviceItemConfigIconColor: themeOnBackground,

      mappingListBackground: neutralBg3,
      mappingListOutline: neutralBorder,
      mappingWarningForeground: rgb('#f43'),
      mappingRowDivider: neutralBorder,
      mappingRowFocusBackground: neutralBg1,
      mappingRowFieldPickerErrorBorder: rgb('#f43'),
      mappingRowFieldPickerBackground: neutralBg1,
      mappingFieldSecondaryForeground: neutralFg4,

      rulesLabelDivider: neutralBorder
    },
    processDesignerStep: {
      background: neutralBg2,
      foreground: neutralFg,
      outline: neutralBorder,
      shadow: '0 1.6px 3.6px 0 rgba(0, 0, 0, 0.4), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.3)',
      divider: neutralBorder,

      titleForeground: neutralFg1,
      inputFocusAccent: themeOnBackground,
      inputHoverBackground: neutralBg1,
      iconColor: neutralFg1,
      personaOverflowBackground: themeOnBackground,
      personaOverflowForeground: fgOnThemeOnBackground,

      menuOverflowExpandedBackground: themeColor,
      menuOverflowExpandedForeground: fgOnThemeColor
    },
    processDesignerTemplatePanel: {
      columnsBackground: neutralBg2,
      columnsDraggingOverBackground: neutralBg3,
      columnsDivider: neutralBorder,
      columnsShadow: '0 3.2px 7.2px 0 rgb(0 0 0 / 26%), 0 0.6px 1.8px 0 rgb(0 0 0 / 22%)',
      columnRearrangeIconColor: neutralFg4,
      columnTitleBackground: neutralBg1,
      columnTitleOutline: neutralBorder,
      columnTitleDisabledForeground: neutralFg2,
      columnDraggingOutline: themeOnBackground,
      columnDraggingShadow: '0px 0px 5px rgb(0 0 0 / 0.4)',

      overflowSettingsButtonBackground: neutralBg2,
      overflowSettingsButtonHoverBackground: neutralBg3,

      commandBarBackground: neutralBg3,
      commandBarItemHoverBackground: neutralBg4
    },

    welcomePage: {
      ...defaultConfig.welcomePage,
      accentForeground: rgb('#769ef8'),
      logoColor: rgb('#6b9af0')
    },

    aiChatAgentInfo: {
      disabledWarningBackground: rgb('#751'),
      disabledWarningForeground: rgb('#fff')
    },

    agentSettings: {
      ...defaultConfig.agentSettings,
      containerOutline: neutralBorder,
      containerShadow: '0 1px 4px rgba(0, 0, 0, 0.5)',
      deleteButtonColor: rgb('#f43')
    },
    agentCard: {
      ...defaultConfig.agentCard,
      background: neutralBg2,
      startHighlightBackgroundOpacity: '0.25'
    },

    aiCustomPluginConfig: {
      ...defaultConfig.aiCustomPluginConfig,
      tableOverlayCellBackground: rgb('#751'),
      unmappedBannerBackground: rgb('#751')
    },
    httpMethod: {
      delete: rgb('#f44'),
      get: rgb('#89f'),
      head: rgb('#f5c'),
      options: rgb('#bae'),
      patch: rgb('#6cd'),
      post: rgb('#6e9'),
      put: rgb('#f94'),
      trace: rgb('#ccc')
    }
  } as DeclaredTheme;
};

export const AI_LIGHT = (config: ThemeConfig) => {
  const palette = {
    ...lightPalette(config),
    neutralBg1: globalRgb('#fcfcf9', 'neutralBg1'),
    neutralBg2: globalRgb('#f3f3ee', 'neutralBg2'),
    neutralBg3: globalRgb('#e8e8e3', 'neutralBg3'),
    neutralFg3: globalRgb('#64645f', 'neutralFg3')
  };
  const light = createLightTheme(palette, config.fluentPalette);

  return {
    ...light,
    app: {
      ...light.app,
      background: palette.neutralBg2
    }
  };
};

export const AI_DARK = (config: ThemeConfig) => {
  const palette = darkPalette(config);
  const dark = DARK(config);

  return {
    ...dark,
    app: {
      ...dark.app,
      background: palette.neutralBg2
    }
  };
};
