import PropTypes from 'prop-types';
import styled from 'styled-components';
import { adjustColor, colorsForDashboards } from 'utils/helpers';
import { formatStringVertical, getWidthOfText } from './utils';

const DiagramContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const BarStyled = styled.g`
  transition: all 0.3s ease-in-out;

  rect:hover {
    cursor: ${(props) => (props.$clickable ? 'pointer' : 'default')};
    fill: ${(props) => adjustColor(props.$barFill, -40)};
    x: ${(props) => props.$xTick - (props.$barWidth * 0.15) / 2}px;
    width: ${(props) => props.$barWidth * 1.15}px;
    height: ${(props) => (props.$barVal > 1 ? props.$barHeight - 5 : props.$barHeight - 2)}px;
  }

  rect:hover ~ .barText {
    font-size: ${(props) => (props.$chartHeight < 650 ? '0.9em' : '1.2em')};
    font-weight: bold;
  }

  rect:hover ~ .xAxisText {
    font-size: ${(props) => (props.$chartHeight < 650 ? '0.9em' : '1.2em')};
    font-weight: bold;
  }
`;

const DiagramStyled = styled.div`
  margin: 0 1em 1em 0;

  .axis {
    font-size: ${(props) => (props.$chartHeight < 650 ? '0.7em' : '1em')};
  }

  .axis path,
  .axis line {
    fill: none;
    stroke: currentColor;
    shape-rendering: crispEdges;
  }

  .barPath {
    transition: all 0.3s ease-in-out;
  }

  .barText {
    fill: currentColor;
    font-size: ${(props) => (props.$chartHeight < 650 ? '0.7em' : '1em')};
    transition: all 0.3s ease-in-out;
  }

  .xAxisText {
    fill: currentColor;
  }
`;

function GroupedBarsChart({
  showText,
  showTooltip,
  chartValues,
  onSelectedChartGroup,
  yAxisTitle,
  chartHeight,
  chartWidth,
  chartId
}) {
  const chartStartX = 0;
  const chartStartY = 0;
  const maxChartWidth = chartWidth || 950;
  const maxChartHeight = chartHeight || 650;
  const yAxisLength = maxChartHeight - 110;
  const xAxisLength = maxChartWidth - 50;
  const barColors = colorsForDashboards();

  function getXAxis() {
    const numberOfGroups = chartValues.length;

    const tickDistance = xAxisLength / (numberOfGroups + 1);
    const xAxis = [];
    const textHeight = '100';
    const textWidth = tickDistance - 5;
    const fontSize = '11px';
    const font = 'Segoe UI';

    for (let i = 0; i < numberOfGroups; i += 1) {
      const xTranslation = (i + 1) * tickDistance;
      let formatedName = chartValues[i].title;
      const stringWidth = getWidthOfText(chartValues[i].title, { font }, { fontSize });

      if (stringWidth > textHeight) {
        formatedName = formatStringVertical(
          chartValues[i].title,
          textWidth,
          textHeight,
          -15,
          8,
          15,
          fontSize
        );
      }

      xAxis.push(
        <g
          className="tick"
          transform={`translate(${xTranslation},0)`}
          style={{ opacity: 1 }}
          key={`bar-x-tick-${i + 1}-${chartId}`}
        >
          <line y2="6" x2="0" />
          <text
            className="xAxisText"
            transform="rotate(-60)"
            y="8"
            x="-15"
            style={{ textAnchor: 'end' }}
          >
            {formatedName}
          </text>
        </g>
      );
    }

    return (
      <g className="x axis" transform={`translate(0,${yAxisLength})`} key={`bar-x-axis-${chartId}`}>
        {xAxis}
        <path className="domain" d={`M0,6V0H${xAxisLength}V6`} />
      </g>
    );
  }

  function getYAxisValues() {
    const maxValue = Math.max(
      ...chartValues.map((group) =>
        Math.max(...group.numbers.map((groupItem) => groupItem.number), 0)
      ),
      0
    );

    if (maxValue > 0) {
      let stepValue = 1;

      if (maxValue > 10000) {
        stepValue = 5000;
      } else if (maxValue > 5000) {
        stepValue = 1000;
      } else if (maxValue > 2000) {
        stepValue = 500;
      } else if (maxValue > 1000) {
        stepValue = 200;
      } else if (maxValue > 500) {
        stepValue = 100;
      } else if (maxValue > 200) {
        stepValue = 50;
      } else if (maxValue > 100) {
        stepValue = 20;
      } else if (maxValue > 50) {
        stepValue = 10;
      } else if (maxValue > 20) {
        stepValue = 5;
      } else if (maxValue > 10) {
        stepValue = 2;
      }

      const maxTopRoundedValue = Math.ceil(maxValue / stepValue) * stepValue;
      const numberOfSteps = maxTopRoundedValue / stepValue;
      const stepHeight = (yAxisLength - 20) / numberOfSteps;

      return {
        stepValue,
        maxTopRoundedValue,
        stepHeight,
        numberOfSteps
      };
    }

    return {
      stepValue: 1,
      maxTopRoundedValue: 0,
      stepHeight: 0,
      numberOfSteps: 0
    };
  }

  function getYAxis() {
    const y = getYAxisValues();

    const yAxis = [];
    for (let i = 0; i < y.numberOfSteps + 1; i += 1) {
      const yTranslation = yAxisLength - i * y.stepHeight;
      yAxis.push(
        <g
          className="tick"
          transform={`translate(0,${yTranslation})`}
          style={{ opacity: 1 }}
          key={`bar-y-tick-${i}-${chartId}`}
        >
          <line x2="-6" y2="0" />
          <text dy=".32em" x="-9" y="0" style={{ textAnchor: 'end', fill: 'currentColor' }}>
            {y.stepValue * i}
          </text>
        </g>
      );
    }
    return (
      <g className="y axis" key={`bar-y-axis-${chartId}`}>
        {yAxis}
        <path className="domain" d={`M-6,0H0V${yAxisLength}H-6`} />
        <text x="-8" y="-15" dy=".71em" style={{ textAnchor: 'start', fill: 'currentColor' }}>
          {yAxisTitle}
        </text>
      </g>
    );
  }

  function getAxises() {
    const axises = [];
    axises.push(getXAxis());
    axises.push(getYAxis());
    return axises;
  }

  function getBarsForGroup(group, tickDistance, groupIndex) {
    if (group.numbers) {
      const numberOfBars = group.numbers?.length || 0;

      const spacer = numberOfBars > 1 ? 6 : 20;

      const barWidth = (tickDistance - (numberOfBars + 1) * spacer) / numberOfBars;

      const y = getYAxisValues();

      const bars = [];

      for (let i = 0; i < numberOfBars; i += 1) {
        let xTick = (groupIndex + 1) * tickDistance;

        if (i + 1 < (numberOfBars + 1) / 2) {
          xTick -= (numberOfBars / 2 - i) * barWidth + ((numberOfBars + 1) / 2 - (i + 1)) * spacer;
        } else if (i + 1 === (numberOfBars + 1) / 2) {
          xTick -= barWidth / 2;
        } else {
          xTick += (i - numberOfBars / 2) * barWidth + (i - (numberOfBars + 1) / 2 + 1) * spacer;
        }

        const barHeight = group.numbers[i].number * (y.stepHeight / y.stepValue);
        const tooltip = `${group.numbers[i].title}: ${group.numbers[i].number}`;
        const barColor = barColors[group.numbers[i].colorIndex];

        bars.push(
          <BarStyled
            className="barContainer"
            key={`bar-${group.numbers[i].key}-${chartId}`}
            $barWidth={barWidth}
            $barHeight={barHeight}
            $xTick={xTick}
            $barFill={barColor}
            $barVal={group.numbers[i].number}
            $chartHeight={maxChartHeight}
            $clickable={!!onSelectedChartGroup}
          >
            <rect
              className="barPath"
              x={xTick}
              width={barWidth}
              y={yAxisLength - barHeight}
              height={barHeight}
              onClick={() =>
                onSelectedChartGroup
                  ? onSelectedChartGroup({ ...group.numbers[i], sprintId: group.key })
                  : null
              }
              fill={barColor}
            />
            {showTooltip ? <title className="barTooltip">{tooltip}</title> : null}
            {showText ? (
              <text
                className="barText"
                textAnchor="middle"
                x={xTick + barWidth / 2}
                y={yAxisLength - barHeight - 10}
              >
                {group.numbers[i].number}
              </text>
            ) : null}
          </BarStyled>
        );
      }

      return bars;
    }

    return null;
  }

  function getGroups() {
    const numberOfGroups = chartValues.length;

    const tickDistance = xAxisLength / (numberOfGroups + 1);

    const groups = [];

    for (let i = 0; i < numberOfGroups; i += 1) {
      const groupBars = getBarsForGroup(chartValues[i], tickDistance, i);

      if (groupBars) {
        groups.push(groupBars);
      }
    }

    return groups;
  }

  function getBarChart() {
    return (
      <g transform="translate(40,20)">
        {getAxises()}
        {getGroups()}
      </g>
    );
  }

  function getSvgChart() {
    return (
      <svg
        width={maxChartWidth}
        height={maxChartHeight}
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="xMinYMin meet"
        viewBox={`${chartStartX * 1.5} ${chartStartY * 1.5} ${maxChartWidth} ${maxChartHeight}`}
        className="bar-chart"
      >
        {getBarChart()}
      </svg>
    );
  }

  return (
    <DiagramContainerStyled>
      <DiagramStyled $chartHeight={maxChartHeight}>{getSvgChart()}</DiagramStyled>
    </DiagramContainerStyled>
  );
}

GroupedBarsChart.propTypes = {
  showText: PropTypes.bool,
  showTooltip: PropTypes.bool,
  onSelectedChartGroup: PropTypes.func,
  chartValues: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      number: PropTypes.number,
      colorIndex: PropTypes.number
    })
  ).isRequired,
  yAxisTitle: PropTypes.string,
  chartHeight: PropTypes.number,
  chartWidth: PropTypes.number,
  chartId: PropTypes.string
};

GroupedBarsChart.defaultProps = {
  showText: false,
  showTooltip: false,
  onSelectedChartGroup: null,
  yAxisTitle: null,
  chartHeight: null,
  chartWidth: null,
  chartId: ''
};

export default GroupedBarsChart;
