import {
  createApiObjectDataFromPlainObject,
  IApiObjectData,
  IApiObjectType,
  makeApiObjectVariantKey
} from '../object';
import { ApiError, fetchJson } from '../../../services/api2';

export interface IClientStoreItem extends IApiObjectData {
  value: unknown | null;
}

export const MEMBER_CLIENT_STORE: IApiObjectType<string, IClientStoreItem> = {
  id: 'tenants/members/client-store',
  // only the current user can mutate this value, so we can cache for a bit longer,
  // since it's unlikely that it'd be mutated a lot from multiple different tabs
  loadDedupIntervalSecs: 60,
  createRefs(): void {
    // nothing
  },
  async load(cache, params, abort) {
    let result: string | null;
    try {
      result = await fetchJson({
        url: `tenants/${cache.tenantId}/members/${cache.memberId}/client-store/${params}`,
        abort
      });
    } catch (error) {
      if (error instanceof ApiError && error.status === 404) {
        result = null;
      } else {
        throw error;
      }
    }

    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(MEMBER_CLIENT_STORE, params),
      { value: result }
    ) as IClientStoreItem;
  }
};

export const TEAM_CLIENT_STORE: IApiObjectType<
  { teamId: string; store: string },
  IClientStoreItem
> = {
  id: 'tenants/teams/client-store',
  createRefs(): void {
    // nothing
  },
  async load(cache, params, abort) {
    let result: string | null;
    try {
      result = await fetchJson({
        url: `tenants/${cache.tenantId}/teams/${params.teamId}/client-store/${params.store}`,
        abort
      });
    } catch (error) {
      if (error instanceof ApiError && error.status === 404) {
        result = null;
      } else {
        throw error;
      }
    }

    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(TEAM_CLIENT_STORE, params),
      { value: result }
    ) as IClientStoreItem;
  }
};
