import DetailsList from 'components/lists/DetailsList/DetailsList';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';

const DetailsListStyled = styled(DetailsList)`
  .row-wrapper,
  .ms-GroupHeader-expand {
    cursor: pointer;
  }

  .ms-DetailsRow-cell {
    align-items: center;
    display: flex;
  }
`;

const WrapperStyled = styled.div`
  background-color: rgb(${({ theme }) => theme.pagesList.background});
  padding: 15px;
  overflow: hidden;
  height: 100%;
  display: flex;

  @media (max-width: ${breakpoints.smallMax}px) {
    padding: 0px;
  }
`;

const TemplateNameCell = styled.div<{ $disabled?: boolean }>`
  color: ${(props) =>
    props.$disabled
      ? `rgb(${props.theme.detailsList.linkNameDisabledForeground})`
      : `rgb(${props.theme.detailsList.linkNameForeground})`};
  display: flex;
  width: 100%;
  justify-content: space-between;
  white-space: pre-line;

  &:hover {
    color: ${(props) =>
      props.$disabled
        ? `rgb(${props.theme.detailsList.linkNameDisabledForeground})`
        : `rgb(${props.theme.detailsList.linkNameHoverForeground})`};
    cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
  }

  .info-icon {
    visibility: hidden;
    color: rgb(${({ theme }) => theme.infoIconTooltip.iconColor});
    margin-left: 5px;
    font-size: 12px;
  }

  .name-info {
    display: flex;
  }

  &:hover .info-icon {
    visibility: visible;
  }
`;

export { DetailsListStyled, TemplateNameCell, WrapperStyled };
