/* eslint-disable no-bitwise */

import { LoadingSpinner, Panel } from 'components';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import { checkScreenWidth } from 'utils/helpers';
import { CommandBar, PanelType } from '@fluentui/react';
import ServiceConnectionPivotItem from '../ServiceConnectionPivotItem';
import ServiceGeneralPivotItem from '../ServiceGeneralPivotItem';
import ServiceListConnectionPivotItem from '../ServiceListConnectionPivotItem';
import ServiceResponsePivotItem from '../ServiceResponsePivotItem';
import ServicePanelHeader from './ServicePanelHeader';

// styles
const ServicePanelCommandBarStyled = styled(CommandBar)`
  .command-bar-item:hover {
    background-color: rgb(${({ theme }) => theme.panelCommandBar.itemHoverBackground});
  }

  .command-bar-item {
    background-color: rgb(${({ theme }) => theme.panelCommandBar.background});
  }

  .ms-FocusZone.ms-CommandBar {
    background-color: rgb(${({ theme }) => theme.panelCommandBar.background});
  }

  .hideItem {
    display: none;
  }
`;

const ContentStyled = styled.div`
  .header-container {
    position: absolute;
    top: 47px;
    left: 0;
    width: 100%;
    z-index: 100;
  }

  .tab-content {
    height: 100%;
    margin-top: 145px;
  }
`;

function ServicePanel({
  onClosePanel,
  updateServices,
  onShowDeleteConfirmationDialog,
  service,
  onPivotLinkClick,
  tab
}) {
  const { t } = useTranslation();

  const [newService, setNewService] = useState(null);

  const [team, setTeam] = useState(null);
  const [locations, setLocations] = useState(null);
  const [images, setImages] = useState(null);

  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fieldToFocus, setFieldToFocus] = useState(null);
  const [errorsInTabs, setErrorsInTabs] = useState(null);

  const location = useLocation();

  const isEdit = service && location.pathname.indexOf('edit') > -1;

  const setInitialService = useCallback(() => {
    if (isEdit) {
      setNewService(service);
      setTeam(service?.team);
    } else {
      setNewService({
        name: '',
        serviceData: {
          connection: {
            method: '',
            url: '',
            contentType: 0,
            scopes: []
          }
        },
        permissions: {
          delete: true,
          edit: true
        }
      });
    }
  }, [isEdit, service]);

  useEffect(() => {
    if (!newService) {
      setInitialService();
    }

    setIsLoading(false);
  }, [setInitialService, newService, tab]);

  const convertToDropDownObject = useCallback((dataObjects) => {
    return dataObjects.map((dataObject) => {
      return {
        ...dataObject,
        text: dataObject.name,
        key: dataObject.id
      };
    });
  }, []);

  useEffect(() => {
    if (!locations) {
      fetchRequest({ url: 'Locations' })
        .then((locations) => {
          if (locations) {
            const dropDownLocationObjects = convertToDropDownObject(locations);
            setLocations(dropDownLocationObjects);
          }
        })
        .catch(apiErrorHandler);
    }
  }, [locations, convertToDropDownObject]);

  const getImages = useCallback(() => {
    fetchRequest({ url: 'Files/Pics' })
      .then((images) => {
        if (images) {
          const dropDownImagesObjects = convertToDropDownObject(images);
          setImages(dropDownImagesObjects);
        }
      })
      .catch(apiErrorHandler);
  }, [convertToDropDownObject]);

  useEffect(() => {
    if (!images) {
      getImages();
    }
  }, [images, convertToDropDownObject, getImages]);

  function deleteService() {
    onShowDeleteConfirmationDialog(newService.id, newService.name, 'Service');
  }

  function onDismiss(event) {
    if (event && event.type !== 'mousedown') {
      if (onClosePanel) {
        onClosePanel();
      }
    }
  }

  function checkErrorsInTabs() {
    let tabToFocus = tab;
    let noErrors = true;

    const newErrorsInTabs = {};

    if (
      errorsInTabs?.connection?.length &&
      !!errorsInTabs.connection.find((err) => !!err.message)
    ) {
      tabToFocus = 'connection';
      noErrors = false;

      newErrorsInTabs.connection = errorsInTabs.connection.map((err) => {
        const newError = { ...err };
        newError.showMessage = !!newError.message;

        return newError;
      });
    } else if (
      errorsInTabs?.response?.length &&
      !!errorsInTabs.response.find((err) => !!err.message)
    ) {
      tabToFocus = 'response';
      noErrors = false;

      newErrorsInTabs.response = errorsInTabs.response.map((err) => {
        const newError = { ...err };
        newError.showMessage = !!newError.message;

        return newError;
      });
    } else if (
      errorsInTabs?.['list-connection']?.length &&
      !!errorsInTabs['list-connection'].find((err) => !!err.message)
    ) {
      tabToFocus = 'list-connection';
      noErrors = false;

      newErrorsInTabs['list-connection'] = errorsInTabs['list-connection'].map((err) => {
        const newError = { ...err };
        newError.showMessage = !!newError.message;

        return newError;
      });
    }

    if (tab !== tabToFocus) {
      onPivotLinkClick(tabToFocus);
    }

    if (!noErrors) {
      setErrorsInTabs(newErrorsInTabs);
    }

    return noErrors;
  }

  function checkRequiredFields(passedService) {
    let ready = true;
    let fieldToFocus;
    const connection = passedService.serviceData?.connection;
    const listConnection = passedService.serviceData?.listConnection;

    if (
      !passedService ||
      !passedService.name ||
      (passedService.status && !(passedService.usage || passedService.usage === 0))
    ) {
      ready = false;

      if (!passedService || !passedService.name) {
        fieldToFocus = {
          name: 'name',
          message: t('servicePanel.errorMessage.name'),
          tab: 'general'
        };
      } else {
        fieldToFocus = {
          name: 'usage',
          message: t('servicePanel.errorMessage.usage'),
          tab: 'general'
        };
      }
    } else if (
      passedService.status &&
      (!connection?.url || !connection?.method || !(connection?.auth || connection?.auth === 0))
    ) {
      ready = false;

      if (!connection?.url) {
        fieldToFocus = {
          name: 'connection-url',
          inputId: 'connection-url',
          message: t('servicePanel.errorMessage.url'),
          tab: 'connection'
        };
      } else if (!connection?.method) {
        fieldToFocus = {
          name: 'connection-method',
          inputId: 'connection-method',
          message: t('servicePanel.errorMessage.method'),
          tab: 'connection'
        };
      } else if (!(connection?.auth || !connection?.auth === 0)) {
        fieldToFocus = {
          name: 'connection-auth',
          inputId: 'connection-auth',
          message: t('servicePanel.errorMessage.auth'),
          tab: 'connection'
        };
      }
    } else if (
      passedService.status &&
      (passedService.usage & 4) === 4 &&
      !(
        connection?.queryFields?.length ||
        (connection?.requestBody?.properties &&
          Object.keys(connection.requestBody.properties).length) ||
        (connection?.requestBody?.items?.properties &&
          Object.keys(connection.requestBody.items.properties).length)
      )
    ) {
      ready = false;

      fieldToFocus = {
        name: 'connection-property',
        inputId: 'connection-property',
        message: t('servicePanel.errorMessage.requestProperty'),
        tab: 'connection'
      };
    } else if (
      passedService.status &&
      (passedService.usage & 4) === 4 &&
      !listConnection?.url &&
      (listConnection?.headerFields?.length ||
        listConnection?.queryField?.internalName ||
        (listConnection?.responseBody?.properties &&
          Object.keys(listConnection?.responseBody?.properties).lenth) ||
        (listConnection?.responseBody?.items?.properties &&
          Object.keys(listConnection?.responseBody?.items?.properties).lenth))
    ) {
      ready = false;

      fieldToFocus = {
        name: 'listConnection-url',
        inputId: 'listConnection-url',
        message: t('servicePanel.errorMessage.url'),
        tab: 'list-connection'
      };
    }

    if (!ready) {
      setFieldToFocus(fieldToFocus);

      if (tab !== fieldToFocus.tab) {
        onPivotLinkClick(fieldToFocus.tab);
      }
    } else {
      setFieldToFocus(null);
    }

    return ready;
  }

  function valideURL(urlToValidate) {
    try {
      const url = new URL(urlToValidate);
      const { protocol, hostname, pathname } = url;

      if (protocol && hostname && pathname) {
        return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  }

  function validateURLInput(connection, connType) {
    let newUrlError = false;

    if (connection?.url) {
      const url = connection?.url;
      const validUrl = valideURL(url);

      if (validUrl) {
        const errorFields = [];

        if (connType === 'connection' && connection.queryFields) {
          connection.queryFields.map((field) => {
            if (
              (!url.includes('{0}') && field.usage === 2) ||
              (url.includes('{0}') && field.usage === 1)
            ) {
              errorFields.push(field.internalName);
            }

            return null;
          });
        } else if (connType === 'listConnection' && connection.queryField) {
          if (
            (!url.includes('{0}') && connection.queryField?.usage === 2) ||
            (url.includes('{0}') && connection.queryField?.usage === 1)
          ) {
            errorFields.push(connection.queryField.internalName);
          }
        }

        if (errorFields.length) {
          newUrlError = true;
          setFieldToFocus({
            name: connType === 'connection' ? 'connection-url' : 'listConnection-url',
            message:
              connType === 'connection'
                ? t('serviceConnectionPivotItem.errorMessage.queryFieldUsage', { key: errorFields })
                : t('serviceListConnectionPivotItem.errorMessage.queryFieldUsage', {
                    key: errorFields
                  })
          });
        }
      } else {
        newUrlError = true;

        setFieldToFocus({
          name: connType === 'connection' ? 'connection-url' : 'listConnection-url',
          message:
            connType === 'connection'
              ? t('serviceConnectionPivotItem.errorMessage.invalidUrl')
              : t('serviceListConnectionPivotItem.errorMessage.invalidUrl')
        });
      }
    }

    return newUrlError;
  }

  function handleErrorsFromTabs(tab, errors) {
    setErrorsInTabs({ [tab]: errors?.length ? [...errors] : null });
  }

  function handleServiceState(passedService) {
    if (passedService) {
      const passedServiceClone = { ...passedService };
      const connection = passedServiceClone.serviceData?.connection;
      const listConnection = passedServiceClone.serviceData?.listConnection;
      let resetErrorMessage = false;

      let newUrlError = false;
      // Check for errors related to the url input and set error messages
      if (connection.url) {
        newUrlError = validateURLInput(connection, 'connection');
      }

      if (!newUrlError) {
        newUrlError = validateURLInput(listConnection, 'listConnection');
      }

      // Check and reset error messages
      if (fieldToFocus?.name === 'name' && passedServiceClone.name) {
        resetErrorMessage = true;
      } else if (fieldToFocus?.name === 'usage' && passedServiceClone.usage) {
        resetErrorMessage = true;
      } else if (fieldToFocus?.name === 'connection-url' && connection?.url && !newUrlError) {
        resetErrorMessage = true;
      } else if (fieldToFocus?.name === 'method' && connection?.method) {
        resetErrorMessage = true;
      } else if (
        fieldToFocus?.name === 'request-property' &&
        (!((passedServiceClone.usage & 4) === 4) ||
          connection?.queryFields?.length ||
          (connection?.requestBody?.properties &&
            Object.keys(connection.requestBody.properties).length) ||
          (connection?.requestBody?.items?.properties &&
            Object.keys(connection.requestBody.items.properties).length))
      ) {
        resetErrorMessage = true;
      } else if (
        fieldToFocus?.name === 'listConnection-url' &&
        passedServiceClone.serviceData?.listConnection?.url &&
        !newUrlError
      ) {
        resetErrorMessage = true;
      } else if (fieldToFocus?.name === 'auth' && (connection.auth || connection.auth === 0)) {
        resetErrorMessage = true;
      }

      if (resetErrorMessage) {
        setFieldToFocus(null);
      }

      if (
        (passedServiceClone.usage & 4) !== 4 &&
        !listConnection?.url &&
        (listConnection?.headerFields?.length ||
          listConnection?.queryField?.internalName ||
          (listConnection?.responseBody?.properties &&
            Object.keys(listConnection?.responseBody?.properties).lenth) ||
          (listConnection?.responseBody?.items?.properties &&
            Object.keys(listConnection?.responseBody?.items?.properties).lenth))
      ) {
        const tabToFocus = 'list-connection';

        const fieldToFocus = {
          name: 'listConnection-url',
          message: t('servicePanel.errorMessage.url')
        };

        setFieldToFocus(fieldToFocus);

        if (tab !== tabToFocus) {
          onPivotLinkClick(tabToFocus);
        }
      } else {
        if (passedServiceClone.pic?.new) {
          setImages((prevState) => [
            ...prevState,
            {
              ...passedServiceClone.pic,
              key: passedServiceClone.pic.id,
              text: passedServiceClone.pic.name
            }
          ]);

          delete passedServiceClone.pic.new;
        }

        setNewService(passedServiceClone);
      }
    }
  }

  function handleTeamState(value) {
    setTeam(value[0] || null);
  }

  async function saveService(status) {
    const serviceToUpdate = { ...newService, team };

    if (serviceToUpdate) {
      serviceToUpdate.status = status;

      if (!(serviceToUpdate.serviceData?.usage || serviceToUpdate.serviceData?.usage === 0)) {
        serviceToUpdate.serviceData.usage = 0;
      }
      if (!serviceToUpdate.serviceData?.connection?.method) {
        serviceToUpdate.serviceData.connection.method = 'GET';
      }
      if (!serviceToUpdate.serviceData?.connection?.contentType) {
        serviceToUpdate.serviceData.connection.contentType = 1;
      }
    }

    if (checkErrorsInTabs() && checkRequiredFields(serviceToUpdate)) {
      const method = isEdit ? 'PUT' : 'POST';

      if (team) {
        serviceToUpdate.team = { ...team };
      }

      let checkConsent = false;
      if (
        serviceToUpdate &&
        serviceToUpdate.status &&
        serviceToUpdate.serviceData?.connection?.auth === 1 &&
        serviceToUpdate.serviceData?.connection?.scopes?.length
      ) {
        checkConsent = true;
      } else if (
        serviceToUpdate &&
        serviceToUpdate.status &&
        serviceToUpdate.serviceData?.listConnection?.auth === 1 &&
        serviceToUpdate.serviceData?.listConnection?.scopes?.length
      ) {
        checkConsent = true;
      }

      if (checkConsent) {
        await fetchRequest({
          url: 'Route/Definition/TestExternalService',
          method: 'PUT',
          body: JSON.stringify(serviceToUpdate)
        }).catch((err) => {
          setIsSaving(false);
          return apiErrorHandler(err);
        });
      }

      const resultService = await fetchRequest({
        url: 'Route/Definition/ExternalService',
        method: 'PUT',
        body: JSON.stringify(serviceToUpdate)
      }).catch((err) => {
        setIsSaving(false);
        return apiErrorHandler(err);
      });

      if (resultService) {
        onClosePanel();

        updateServices(resultService, method);
      } else {
        setIsSaving(false);
      }
    } else {
      setIsSaving(false);
    }
  }

  let commandBar = null;
  const cmdBarFarItems = [];
  const cmdBarItems = [];

  if (newService?.permissions?.delete === true) {
    cmdBarFarItems.push({
      key: 'delete',
      className: 'command-bar-item',
      text: t('servicePanel.commandBar.button.delete'),
      iconProps: {
        iconName: 'delete'
      },
      disabled: !isEdit,
      onClick: () => deleteService()
    });
  }

  if (newService?.permissions?.edit === true) {
    cmdBarItems.push({
      key: 'saveAsDraft',
      className: 'command-bar-item',
      text: t('servicePanel.commandBar.button.saveAsDraft'),
      iconProps: {
        iconName: 'TestPlan'
      },
      disabled: isSaving,
      onClick: () => {
        setIsSaving(true);
        saveService(0);
      }
    });
    cmdBarItems.push({
      key: 'publish',
      className: 'command-bar-item',
      text: t('servicePanel.commandBar.button.publish'),
      iconProps: {
        iconName: 'Upload'
      },
      disabled: isSaving,
      onClick: () => {
        setIsSaving(true);
        saveService(1);
      }
    });
  }

  if (newService?.permissions?.delete || newService?.permissions?.edit) {
    commandBar = <ServicePanelCommandBarStyled items={cmdBarItems} farItems={cmdBarFarItems} />;
  }

  if (!newService) {
    return null;
  }

  function getPanelContent() {
    let body = null;

    if (tab === 'connection') {
      body = (
        <ServiceConnectionPivotItem
          service={newService}
          handleServiceState={handleServiceState}
          fieldToFocus={fieldToFocus}
          handleErrors={handleErrorsFromTabs}
          inputErrors={errorsInTabs?.connection}
        />
      );
    } else if (tab === 'response') {
      body = (
        <ServiceResponsePivotItem
          service={newService}
          handleServiceState={handleServiceState}
          handleErrors={handleErrorsFromTabs}
          inputErrors={errorsInTabs?.response}
        />
      );
    } else if (tab === 'list-connection') {
      body = (
        <ServiceListConnectionPivotItem
          service={newService}
          handleServiceState={handleServiceState}
          fieldToFocus={fieldToFocus}
          handleErrors={handleErrorsFromTabs}
          inputErrors={errorsInTabs?.['list-connection']}
        />
      );
    } else {
      body = (
        <ServiceGeneralPivotItem
          service={newService}
          team={team}
          handleServiceState={handleServiceState}
          handleTeamState={handleTeamState}
          fieldToFocus={fieldToFocus}
          locations={locations}
          images={images}
        />
      );
    }

    return (
      <ContentStyled>
        <div className={`${checkScreenWidth(['extraSmall']) ? null : 'header-container'}`}>
          <ServicePanelHeader service={newService} onPivotLinkClick={onPivotLinkClick} tab={tab} />
          <div className={`${checkScreenWidth(['extraSmall']) ? null : 'sticky'}`}>
            {commandBar}
          </div>
        </div>
        <div className={`${checkScreenWidth(['extraSmall']) ? null : 'tab-content'}`}>
          {isSaving ? (
            <LoadingSpinner
              styles={{ container: { marginTop: 500 } }}
              label={t('servicePanel.saving.text')}
            />
          ) : (
            body
          )}
        </div>
      </ContentStyled>
    );
  }

  return isLoading ? (
    <LoadingSpinner label={t('loading.servicePanel.text')} />
  ) : (
    <Panel
      isLightDismiss={false}
      isOpen={!!tab}
      onDismiss={onDismiss}
      onRenderBody={() => getPanelContent()}
      type={PanelType.custom}
      customWidth="800px"
    />
  );
}

ServicePanel.propTypes = {
  onClosePanel: PropTypes.func,
  updateServices: PropTypes.func.isRequired,
  onShowDeleteConfirmationDialog: PropTypes.func.isRequired,
  service: PropTypes.object,
  onPivotLinkClick: PropTypes.func.isRequired,
  tab: PropTypes.string
};

ServicePanel.defaultProps = {
  onClosePanel: null,
  service: null,
  tab: null
};

export default ServicePanel;
