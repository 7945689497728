import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { checkScreenWidth, getPivotStyles2 } from 'utils/helpers';
import { Pivot, PivotItem } from '@fluentui/react';

const ServicePanelHeaderStyled = styled.div`
  background-color: rgb(${({ theme }) => theme.panelHeader.background});
  color: rgb(${({ theme }) => theme.panelHeader.foreground});
  height: 100px;

  .new-edit-service-title {
    font-size: 28px;
    padding-left: 16px;
  }

  .new-edit-service-subtitle {
    font-size: 14px;
    padding-left: 16px;
  }

  @media (max-width: ${breakpoints.smallMax}px) {
    height: auto;

    .new-edit-service-title {
      font-size: 18px;
    }

    .new-edit-service-subtitle {
      font-size: 12px;
    }
  }
`;

function ServicePanelHeader({ service, onPivotLinkClick, tab }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const location = useLocation();

  const isEdit = service && location.pathname.indexOf('edit') !== -1;

  const [pivotItems, setPivotItems] = useState([]);

  const getPivotArray = useCallback(() => {
    const pivotArray = [];

    pivotArray.push(
      <PivotItem
        itemIcon={checkScreenWidth(['extraSmall']) ? 'Label' : null}
        className="pivot-item-height"
        itemKey="general"
        key="general"
        headerText={
          checkScreenWidth(['extraSmall']) ? null : t('servicePanelHeader.pivotItemLabel.general')
        }
      />,
      <PivotItem
        itemIcon={checkScreenWidth(['extraSmall']) ? 'PlugConnected' : null}
        className="pivot-item-height"
        itemKey="connection"
        key="connection"
        headerText={
          checkScreenWidth(['extraSmall'])
            ? null
            : t('servicePanelHeader.pivotItemLabel.connection')
        }
      />,
      <PivotItem
        itemIcon={checkScreenWidth(['extraSmall']) ? 'GroupList' : null}
        className="pivot-item-height"
        itemKey="response"
        key="response"
        headerText={
          checkScreenWidth(['extraSmall']) ? null : t('servicePanelHeader.pivotItemLabel.response')
        }
      />
    );

    if (service?.usage === 4 || service?.usage === 6) {
      pivotArray.push(
        <PivotItem
          itemIcon={checkScreenWidth(['extraSmall']) ? 'ConnectVirtualMachine' : null}
          className="pivot-item-height"
          itemKey="list-connection"
          key="list-connection"
          headerText={
            checkScreenWidth(['extraSmall'])
              ? null
              : t('servicePanelHeader.pivotItemLabel.list-connection')
          }
        />
      );
    }

    return pivotArray;
  }, [t, service]);

  useEffect(() => {
    setPivotItems(getPivotArray());
  }, [getPivotArray, service.usage]);

  return (
    <ServicePanelHeaderStyled>
      <div className="new-edit-service-title">
        {isEdit
          ? t('servicePanelHeader.title.editService')
          : t('servicePanelHeader.title.newService')}
      </div>
      <div className="new-edit-service-subtitle">
        {service && isEdit ? `${service.name}` : t('servicePanelHeader.subTitle.create')}
      </div>
      <Pivot
        overflowBehavior="menu"
        linkSize="normal"
        selectedKey={`${tab}`}
        onLinkClick={(item) => onPivotLinkClick(item.props.itemKey)}
        styles={getPivotStyles2(theme)}
      >
        {pivotItems}
      </Pivot>
    </ServicePanelHeaderStyled>
  );
}

ServicePanelHeader.propTypes = {
  service: PropTypes.object,
  onPivotLinkClick: PropTypes.func.isRequired,
  tab: PropTypes.string
};

ServicePanelHeader.defaultProps = {
  service: null,
  tab: null
};

export default ServicePanelHeader;
