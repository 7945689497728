import { useBubblesStore } from 'features/App/context/BubblesStore';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import fetchRequest from 'services/api';
import { ViewStatus, userPropType } from 'types';
import { checkScreenWidth } from 'utils/helpers';
import { useTheme } from 'styled-components';
import {
  ActionButton,
  Callout,
  DefaultButton,
  Persona,
  PersonaSize,
  SearchBox,
  Slider
} from '@fluentui/react';
import { useThemeStyles } from 'utils/theme';
import { Divider, HeaderButtonsRowStyled } from './HeaderButtonsRow.styled';
import HeaderCmdBarButton from './HeaderCmdBarButton';
import ThemePreferenceSetting from './ThemePreferenceSetting';

function HeaderButtonsRow({
  currentUser,
  tenants,
  styles,
  logout,
  unreadNotifications,
  onToggleNotifications,
  onToggleTrainer,
  onToggleReleaseNotes
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isCalloutVisible, setIsCalloutVisible] = useState(false);

  const trainers = useBubblesStore((state) => state.trainers);
  const { pathname } = useLocation();

  const currentTenantId = window.localStorage.getItem('tenantId');
  const currentVersion = JSON.parse(window.localStorage.getItem('version'));

  const trainingsStatusCount = trainers.reduce((acc, trainer) => {
    const { trainings = [] } = trainer;

    const newTrainings = trainings.filter((training) => training.viewStatus === ViewStatus.new);

    return acc + newTrainings.length;
  }, 0);

  const newTrainngsCount = useMemo(() => {
    if (!trainingsStatusCount) return 0;
    const activeTrainers = trainers.filter((trainer) => {
      const trainings = trainer.trainings.filter((training) => {
        const { paths } = training;

        const globalRoot = paths.find(({ path }) => path === '/');

        if (globalRoot) {
          return true;
        }

        const match = paths.find(({ path }) => {
          const matchedPath = matchPath(path, pathname);

          return matchedPath;
        });

        return match;
      });

      return trainings.length > 0;
    });

    const activeTrainings = activeTrainers.reduce((acc, trainer) => {
      const { trainings = [] } = trainer;

      return [...acc, ...trainings];
    }, []);

    return activeTrainings.filter((training) => training.viewStatus === ViewStatus.new).length;
  }, [trainers, pathname, trainingsStatusCount]);

  const [tenantSearchTerm, setTenantSearchTerm] = useState('');
  const [mailFrequency, setMailFrequency] = useState(null);

  useEffect(() => {
    fetchRequest({ url: 'User/Settings' }).then((res) => {
      setMailFrequency(res.mailFrequency);
    });
  }, []);

  const theme = useTheme();

  const fontColor = styles?.color;

  function getPersonaButton() {
    return (
      <DefaultButton
        styles={{
          root: {
            backgroundColor: 'transparent',
            border: 'none',
            borderRadius: '0px',
            height: '100%',
            marginLeft: '7px',
            marginRight: '7px',
            paddingBottom: '6px',
            paddingLeft: '18px',
            paddingRight: '7px',
            selectors: {
              '@media (max-width: 450px)': {
                marginLeft: '0px',
                marginRight: '0px',
                paddingLeft: '12px',
                paddingRight: '5px'
              }
            }
          },
          rootHovered: {
            backgroundColor: `rgb(${theme.header.foreground} / 0.1)`,
            selectors: {
              '@media (max-width: 450px)': {
                backgroundColor: 'transparent'
              }
            }
          },
          rootPressed: {
            backgroundColor: `rgba(${theme.header.foreground} / 0.1)`,
            selectors: {
              '@media (max-width: 450px)': {
                backgroundColor: 'transparent'
              }
            }
          }
        }}
        text={getPersona()}
        onClick={() => setIsCalloutVisible((prevState) => !prevState)}
      />
    );
  }

  function onTenantSelect(tenantId) {
    if (tenantId && currentTenantId !== tenantId) {
      window.localStorage.setItem('tenantId', tenantId);
      window.location.href = '/tasks/my-open';
    } else {
      setIsCalloutVisible(false);
    }
  }

  function getCurrentTenantName() {
    if (tenants.length > 0) {
      const currentTenant = tenants.find((tenant) => currentTenantId === tenant.tenantId);

      return currentTenant?.name;
    }

    return null;
  }

  let tenantsList = [...tenants];

  if (tenantSearchTerm) {
    tenantsList = tenants.filter((tenant) =>
      tenant.name.toLowerCase().includes(tenantSearchTerm.toLowerCase())
    );
  }

  function getMailFrequencySliderValue() {
    if (mailFrequency === 10) {
      return 0;
    }

    return 6 - mailFrequency;
  }

  function convertSliderValueToNewMailFrequency(value) {
    let newMailFrequency = Math.abs(value - 6);

    if (newMailFrequency === 5) {
      newMailFrequency = 10;
    }

    return newMailFrequency;
  }

  function getCalloutConent() {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Persona
            hidePersonaDetails={false}
            imageUrl={currentUser.pictureUrl}
            secondaryText={currentUser.login}
            showSecondaryText
            size={PersonaSize.size48}
            styles={{ root: { padding: '20px', marginTop: '20px', marginBottom: '20px' } }}
            text={currentUser.name}
          />
          <ActionButton
            styles={{
              label: { fontSize: '14px' },
              root: { marginTop: '13px', marginRight: '5px', border: 'none' }
            }}
            onClick={logout}
          >
            {t('header.logOut')}
          </ActionButton>
        </div>
        <div>
          {mailFrequency && (
            <Slider
              styles={{ root: { margin: '0px 20px 10px 20px' } }}
              label={t('header.mailFrequency', { context: mailFrequency })}
              min={1}
              max={5}
              step={1}
              onChange={(value) => {
                const newMailFrequency = convertSliderValueToNewMailFrequency(value);

                setMailFrequency(newMailFrequency);
              }}
              onChanged={(_, value) => {
                fetchRequest({
                  url: `User/Settings`,
                  method: 'POST',
                  body: JSON.stringify({
                    mailFrequency: convertSliderValueToNewMailFrequency(value)
                  })
                });
              }}
              defaultValue={getMailFrequencySliderValue()}
              showValue={false}
              snapToStep
            />
          )}
        </div>
        <ThemePreferenceSetting />
        <div style={{ maxHeight: 500, overflow: 'auto' }}>
          {tenants.length > 10 && (
            <SearchBox
              placeholder={t('searchBar.placeholder')}
              autoComplete="off"
              underlined
              styles={{ root: { margin: 10, marginTop: 0 } }}
              onClear={() => setTenantSearchTerm(null)}
              onChange={(_, value) => setTenantSearchTerm(value)}
              defaultValue={tenantSearchTerm}
              onSearch={setTenantSearchTerm}
            />
          )}
          {tenants &&
            tenantsList.map((tenant) => (
              <DefaultButton
                key={tenant.tenantId}
                onClick={() => onTenantSelect(tenant.tenantId)}
                styles={{
                  root: {
                    border: 'none',
                    borderRadius: '0px',
                    borderTop: `1px solid rgb(${theme.header.tenantDividerLine})`,
                    height: '45px',
                    width: '100%'
                  }
                }}
              >
                {tenant.name}
              </DefaultButton>
            ))}
        </div>
      </div>
    );
  }

  function getPersona() {
    return (
      <Persona
        hidePersonaDetails={checkScreenWidth(['extraSmall', 'small'])}
        imageUrl={currentUser.pictureUrl}
        secondaryText={getCurrentTenantName()}
        showSecondaryText={tenants && tenants.length > 1}
        size={PersonaSize.size32}
        styles={{
          secondaryText: { color: fontColor || `rgb(${theme.header.foreground})` },
          primaryText: {
            color: fontColor || `rgb(${theme.header.foreground})`,
            height: '16px',
            selectors: { '&:hover': { color: fontColor || `rgb(${theme.header.foreground})` } }
          },
          root: { paddingTop: '5px' }
        }}
        text={currentUser.name}
      />
    );
  }

  function showNotificationsPanel() {
    onToggleNotifications();
  }

  function showReleaseNotesPanel() {
    if (currentVersion?.new) {
      window.localStorage.setItem('version', JSON.stringify({ ...currentVersion, new: false }));
    }

    onToggleReleaseNotes();
  }

  const themeStyles = useThemeStyles();

  return (
    <HeaderButtonsRowStyled>
      <Divider backgroundColor={styles?.dividerColor} />
      <HeaderCmdBarButton
        iconName="Info"
        onClickMethod={showReleaseNotesPanel}
        styles={styles?.headerCmdBarButton}
        title={t('header.iconbutton.releaseNotes')}
        badgeIcon={currentUser?.isAdmin && currentVersion?.new ? 'CRMCustomerInsightsApp' : null}
      />
      <Divider backgroundColor={styles?.dividerColor} />
      <HeaderCmdBarButton
        iconName={unreadNotifications > 0 ? 'RingerSolid' : 'Ringer'}
        onClickMethod={showNotificationsPanel}
        styles={styles?.headerCmdBarButton}
        title={t('header.iconbutton.notifications')}
        badgeText={unreadNotifications > 0 ? unreadNotifications.toString() : null}
      />
      {currentUser?.isAdmin && (
        <>
          <Divider backgroundColor={styles?.dividerColor} />
          <HeaderCmdBarButton
            iconName="Settings"
            onClickMethod={() => navigate(`/administration`)}
            styles={styles?.headerCmdBarButton}
            title={t('header.iconbutton.settings')}
          />
        </>
      )}
      {!checkScreenWidth(['extraSmall', 'small']) && (
        <>
          <Divider backgroundColor={styles?.dividerColor} />
          <HeaderCmdBarButton
            iconName="ChatBot"
            onClickMethod={() => {
              onToggleTrainer();
            }}
            badgeText={newTrainngsCount > 0 ? newTrainngsCount.toString() : null}
            styles={styles?.headerCmdBarButton}
            title="Trainer"
          />
        </>
      )}
      <Divider backgroundColor={styles?.dividerColor} />
      <HeaderCmdBarButton
        iconName="help"
        onClickMethod={() => {
          window.open(t('header.iconbutton.helpHref'), '_blank').focus();
        }}
        styles={styles?.headerCmdBarButton}
        title={t('header.iconbutton.help')}
      />
      <Divider backgroundColor={styles?.dividerColor} />
      <div className="c-profile-persona">{getPersonaButton()}</div>
      <Callout
        gapSpace={0}
        styles={{
          root: {
            marginTop: '10px',
            borderRadius: '10px',
            overflow: 'hidden',
            maxWidth: '500px',
            selectors: {
              '@media (max-width: 450px)': {
                width: '100%',
                maxWidth: '100%',
                left: '0 !important'
              }
            },
            ...themeStyles
          }
        }}
        target=".c-profile-persona"
        onDismiss={() => setIsCalloutVisible(false)}
        hidden={!isCalloutVisible}
        directionalHint={6}
        calloutWidth={450}
        preventDismissOnScroll
        isBeakVisible={false}
      >
        {getCalloutConent()}
      </Callout>
    </HeaderButtonsRowStyled>
  );
}

HeaderButtonsRow.propTypes = {
  currentUser: userPropType.isRequired,
  tenants: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, tenantId: PropTypes.string })
  ).isRequired,
  styles: PropTypes.objectOf(PropTypes.any),
  logout: PropTypes.func.isRequired,
  unreadNotifications: PropTypes.number.isRequired,
  onToggleNotifications: PropTypes.func.isRequired,
  onToggleTrainer: PropTypes.func.isRequired,
  onToggleReleaseNotes: PropTypes.func.isRequired
};

HeaderButtonsRow.defaultProps = {
  styles: null
};

export default HeaderButtonsRow;
