import { LoadingSpinner, LongTextField } from 'components';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { RouteFieldType } from 'types';
import { formatDate } from 'utils/helpers';
import {
  ContextualMenu,
  ContextualMenuItemType,
  DefaultButton,
  DirectionalHint,
  Icon,
  IContextualMenuItem
} from '@fluentui/react';
import ContextualFilterMenuFilterOption from './ContextualFilterMenuFilterOption';
import ContextualFilterMenuSearchBox from './ContextualFilterMenuSearchBox';
import {
  IProcessInstanceColumnHeader,
  IProcessInstanceFilterProps,
  ProcessInstancesFilterType
} from '../../../../../hooks/api2';

const LongTextFieldWrapper = styled.div`
  max-height: 16px;

  * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0;
  }
`;

interface IColumn extends IProcessInstanceColumnHeader {
  filterValues: unknown[];
  isFiltered: boolean;
  isSorted: boolean;
  isSortedDescending: boolean;
}

interface IFilterProps {
  /** All possible values */
  all: IProcessInstanceFilterProps;
  /** Subset after filtering by other filter columns */
  filtered: IProcessInstanceFilterProps;
}

interface IContextualFilterMenuProps {
  column: IColumn;
  filterType: string;
  onDismiss: () => void;
  onFilterChange: (column: IColumn) => void;
  onSortingChange: (column: IColumn, ascending: boolean) => void;
  getContextualFilterMenuProps: (
    filterType: ProcessInstancesFilterType,
    filterField: string | null
  ) => Promise<IFilterProps>;
  target: Element | DOMRect;
}

interface IFilterOption {
  key: string;
  text: ReactNode;
  hasValue: boolean;
}

function ContextualFilterMenu({
  column,
  onDismiss,
  filterType,
  onFilterChange,
  onSortingChange,
  target,
  getContextualFilterMenuProps
}: IContextualFilterMenuProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [filterOptions, setFilterOptions] = useState<IFilterOption[]>([]);
  const [filteredFilterOptions, setFilteredFilterOptions] = useState([]);
  const theme = useTheme();

  const showFilterOptions =
    column.id === 'default-column-createdBy' ||
    (column.id === 'default-column-progress' && filterType === 'completed') ||
    column.id === 'default-column-currentTask' ||
    (!column.id.includes('default-column-') &&
      column.fieldType !== RouteFieldType.Document &&
      column.fieldType !== RouteFieldType.Hyperlink &&
      column.fieldType !== RouteFieldType.LongText);

  const getFilterText = useCallback(
    (option: unknown, key: keyof IProcessInstanceFilterProps & string) => {
      if (key === 'dateValues') {
        return formatDate(option as string, 'DD.MM.YYYY');
      }

      if (key === 'boolValues') {
        return option
          ? t('contextualMenu.option.boolValues.true')
          : t('contextualMenu.option.boolValues.false');
      }

      if (column.id === 'default-column-progress') {
        if (option === 4) {
          return t('taskPanelDetail.status.label.5');
        }

        if (option === 5) {
          return t('contextualMenu.filter.abortedBySubProcess');
        }

        return t('contextualMenu.filter.abortedByUser');
      }

      return option?.toString();
    },
    [column.id, t]
  );

  const createFilterOptions = useCallback(
    (filterProps: IFilterProps) => {
      const options: IFilterOption[] = [];

      for (const user of filterProps.all.userValues ?? []) {
        const hasValue = filterProps.filtered.userValues?.some(
          (item) => item.userId === user.userId
        );
        options.push({
          key: user.userId,
          text: user.name,
          hasValue
        });
      }

      for (const link of filterProps.all.linkValues ?? []) {
        const hasValue = filterProps.filtered.linkValues?.some((item) => item.url === link.url);
        options.push({
          key: link.url,
          text: link.text || link.url,
          hasValue
        });
      }

      for (const value of filterProps.all.dateValues ?? []) {
        const hasValue = filterProps.filtered.dateValues?.includes(value);
        options.push({
          key: value,
          text: formatDate(value, 'L'),
          hasValue
        });
      }

      for (const value of filterProps.all.lookupValues ?? []) {
        const hasValue = filterProps.filtered.lookupValues?.some((item) => item.id === value.id);
        options.push({
          key: value.id,
          text: value.value,
          hasValue
        });
      }

      for (const value of filterProps.all.textValues ?? []) {
        const isLongTextField = value.includes('<p>');
        const isAppendChangesLongTextField = value && value.includes('CurrentValue');

        if (isAppendChangesLongTextField) {
          continue;
        }

        const hasValue = filterProps.filtered.textValues?.includes(value);
        options.push({
          key: value,
          text: isLongTextField ? (
            <LongTextFieldWrapper>
              <LongTextField defaultValue={value} disabled />
            </LongTextFieldWrapper>
          ) : (
            value
          ),
          hasValue
        });
      }

      for (const key of ['numberValues', 'boolValues'] as const) {
        for (const value of filterProps.all[key] ?? []) {
          const hasValue = (filterProps.filtered[key] as unknown[])?.includes(value);
          options.push({
            key: value?.toString(),
            text: getFilterText(value, key),
            hasValue
          });
        }
      }

      if (filterProps.all.hasEmptyValue) {
        options.push({
          key: '',
          text: t('contextualMenu.option.empty'),
          hasValue: filterProps.filtered.hasEmptyValue
        });
      }
      setIsLoading(false);
      setFilterOptions(options);
    },
    [getFilterText, t]
  );

  const getFilterProps = useCallback(() => {
    let filterField = null;
    let filterType = 100;

    if (filterType === ProcessInstancesFilterType.DataField) {
      filterField = column.id;
    }

    if (column.id === 'default-column-createdBy') {
      filterType = ProcessInstancesFilterType.CreatedBy;
    }

    if (column.id === 'default-column-currentTask') {
      filterType = ProcessInstancesFilterType.CurrentStep;
    }

    if (column.id === 'default-column-progress') {
      filterType = ProcessInstancesFilterType.Progress;
    }

    getContextualFilterMenuProps(filterType, filterField).then(createFilterOptions);
  }, [column.id, createFilterOptions, getContextualFilterMenuProps]);

  useEffect(() => {
    if (showFilterOptions) {
      getFilterProps();
    }
  }, [showFilterOptions, getFilterProps]);

  function onClearFilterOptions() {
    onFilterChange({
      ...column,
      filterValues: [],
      isFiltered: false
    });
  }

  function onFilterOptionChange(option) {
    let filterValues = [...column.filterValues];
    const doesFilterValueAlreadyExist = filterValues.includes(option.key);

    let isFiltered = false;

    if (option.checked) {
      isFiltered = true;

      // Commented until API is enabled to filter on multiple values
      filterValues.push(option.key);
    } else if (doesFilterValueAlreadyExist) {
      // Commented until API is enabled to filter on multiple values
      filterValues = filterValues.filter((value) => value !== option.key);
    }

    if (filterValues.length) {
      isFiltered = true;
    }

    onFilterChange({
      ...column,
      filterValues,
      isFiltered
    });
  }

  function getContextualMenuOptions(option: IFilterOption): IContextualMenuItem {
    if (option.key === 'noResults') {
      return {
        key: 'noResults',
        text: '',
        onRender: () => (
          <div
            key="noResults"
            style={{
              alignItems: 'center',
              display: 'flex',
              height: '100px',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <Icon iconName="SearchIssue" title={t('contextualMenu.filterSearch.noItemsFound')} />
            <span>{t('contextualMenu.filterSearch.noItemsFound')}</span>
          </div>
        )
      };
    }

    return {
      key: option.key,
      text: typeof option.text === 'string' ? option.text : '',
      onRender: () => (
        <ContextualFilterMenuFilterOption
          option={{ ...option, checked: column.filterValues.includes(option.key) }}
          onFilterOptionChange={onFilterOptionChange}
          hasValue={option.hasValue}
        />
      )
    };
  }

  function onFilterOptionsSearchChange(_, newValue: string) {
    const filteredItems = filterOptions.filter(
      (item) =>
        item.text && item.text.toString().toLowerCase().indexOf(newValue.toLowerCase()) !== -1
    );

    if (!filteredItems || !filteredItems.length) {
      filteredItems.push({
        key: 'noResults',
        text: '',
        hasValue: true
      });
    }
    setFilteredFilterOptions(filteredItems);
  }

  let items: IContextualMenuItem[] = [
    {
      canCheck: true,
      checked: column.isSorted && column.isSortedDescending,
      iconProps: { iconName: 'Ascending' },
      key: 'aToZ',
      text: t('contextualMenu.sort.ascending'),
      onClick: () => onSortingChange(column, true)
    },
    {
      canCheck: true,
      checked: column.isSorted && !column.isSortedDescending,
      iconProps: { iconName: 'Descending' },
      key: 'zToA',
      text: t('contextualMenu.sort.descending'),
      onClick: () => onSortingChange(column, false)
    },
    {
      key: 'divider_1',
      text: '',
      itemType: ContextualMenuItemType.Divider
    }
  ];

  if (showFilterOptions) {
    const filterSearchBox: IContextualMenuItem = {
      key: 'searchBox',
      onRender: () => (
        <ContextualFilterMenuSearchBox onFilterOptionsSearchChange={onFilterOptionsSearchChange} />
      )
    };

    const clearFilterButton: IContextualMenuItem = {
      key: 'clearFilter',
      onRender: () => (
        <div style={{ borderBottom: `1px solid rgb(${theme.detailsList.contextMenuDivider})` }}>
          <DefaultButton
            text={t('contextualMenu.filter.clear')}
            disabled={!column.isFiltered}
            iconProps={{ iconName: 'clearFilter' }}
            styles={{
              root: {
                border: 'none',
                height: '36px',
                width: '100%',
                paddingLeft: '28px',
                background: 'none'
              },
              label: { float: 'left', fontWeight: '400' },
              icon: {
                color: `rgb(${theme.detailsList.contextMenuIconColor})`
              }
            }}
            onClick={onClearFilterOptions}
          />
        </div>
      )
    };

    if (isLoading) {
      const isLoadingContainer: IContextualMenuItem = {
        key: 'loading',
        onRender: () => (
          <div
            key="loading"
            style={{
              alignItems: 'center',
              display: 'flex',
              height: '100px',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <LoadingSpinner
              className="loading-spinner"
              label={t('contextualMenu.loading.options')}
            />
          </div>
        )
      };
      items = [...items, isLoadingContainer];
    } else if (filteredFilterOptions.length > 0) {
      items = [
        ...items,
        filterSearchBox,
        clearFilterButton,
        ...filteredFilterOptions.map((option) => getContextualMenuOptions(option))
      ];
    } else if (filterOptions) {
      items = [
        ...items,
        filterSearchBox,
        clearFilterButton,
        ...filterOptions.map((option) => getContextualMenuOptions(option))
      ];
    }
  }

  return (
    <ContextualMenu
      directionalHint={DirectionalHint.bottomLeftEdge}
      gapSpace={10}
      isBeakVisible
      items={items}
      onDismiss={onDismiss}
      styles={{ root: { width: '300px', maxHeight: '700px' } }}
      target={target}
    />
  );
}

export default ContextualFilterMenu;
