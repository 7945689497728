/* eslint-disable react/jsx-props-no-spreading */

import MsTeamsConfig from 'pages/MsTeamsConfig/MsTeamsConfig';
import { createContext, lazy, ReactNode, Suspense, useContext } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider
} from 'react-router-dom';
import styled from 'styled-components';
import NavigationContainer from './NavigationContainer';

const NoAccess = lazy(() => import('pages/NoAccess'));
const NotFound = lazy(() => import('pages/NotFound'));
const Dashboard = lazy(() => import('../../pages/AI/Dashboard'));
const AgentChat = lazy(() => import('pages/AI/AgentChat'));
const OrdinaryChat = lazy(() => import('pages/AI/OrdinaryChat'));
const AgentNewEdit = lazy(() => import('pages/AI/AgentNewEdit'));
const Profile = lazy(() => import('pages/AI/Settings/Profile'));
const Administration = lazy(() => import('pages/AI/Settings/Administration'));
const CustomPluginSettings = lazy(() => import('pages/AI/Settings/CustomPluginSettings'));
const KnowledgeBases = lazy(() => import('pages/AI/KnowledgeBases'));
const KnowledgeBase = lazy(() => import('pages/AI/KnowledgeBase'));

const Debug = lazy(() => import('pages/Debug'));

const PageWrapper = styled.div`
  position: fixed;
  inset: 0;
  bottom: env(safe-area-inset-bottom); /* iOS home bar */

  display: flex;
  flex-direction: column;
`;

interface IRoutingProps {
  logout: () => void;
}

const noAccessRouter = createBrowserRouter([
  {
    path: '/access',
    element: <NoAccess />
  }
]);

const msTeamsAuthRouter = createBrowserRouter([
  {
    path: '/teams-config/:context',
    element: <MsTeamsConfig />
  },
  {
    path: '/teams-config',
    element: <MsTeamsConfig />
  }
]);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      id="root"
      path="/"
      element={
        <Layout>
          <Outlet />
        </Layout>
      }
      errorElement={
        <Layout>
          <NotFound />
        </Layout>
      }
    >
      <Route path="/__DEBUG__" element={<Debug />} />

      <Route path="dashboard" element={<Dashboard />} />
      <Route path="dashboard/:tab" element={<Dashboard />} />
      <Route index element={<OrdinaryChat />} />
      <Route path="chat/:chatId" element={<OrdinaryChat />} />
      <Route path="agent/:chatId" element={<AgentChat />} />
      <Route path="agent/:chatId/message/:messageId" element={<AgentChat />} />
      <Route path="agents" element={<AgentChat />} />
      <Route path="agents/new" element={<AgentNewEdit />} />
      <Route path="agents/:agentId" element={<AgentNewEdit />} />
      <Route path="knowledge-bases" element={<KnowledgeBases />} />
      <Route path="knowledge-bases/:knowledgeBaseId" element={<KnowledgeBase />} />
      <Route path="knowledge-bases/:knowledgeBaseId/chat/:chatId" element={<KnowledgeBase />} />
      <Route
        id="kbSettings"
        path="knowledge-bases/:knowledgeBaseId/settings"
        element={<KnowledgeBase />}
      />
      <Route path="administration/plugins/:pluginId" element={<CustomPluginSettings />} />
      <Route path="administration/*" element={<Administration />} />
      <Route path="profile" element={<Profile />} />
      <Route path="profile/teams/:teamId" element={<Profile />} />
    </Route>
  )
);

const LogoutContext = createContext(() => undefined);

export default function Routing({ logout }: IRoutingProps) {
  const noAccess = window.location.href.includes('/access');
  const isMsTeamsAuthPage = window.location.href.includes('teams-config');

  if (noAccess) {
    return <RouterProvider router={noAccessRouter} />;
  }

  if (isMsTeamsAuthPage) {
    return <RouterProvider router={msTeamsAuthRouter} />;
  }

  return (
    <LogoutContext.Provider value={logout}>
      <RouterProvider router={router} />
    </LogoutContext.Provider>
  );
}

function Layout({ children }: { children?: ReactNode }) {
  const logout = useContext(LogoutContext);

  return (
    <PageWrapper>
      <NavigationContainer logout={logout}>
        <Suspense fallback={<div />}>{children}</Suspense>
      </NavigationContainer>
    </PageWrapper>
  );
}
